<ng-container *ngIf="teamsWithMetadata">
  <div
    *ngFor="let team of teamsWithMetadata"
    mat-ripple
    class="p-2 celo-cursor-pointer celo-hoverable item"
    (click)="handleTeamClick(team)"
  >
    <div class="company">
      <div class="celo-break-word content">
        <div class="text">
          <app-info-line
            [title]="team.name"
            [subtitles]="[team.workspace.name]"
          ></app-info-line>
        </div>
        <div *ngIf="team.metadata.isCurrentUserOnCall" class="on-call">
          On-call
        </div>
      </div>
    </div>
  </div>
</ng-container>
