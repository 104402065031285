import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentsService } from "@modules/core/services/payments.service";
import { exponentialRetry, SubscriptionContainer } from "@utils";
import { finalize, Observable, of } from "rxjs";

@Component({
  selector: "app-processing",
  templateUrl: "./processing.component.html",
  styleUrls: ["./processing.component.scss"],
})
export class ProcessingComponent implements OnInit, OnDestroy {
  private subscriptions = new SubscriptionContainer();

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentsService: PaymentsService
  ) {}

  public ngOnInit(): void {
    const queryParamMapSubscription = this.route.queryParamMap.subscribe({
      next: (paramMap) => {
        let observable: Observable<void> | null = of();
        const action = paramMap.get("action");
        const companyId = paramMap.get("companyId");
        const returnUrl = paramMap.get("returnUrl") ?? "";

        if (action === "add-billing-info") {
          if (!companyId) return;
          observable = this.paymentsService
            .refreshBillingMethod(companyId, {
              idempotencyKey: crypto.randomUUID(),
            })
            .pipe(exponentialRetry());
        }

        observable
          .pipe(
            finalize(() => {
              this.router.navigateByUrl(returnUrl);
            })
          )
          .subscribe();
      },
    });

    this.subscriptions.add(queryParamMapSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
