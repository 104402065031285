import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-search-network",
  templateUrl: "./search-network.component.html",
  styleUrls: ["./search-network.component.scss"],
})
export class SearchNetworkComponent {
  @Output() linkClick = new EventEmitter<any>();
}
