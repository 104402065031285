// Not used anywhere ?

import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { DirectoryService } from "../../modules/core";
import { AlertService } from "../../modules/core/old/alert.service";
import { ParticipantList } from "../ParticipantList";

@Component({
  selector: "app-department-list",
  templateUrl: "./department-list.component.html",
  styleUrls: ["./department-list.component.scss"],
})
export class DepartmentListComponent implements OnInit {
  private _companyId: string;
  public _deps: any[] = [];
  public deps: any[] = [];
  departmentFilter = "";
  public selectedDepartment: string;

  @Input() participantList: ParticipantList;
  @Input() set companyId(value: string) {
    this._companyId = value;
    this.loadDepartmentList(this._companyId);
  }

  get companyId(): string {
    return this._companyId;
  }

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private directoryService: DirectoryService
  ) {}

  ngOnInit() {
    this.selectedDepartment = DirectoryService.ALL_DEPARTMENTS;
  }

  loadDepartmentList(companyId) {
    const url = `${environment.celoApiEndpoint}/api/Companies/${companyId}/Departments`;

    const params = new HttpParams().set("Page", "0").set("PageSize", "500");

    this.http.get<any>(url, { params }).subscribe((deps) => {
      this._deps = [
        { companyId: this.companyId, name: DirectoryService.ALL_DEPARTMENTS },
        { companyId: this.companyId, name: DirectoryService.NO_DEPARTMENT },
      ];
      this._deps = this._deps.concat(deps.data);
      this.deps = [].concat(this._deps);
    });
  }

  filterDepartments() {
    this.deps = this._deps.filter(
      (dep) =>
        !this.departmentFilter ||
        dep.name
          .toLowerCase()
          .indexOf(this.departmentFilter.toLocaleLowerCase()) != -1
    );
  }

  onSelectDepartment(department: string) {
    this.selectedDepartment = department;
    this.directoryService.searchByDepartment(this.selectedDepartment);
  }
}
