import {
  ConnectionModel,
  UserBlockModel,
  UserProfessionModel,
  IdentityVerificationStatus,
  WorkplaceModel,
  TeamPublicProfileModel,
  ParticipantRole,
} from "./api-v2";

export interface ConversationParticipant {
  _type: "ConversationParticipant";

  teamId?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModel;
  blockedMe?: UserBlockModel;
  userId: string;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  isExternal?: boolean | null;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;

  /** @format date-time */
  leftOnUtc?: string | null;

  /** @format date-time */
  joinedOnUtc?: string | null;
  isActive?: boolean;

  /** @format date-time */
  mutedToUtc?: string | null;

  /** @format int32 */
  muteInterval?: number | null;
  isHidden?: boolean;

  /** @format date-time */
  asReadToUtc?: string | null;
  role?: ParticipantRole;

  /** @deprecated Use profilePic instead. */
  profilePicture?: string | null;

  /** @format date-time */
  pinnedOnUtc?: string | null;

  /** @format date-time */
  latestReadMessageUtc?: string | null;
}
