import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ActivatedRoute,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { environment } from "@env";
import {
  ApplicationInsightsService,
  AuthService,
  SettingsService,
  SnackbarService,
} from "@modules/core";
import { createInterruptListenerObservable } from "@utils";
import {
  VoipIncomingCallDialogComponent,
  VoipIncomingCallDialogResult,
} from "app/voip/voip-incoming-call-dialog/voip-incoming-call-dialog.component";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  from,
  fromEvent,
  merge,
  timer,
} from "rxjs";
import { distinctUntilChanged, first, tap, throttleTime } from "rxjs/operators";

interface LockState {
  isLocked: boolean;
  isExpired: boolean;
}

@Injectable()
export class PinscreenService implements OnDestroy {
  private isLockedSubject = new BehaviorSubject<boolean>(true);
  public isLocked$ = this.isLockedSubject.pipe(distinctUntilChanged());

  private afterUnlockedSubject = new Subject<void>();
  public afterUnlocked$ = this.afterUnlockedSubject.asObservable();

  private hasPinSubject = new BehaviorSubject<boolean>(false);
  public hasPin$ = this.hasPinSubject.pipe(distinctUntilChanged());

  private lockoutPeriodSecondsSubject = new BehaviorSubject<number>(
    environment.defaultLockoutPeriodSeconds
  );

  public lockoutPeriodSeconds$ = this.lockoutPeriodSecondsSubject.pipe(
    distinctUntilChanged()
  );

  private idleSubscripion: Subscription | null = null;

  private KEY: string = "epc2";
  private MIN_PIN_LENGTH: number = 4;

  private LOCK_STATE_KEY: string = "lock_state";
  private LAST_ACTIVE_TIME_KEY: string = "last_active";
  private storageListener: ((event: StorageEvent) => void) | null = null;

  private checkLockoutSubscription: Subscription | null = null;
  private isInitialAuthCompleted: boolean = false;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private appInsightsService: ApplicationInsightsService,
    private matDialog: MatDialog,
    private zone: NgZone
  ) {
    this.settingsService.settings$.subscribe({
      next: (settings) => {
        {
          const lockoutPeriodMinutes = settings?.lockoutPeriod;
          if (!lockoutPeriodMinutes) return;
          this.lockoutPeriodSecondsSubject.next(lockoutPeriodMinutes * 60);
        }
      },
    });

    this.updateLastActiveTime();

    this.checkLockoutSubscription = merge(
      fromEvent(window, "focus"),
      timer(0, 1000)
    ).subscribe({
      next: () => {
        this.checkLockout();
      },
    });

    createInterruptListenerObservable()
      .pipe(throttleTime(50))
      .subscribe({
        next: () => {
          this.checkLockout(false);
          this.updateLastActiveTime();
        },
      });

    this.storageListener = (event: StorageEvent) => {
      if (event.key !== this.LOCK_STATE_KEY) return;
      this.checkSharedState();
    };

    window.addEventListener("storage", this.storageListener);

    this.authService.isAuthenticated$.subscribe({
      next: (isAuthenticated) => {
        if (!isAuthenticated) return;
        this.hasPinSubject.next(this.hasPasscode());
        this.checkSharedState();
        this.isInitialAuthCompleted = true;
      },
    });

    // Added to help with testing/debugging. This is generally __not__ a good practice, but it is very time consuming to
    // to test anything related to the pin screen without this.
    if (!environment.production) {
      const propertyName = "lock";
      try {
        if (typeof window[propertyName] !== "undefined")
          throw new Error(
            `Window already has a property named '${propertyName}'`
          );
        window[propertyName] = () => {
          this.zone.run(() => {
            this.lock();
          });
        };
      } catch (err) {
        console.error(err);
      }
    }
  }

  private checkSharedState() {
    this.hasPinSubject.next(this.hasPasscode());

    const lockState = this.getLockState();
    if (!lockState) return;

    const { isLocked, isExpired } = lockState;
    const currentIsLocked = this.isLocked();

    if (isLocked && !currentIsLocked) {
      this.lockInternal(isExpired);
      return;
    }

    if (!isLocked && currentIsLocked) {
      // FIXME: router URL is always empty or '/' on initial load which results in the app always redirecting to the
      // root. The exact cause of this error is currently unknown. Location is used as a workaround.
      const returnUrl = this.isInitialAuthCompleted
        ? null
        : new URL(
            location.pathname + location.hash + location.search,
            location.origin
          );
      this.unlockInternal(returnUrl);
    }
  }

  private checkLockout(enableLogging: boolean = true) {
    const ignoredPaths: string[] = [
      "/",
      "/login",
      "/pin",
      "/register-login-oidc",
    ];
    if (ignoredPaths.some((path) => this.router.url.split("?", 1)[0] === path))
      return;
    if (!this.isTimedOut()) return;
    this.lockInternal(true, enableLogging);
  }

  public updateLastActiveTime(force: boolean = false) {
    if (!force && (this.isLocked() || this.isTimedOut())) return;
    const time = new Date().toISOString();
    localStorage.setItem(this.LAST_ACTIVE_TIME_KEY, time);
  }

  private getLastActiveTime(): Date | null {
    const item = localStorage.getItem(this.LAST_ACTIVE_TIME_KEY);
    if (!item) return null;
    const lastActive = new Date(item);
    return lastActive;
  }

  private isTimedOut(): boolean {
    const lastActive = this.getLastActiveTime();
    if (!lastActive) return true;
    const lockoutTime = new Date(
      Date.now() - this.lockoutPeriodSecondsSubject.value * 1000
    );
    return lastActive < lockoutTime;
  }

  public ngOnDestroy(): void {
    this.idleSubscripion?.unsubscribe();

    if (this.storageListener) {
      window.removeEventListener("storage", this.storageListener);
    }

    if (this.checkLockoutSubscription) {
      this.checkLockoutSubscription.unsubscribe();
    }
  }

  public isLocked(): boolean {
    return this.isLockedSubject.value;
  }

  public hasPin(): boolean {
    return this.hasPinSubject.value;
  }

  public lock() {
    this.lockInternal();
  }

  private lockInternal(
    isExpired: boolean = false,
    enableLogging: boolean = true
  ) {
    if (this.isLocked()) return;

    for (const dialog of this.matDialog.openDialogs) {
      if (dialog.componentInstance instanceof VoipIncomingCallDialogComponent) {
        const result: VoipIncomingCallDialogResult = {
          action: "Locked",
        };
        dialog.close(result);
      } else {
        dialog.close();
      }
    }

    this.matDialog.afterAllClosed.pipe(first()).subscribe({
      next: () => {
        this.isLockedSubject.next(true);

        const queryParams: Params = {
          returnUrl: this.getReturnUrl(),
        };

        if (isExpired) queryParams.expired = true;

        this.updateLockState({
          isLocked: true,
          isExpired,
        });

        this.router.navigate(["/pin"], {
          queryParams,
          queryParamsHandling: "preserve",
          state: {
            ignoreAuth: true,
          },
        });
      },
    });
  }

  private updateLockState(lockState: LockState) {
    localStorage.setItem(this.LOCK_STATE_KEY, JSON.stringify(lockState));
  }

  private getLockState(): LockState | null {
    const value = localStorage.getItem(this.LOCK_STATE_KEY);
    if (!value) return null;
    const state = JSON.parse(value) as LockState;
    return state;
  }

  private getReturnUrl(
    ignoreCurrentUrl: boolean = false,
    state?: RouterStateSnapshot
  ): string {
    let returnUrl =
      state?.url ?? (this.route.snapshot.queryParams["returnUrl"] as string);

    if (returnUrl) {
      returnUrl = returnUrl.split("?", 1)[0];
    }

    if (returnUrl) return returnUrl;

    returnUrl = this.router.url.split("?", 1)[0];
    const invalidPaths: string[] = ["/pin", "/signin-oidc"];
    if (
      ignoreCurrentUrl ||
      invalidPaths.some((path) => returnUrl.includes(path))
    ) {
      returnUrl = "/conversations";
    }

    return returnUrl;
  }

  private unlockInternal(overrideReturnUrl?: URL | null) {
    if (!this.hasPinSubject.value)
      throw new Error("Failed to unlock - no pin has been set.");

    this.isLockedSubject.next(false);
    this.updateLastActiveTime(true);
    this.updateLockState({
      isLocked: false,
      isExpired: false,
    });

    const returnUrl: string = overrideReturnUrl
      ? overrideReturnUrl.pathname
      : this.getReturnUrl(true);

    let queryParams: Params;
    if (overrideReturnUrl) {
      queryParams = Object.fromEntries(
        overrideReturnUrl.searchParams.entries()
      );
    }

    from(
      this.router.navigate([returnUrl], {
        queryParams,
        queryParamsHandling: "merge",
      })
    ).subscribe({
      next: () => {
        this.afterUnlockedSubject.next();
      },
    });
  }

  public unlock() {
    this.unlockInternal();
  }

  public validatePin(pin: string): void {
    if (pin[0] == pin[1] && pin[1] == pin[2] && pin[2] == pin[3]) {
      throw new Error("Please pick a stronger PIN");
    }

    if (["1234", "4321"].indexOf(pin) != -1) {
      throw new Error(
        "Celo's security policy does not allow weak PINs. Please enter a more secure PIN."
      );
    }
  }

  private getKey(): string {
    return this.KEY;
  }

  private getPasscode(): string {
    return localStorage.getItem(this.KEY);
  }

  private savePasscode(passcode: string): void {
    const key = this.getKey();
    localStorage.setItem(key, passcode);
  }

  public removePasscode(): void {
    const key = this.getKey();
    localStorage.removeItem(key);
  }

  private async processMessage(message: string): Promise<string> {
    return message;
  }

  private hash(value: string): Observable<string> {
    const subject = new Subject<string>();

    this.processMessage(value)
      .then((hash) => {
        subject.next(hash);
        subject.complete();
      })
      .catch((err) => subject.error(err));

    return subject;
  }

  /**
   * Sets or updates a user's pin.
   */
  public setOrUpdatePin(pin: string): Observable<void> {
    const observable = new Observable<void>((subscriber) => {
      if (pin.length !== 4) {
        subscriber.error(new Error("Pin must be 4 digits long"));
        return;
      }

      try {
        this.validatePin(pin);
      } catch (err) {
        subscriber.error(new Error(err.message));
        return;
      }

      const hashSubscription = this.hash(pin).subscribe({
        next: (hash) => {
          this.savePasscode(hash);
          this.hasPinSubject.next(true);
          this.snackbarService.show("Pin updated", 3);
          subscriber.next();
        },
        error: (err) => subscriber.error(err),
        complete: () => subscriber.complete,
      });

      return () => hashSubscription.unsubscribe();
    });
    return observable;
  }

  public setOrUpdatePinAndUnlock(pin: string): Observable<void> {
    return this.setOrUpdatePin(pin).pipe(
      tap({
        next: () => this.unlock(),
      })
    );
  }

  public checkPin(pin: string): Observable<boolean> {
    const subject = new ReplaySubject<boolean>();
    const passcode = this.getPasscode();

    if (pin.length < this.MIN_PIN_LENGTH) {
      subject.next(false);
      subject.complete();
    } else {
      this.processMessage(pin)
        .then((hash) => subject.next(hash === passcode))
        .catch((err) => subject.error(err))
        .finally(() => subject.complete());
    }

    return subject;
  }

  public checkPinAndUnlock(pin: string): Observable<boolean> {
    return this.checkPin(pin).pipe(
      tap({
        next: (isMatch) => {
          if (!isMatch) return;
          this.unlock();
        },
      })
    );
  }

  public hasPasscode(): boolean {
    return this.getPasscode() !== null;
  }

  public getUrlTree(state: RouterStateSnapshot): UrlTree {
    const returnUrl = this.getReturnUrl(true, state);
    return this.router.createUrlTree(["/pin"], {
      queryParams: { returnUrl },
    });
  }
}
