<div class="photo-upload-status mt-2">
  <div
    class="row align-items-center"
    *ngFor="let item of uploaderQueueItems; let index = index"
  >
    <div class="col-1">
      <mat-icon *ngIf="type === 'Photos' || type === 'Profile Picture'"
        >photo</mat-icon
      >
      <mat-icon *ngIf="type === 'Documents'">file_copy</mat-icon>
    </div>
    <div class="col-5">
      <section>{{ item.fileItem?.file?.name }}</section>
      <small
        >{{ item.fileItem?.file?.size / 1024 / 1024 | number: ".2" }}MB</small
      >
    </div>
    <div class="col-5">
      <div
        class="progress"
        *ngIf="item.fileItem.isUploading || item.fileItem.isUploaded"
      >
        <div
          class="progress-bar"
          [ngClass]="{
            'progress-bar-animated progress-bar-striped bg-warning':
              item.fileItem.isUploading,
            'bg-success': item.fileItem.isUploaded
          }"
          role="progressbar"
          [ngStyle]="{
            width:
              item.fileItem.isUploading || item.fileItem.isUploaded
                ? '100%'
                : '0%'
          }"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div class="description" *ngIf="allowDescription">
        <input
          type="text"
          placeholder="Add Description"
          [(ngModel)]="item.description"
        />
      </div>
      <section *ngIf="item.fileItem.isError">error upload</section>
    </div>
    <div class="col-1">
      <mat-icon
        *ngIf="!item.fileItem.isUploaded && !item.fileItem.isUploading"
        (click)="uploaderQueueItems.splice(index, 1); item.fileItem.remove()"
        >close</mat-icon
      >
    </div>
  </div>
</div>
