<img *ngIf="!hidePic" [src]="pic"  />
<div class="celo-text-16 celo-bold">
  <div *ngIf="title" >{{ title }}</div>
</div>
<div *ngIf="subtitle" class="celo-grey celo-text-14 subtitle">
  {{ subtitle }}
</div>
<button
  *ngIf="showInviteButton"
  mat-button
  class="celo-tertiary-button"
  [ngClass]="{
    'celo-primary-button': style === 'primary',
    'celo-tertiary-button': style === 'tertiary'
  }"
  (click)="addColleagues()"
>
  Add Colleagues
</button>
<button
  *ngIf="showInviteViaLinkButton"
  mat-button
  class="celo-primary-button uppercase"
  (click)="buttonClick.emit()"
>
  invite to workspace
</button>
<button
  *ngIf="showAddWorkspaceButton"
  (click)="joinWorkspace()"
  mat-button
  class="celo-primary-button"
>
  ADD WORKSPACE
</button>
<button
  *ngIf="showGetStartedButton"
  (click)="joinWorkspace()"
  mat-button
  class="celo-primary-button"
>
  GET STARTED
</button>
