import { Component } from "@angular/core";

@Component({
  selector: "app-add-workspace-details",
  templateUrl: "./add-workspace-details.component.html",
  styleUrls: ["./add-workspace-details.component.scss"],
})
export class AddWorkspaceDetailsComponent {
  constructor() {}
}
