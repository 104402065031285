import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawerMode } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { NewChatComponent } from "@modules/conversations/new-conversations/new-chat/new-chat.component";
import { AlertComponent } from "@modules/core/components";
import { CreateRoleComponent } from "@modules/roles/create-role/create-role.component";
import { WorkspaceService } from "app/workspace/workspace.service";
import { environment } from "environments/environment";
import { combineLatest, NEVER, Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import {
  CompanyModel,
  ContactModel,
  FullUserProfileModel,
  Team,
  TeamMember,
  UserWorkspaceModel,
  WorkspaceModel,
} from "types";
import {
  addOrReplaceElement,
  concatNotNull,
  flatMap,
  generateManageUsersUrl,
  generateUpgradeUrl,
  localeCompareIfNotNull,
  sorted,
  SubscriptionContainer,
  UserLimitsUtils,
} from "utils";
import {
  Contact,
  ContactGroupState,
  DepartmentContactGroup,
} from "../../../modules/conversations/new-conversations/contacts-expansion-panel/contacts-expansion-panel.component";
import { WorkspaceDepartmentContactGroup } from "../../../modules/conversations/new-conversations/search-result/search-result.component";
import {
  AccountService,
  AlertService,
  AnalyticsService,
  CompaniesService,
  NoInternetError,
  RoutingService,
  SharedService,
  TeamsService,
  UserAccountService,
  UserService,
} from "../../../modules/core";
import { NetworkParams } from "../network/network.component";
import {
  NetworkNavigationState,
  NetworkService,
} from "./../../network.service";

export enum SidenavType {
  CLOSED,
  WORKSPACE_DETAILS,
  CONTACT,
  EDIT_MEMBERS,
  INVITE_TO_WORKSPACE,
  ROLE_DETAILS,
  USER_PROFILE,
}

const ANALYTICS_FLOW = "workspace";

@Component({
  selector: "app-workspace",
  templateUrl: "./workspace.component.html",
  styleUrls: ["./workspace.component.scss", "./../network-style.scss"],
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  NO_DEPARTMENT = <any>{ name: "No Department" };
  public searchTerm: string = "";
  userWorkspace: UserWorkspaceModel;
  workspace: WorkspaceModel;
  contactId: string;
  contact: ContactModel;
  sidenavType: SidenavType | null = null;
  workspaceId: string;
  userAccount: FullUserProfileModel;
  sub: any;
  allWorkspaceContacts: ContactModel[];
  loading: boolean;
  page: number;
  groupedLocalContactsDepartments: DepartmentContactGroup[] = [];
  groupedLocalContactsDepartmentsSearchResults: DepartmentContactGroup[] = [];
  groupedLocalContactsPeople: Contact[] = [];
  departments_sub: any;
  departmentContactsList: Contact[];
  isLoadingDepartment: boolean = false;
  isMyWorkspace: boolean = false;
  removableWorkspaceContacts: ContactModel[];

  public isAvatarInvalid: boolean = false;

  public get isLoading(): boolean {
    return this.isLoadingDepartment || this.loading || this.isTeamsLoading;
  }

  public get isSearchResultsEmpty(): boolean {
    return (
      !this.groupedLocalContactsDepartmentsSearchResults.length &&
      !this.groupedLocalContactsPeople.length &&
      !this.teamsSearchResults.length
    );
  }

  public isTeamsLoading: boolean = false;
  public teams: Team[] = [];
  public teamsSearchResults: Team[] = [];
  public selectedTeam: Team | null = null;
  public selectedUserId: string | null = null;

  public isAddRoleEnabled: boolean = false;

  public drawerMode$: Observable<MatDrawerMode> | null;

  public upgradeUrl = generateUpgradeUrl();
  public company: CompanyModel | null = null;

  public manageUsersUrl: string = "";

  private subscriptions = new SubscriptionContainer();

  constructor(
    private route: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef,
    private routingService: RoutingService,
    private alertService: AlertService,
    private teamsService: TeamsService,
    private networkService: NetworkService,
    private router: Router,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
    private companiesService: CompaniesService,
    private accountService: AccountService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: NetworkParams) => {
      if (!params.workspaceId || params.workspaceId === this.workspaceId) {
        return;
      }
      this.workspaceId = params.workspaceId;
      this.initialize();
    });
    this.userAccountService.getUserAccount(true, (userAccount) => {
      this.userAccount = userAccount;
      this.isMyWorkspace = this.getIsMyWorkspace(this.workspaceId);
      this.setRemovableContacts();
    });
    this.observeNetwork();

    this.drawerMode$ = this.breakpointObserver
      .observe("(min-width: 1200px)")
      .pipe(map((result) => (result.matches ? "side" : "over")));
  }

  initialize = () => {
    const state: NetworkNavigationState | null =
      this.router.getCurrentNavigation()?.extras.state;

    this.manageUsersUrl = generateManageUsersUrl(this.workspaceId);

    this.isAvatarInvalid = false;
    this.saveSelectedCompanyId(this.workspaceId);
    this.searchTerm = "";
    this.isMyWorkspace = this.getIsMyWorkspace(this.workspaceId);
    this.saveSelectedCompanyId(this.workspaceId);
    this.loadWorkspace();
    this.getWorkspaceContacts(0, 5000, this.searchTerm);
    this.loadAllTeams(state?.selectedTeam?.id);
    this.closeSidenav();
  };

  public refresh() {
    this.initialize();
  }

  private observeNetwork() {
    const subscription = this.networkService.team$.subscribe({
      next: (team) => {
        if (team) {
          this.showOrHideRole(team);
        } else {
          this.closeSidenav();
        }
      },
    });
    this.subscriptions.add(subscription);
  }

  private loadAllTeams(selectedTeamId?: string) {
    const subscription = this.userService.userId$
      .pipe(
        tap({
          next: () => (this.isTeamsLoading = true),
        }),
        switchMap((userId) => {
          if (!userId) return NEVER;
          return this.teamsService
            .getWorkspaceTeams({
              workspaceId: this.workspaceId,
              fetchAll: true,
              pageSize: 100,
            })
            .pipe(flatMap((page) => page.data));
        })
      )
      .subscribe({
        next: (teams) => {
          const activeTeams = this.teamsService.filterOutInactiveTeams(teams);
          this.teams = this.teamsService.sortWorkspaceTeams(activeTeams);

          if (selectedTeamId) {
            const selectedTeam = this.teams.find(
              (team) => team.id === selectedTeamId
            );
            if (!selectedTeam) {
              AlertComponent.openErrorDialog(
                this.matDialog,
                "Error loading team. Please try again."
              );
              return;
            }
            this.showOrHideRole(selectedTeam);
          }
          this.isTeamsLoading = false;
        },
      });

    this.subscriptions.add(subscription);
  }

  getIsMyWorkspace(workspaceId: string) {
    if (
      !this.userAccount ||
      !this.userAccount.workplaces ||
      !this.userAccount.workplaces.length
    )
      return false;
    let found = false;
    this.userAccount.workplaces.forEach((workspace) => {
      if (workspace.companyId == workspaceId) {
        found = true;
        return;
      }
    });
    return found;
  }

  saveSelectedCompanyId(id) {
    localStorage.setItem("company_selected", id);
  }

  loadWorkspace() {
    const subscription = combineLatest([
      this.userService.getUserWorkspace(this.workspaceId),
      this.accountService.getUserLimits(),
    ]).subscribe({
      next: ([workspace, userLimits]) => {
        if (!workspace || (!workspace.isActive && !workspace.isPartner)) {
          this.alertService.showSnackBar(
            "You don't have access to this workspace",
            3
          );
          if (!workspace) return;
          this.sharedService.navigateToNetwork();
        }
        this.userWorkspace = workspace;

        const hasWorkspaceTeamManageClaim =
          this.userWorkspace.claims?.workspaceTeamManage === "true";

        this.isAddRoleEnabled =
          userLimits &&
          UserLimitsUtils.isAddRoleEnabled(
            userLimits,
            hasWorkspaceTeamManageClaim
          );
      },
      error: (error) => {
        if (error instanceof NoInternetError) return;
        this.sharedService.navigateToNetwork();
        this.alertService.showSnackBar(
          "You don't have access to this workspace",
          3
        );
      },
    });

    const companySubscription = this.companiesService
      .getCompanyV1(this.workspaceId)
      .subscribe({
        next: (company) => {
          this.company = company;
        },
      });

    this.subscriptions.add(subscription, companySubscription);
  }

  public handleClearSearch() {
    this.searchTerm = "";
    this.groupedLocalContactsDepartmentsSearchResults = [];
  }

  public handleSearch(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.getDepartment(this.workspaceId);
    this.localSearchPeople(this.searchTerm);
    this.teamsSearchResults = this.teamsService.filterTeamsByName(
      this.teams,
      this.searchTerm
    );
  }

  viewDetailsButtonClick() {
    if (this.sidenavType == SidenavType.WORKSPACE_DETAILS) {
      this.sidenavType = null;
      return;
    }
    this.sidenavType = SidenavType.WORKSPACE_DETAILS;
    this.analyticsService.buttonClickEvent("view_workspace_details", {
      flow: ANALYTICS_FLOW,
    });
  }

  inviteToWorkspaceButtonClick() {
    if (this.sidenavType == SidenavType.INVITE_TO_WORKSPACE) {
      this.sidenavType = null;
      return;
    }
    this.sidenavType = SidenavType.INVITE_TO_WORKSPACE;
    this.analyticsService.buttonClickEvent("invite_to_workspace", {
      flow: ANALYTICS_FLOW,
    });
  }

  onCardClicked(contact: ContactModel, cardType: string) {
    if (this.userAccount && this.userAccount.userId == contact.userId) {
      this.analyticsService.buttonClickEvent("user_card", {
        flow: ANALYTICS_FLOW,
        current_user: true,
      });
      this.routingService.routeToProfilePage();
      return;
    }
    this.sidenavType = SidenavType.CONTACT;
    this.contactId = contact.userId;
    this.contact = contact;
    let type = "colleague";
    switch (cardType) {
      case "colleague":
        type = this.searchTerm ? "searched_colleague" : "colleague";
        break;
      case "department":
        type = this.searchTerm ? "searched_department" : "department";
        break;
    }
    this.analyticsService.buttonClickEvent("user_card", {
      flow: ANALYTICS_FLOW,
      current_user: false,
      card_type: type,
    });
  }

  closeSidenavClicked() {
    this.closeSidenav();
  }

  closeSidenav() {
    this.sidenavType = null;
  }

  onWorkspaceUpdated(event) {
    this.workspaceService.workspaceChange.next(event);
  }

  setRemovableContacts() {
    if (
      !this.userAccount ||
      !this.allWorkspaceContacts ||
      !this.allWorkspaceContacts.length
    )
      return;
    this.removableWorkspaceContacts = this.allWorkspaceContacts.filter(
      (contact) => {
        if (!contact) return false;
        return contact.userId != this.userAccount.userId;
      }
    );
  }

  onEditMembers() {
    this.setRemovableContacts();
    this.sidenavType = SidenavType.EDIT_MEMBERS;
  }

  handleAddMembers(workspaceId) {
    const instance = this;
    let workspaceContacts = this.allWorkspaceContacts.map(
      (user) => user.userId
    );
    NewChatComponent.openWorkspaceAdminAddUsersDialog(
      this.matDialog,
      workspaceId,
      workspaceContacts
    )
      .afterClosed()
      .subscribe((res) => {
        if (!res || !res.length) {
          return;
        }
        let usersToAdd = res.map((user) => user.userId);
        instance.addUsersToWorkspace(workspaceId, usersToAdd);
      });
  }

  addUsersToWorkspace(workspaceId: string, usersToAdd: string[]) {
    let url =
      environment.celoApiEndpoint + `/api/v2/workspaces/${workspaceId}/users`;
    let body = { userIds: usersToAdd };
    let instance = this;
    this.sharedService.postObjectById(url, {}, body).subscribe(
      (res) => {
        instance.alertService.showSnackBar("Users added successfully", 4);
        instance.initialize();
      },
      (err) => {
        instance.alertService.showSnackBar(
          instance.sharedService.STANDARD_ERROR_MESSAGE,
          4
        );
      }
    );
  }

  getWorkspaceContacts(page: number, pageSize: number, query: string): void {
    this.loading = true;
    this.page = page;
    this.groupedLocalContactsDepartments = [];
    const body = {
      page,
      pageSize,
      includeSelf: true,
    };
    if (query) {
      body["fullName"] = query;
    }
    this.unsubscribe();
    this.sub = this.sharedService
      .getCompanyContacts(this.workspaceId, body)
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (!query) {
            this.allWorkspaceContacts = res.data;
            this.setRemovableContacts();
          }
          this.localSearchDepartments();
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }

  localSearchDepartments() {
    this.allWorkspaceContacts.sort((a, b) =>
      localeCompareIfNotNull(
        concatNotNull([a.firstName, a.lastName]),
        concatNotNull([b.firstName, b.lastName])
      )
    );
    const localContacts = [...this.allWorkspaceContacts];
    this.groupedLocalContactsDepartments =
      this.groupByDepartment(localContacts);
    this.sharedService.sortArrayByField(
      this.groupedLocalContactsDepartments,
      "departmentName"
    );
    const myWorkspace = this.workspaceService.getMyCompanyByCompanyId(
      this.workspaceId,
      this.userAccount
    );

    // If the user is part of a department move it to the top
    if (myWorkspace) {
      const myDepartmentName = myWorkspace.departmentName;
      if (myDepartmentName) {
        this.sharedService.pullToTop(
          this.groupedLocalContactsDepartments,
          "departmentName",
          myDepartmentName
        );
      }
    }

    // If there's a "No department" department, it should always be placed at the bottom
    const hasNoDepartment = this.groupedLocalContactsDepartments.filter(
      (group) => group.departmentName === this.NO_DEPARTMENT.name
    ).length;
    if (hasNoDepartment) {
      this.sharedService.pullToBottom(
        this.groupedLocalContactsDepartments,
        "departmentName",
        this.NO_DEPARTMENT.name
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  groupByDepartment(contacts: Contact[]): DepartmentContactGroup[] {
    if (!contacts?.length === null) {
      return [];
    }

    const group: {
      [x: string]: Contact[];
    } = {};
    for (const contact of contacts) {
      const company = this.sharedService.getCompanyById(
        this.workspaceId,
        contact
      );
      const departmentName = company?.departmentName || this.NO_DEPARTMENT.name;
      group[departmentName] = group[departmentName] || [];
      group[departmentName].push(contact);
    }

    const departmentContactGroups: DepartmentContactGroup[] = [];
    for (const key of Object.keys(group)) {
      const departmentContacts: Contact[] = group[key];
      departmentContactGroups.push({
        departmentName: key,
        contacts: departmentContacts,
        state: ContactGroupState.Disabled,
      });
    }

    return departmentContactGroups;
  }

  getDepartment(companyId?: string) {
    this.isLoadingDepartment = true;
    let params = {
      page: 0,
      pageSize: 5000,
      department: this.searchTerm ? this.searchTerm : "",
    };
    this.unsubscribeGetDepartments();
    this.departmentContactsList = [];
    this.groupedLocalContactsDepartmentsSearchResults = [];

    this.departments_sub = this.sharedService.getContacts(params).subscribe({
      next: (res) => {
        this.isLoadingDepartment = false;
        let list = res.data || [];
        if (companyId) {
          list = this.filterByCompanyId(list, companyId);
        }
        this.departmentContactsList = this.departmentContactsList.concat(list);
        this.formatDepartmentResults(this.searchTerm, companyId);
      },
      error: (err) => {
        this.isLoadingDepartment = false;
      },
    });
  }

  formatDepartmentResults(query: string, companyIdToFocus) {
    query = query.toLowerCase();
    const localContacts = sorted(this.departmentContactsList, (a, b) =>
      localeCompareIfNotNull(
        concatNotNull([a.firstName, a.lastName]),
        concatNotNull([b.firstName, b.lastName])
      )
    );

    this.groupedLocalContactsDepartmentsSearchResults = this.format(
      localContacts,
      companyIdToFocus
    );
    this.sharedService.sortArrayByField(
      this.groupedLocalContactsDepartmentsSearchResults,
      "departmentName"
    );
    this.sortGroupByOncall(this.groupedLocalContactsDepartmentsSearchResults);
    if (query) {
      this.sharedService.pullToTop(
        this.groupedLocalContactsDepartmentsSearchResults,
        "departmentName",
        query
      );
    }
  }

  sortGroupByOncall(groupedLocalContactsDepartments) {
    groupedLocalContactsDepartments.forEach((department) => {
      if (
        department &&
        department["contacts"] &&
        department["contacts"].length
      ) {
        this.formatByField(department["contacts"], "isOnCall");
      }
    });
  }

  formatByField(array, fieldName) {
    let pivot = 0;
    for (let index = 0; index < array.length; index++) {
      const contact = array[index];
      if (contact[fieldName] == true || contact[fieldName] == "true") {
        array.splice(index, 1);
        array.splice(pivot++, 0, contact);
      }
    }
  }

  format(
    contacts: any[],
    companyIdToFocus?: string
  ): WorkspaceDepartmentContactGroup[] {
    let group = {};
    for (const contact of contacts) {
      const companies = contact["workplaces"];
      for (const company of companies) {
        if (companyIdToFocus && company.companyId != companyIdToFocus) continue;
        if (contact.userId !== this.userAccount.userId) {
          const departmentId = company.departmentId
            ? company.departmentId
            : this.NO_DEPARTMENT.id;
          const departmentName = company.departmentName
            ? company.departmentName
            : this.NO_DEPARTMENT.name;
          const workspaceName = company.companyName ? company.companyName : "";
          const workspaceVerificationStatus = company.verificationStatus
            ? company.verificationStatus
            : "";
          group = group || {};
          group[departmentId] = group[departmentId] || {};
          group[departmentId].companyId = company.companyId;
          group[departmentId].departmentName = departmentName;
          group[departmentId].workspaceName = group[departmentId].workspaceName
            ? group[departmentId].workspaceName
            : workspaceName;
          group[departmentId].workspaceVerificationStatus = group[departmentId]
            .workspaceVerificationStatus
            ? group[departmentId].workspaceVerificationStatus
            : workspaceVerificationStatus;
          group[departmentId].contacts = group[departmentId].contacts || [];
          group[departmentId].contacts.push(contact);
        }
      }
    }
    const pairs: WorkspaceDepartmentContactGroup[] = [];
    for (const k in group) {
      const departmentName = group[k].departmentName.toLowerCase();
      if (k && departmentName.indexOf(this.searchTerm.toLowerCase()) != -1) {
        pairs.push({
          departmentId: k,
          contacts: group[k].contacts,
          departmentName: group[k].departmentName,
          workspaceName: group[k].workspaceName,
          workspaceVerificationStatus: group[k].workspaceVerificationStatus,
          companyId: group[k].companyId,
          state: ContactGroupState.Unchecked,
        });
      }
    }
    return pairs;
  }

  filterByCompanyId(array: ContactModel[], companyId) {
    array = array.filter((contact) => {
      for (const workspace of contact.workplaces) {
        if (workspace["companyId"] == companyId && workspace["isActive"]) {
          return true;
        }
      }
      return false;
    });
    return array;
  }

  localSearchPeople(query: string) {
    query = query.toLowerCase();
    let localContacts = sorted(this.allWorkspaceContacts, (a, b) =>
      localeCompareIfNotNull(
        concatNotNull([a.firstName, a.lastName]),
        concatNotNull([b.firstName, b.lastName])
      )
    );
    localContacts = localContacts.filter((contact) => {
      const workspaceOfContact = this.sharedService.getCompanyById(
        this.workspaceId,
        contact
      );
      if (workspaceOfContact) {
        let result = false;
        result = this.peopleFilter(query, workspaceOfContact, contact);
        return result;
      }
      return false;
    });
    this.groupedLocalContactsPeople = this.formatPeople(localContacts);
  }

  peopleFilter(query, company, contact) {
    if (
      (company.position &&
        company.position.toLowerCase().indexOf(query) != -1) ||
      (contact.firstName &&
        contact.firstName.toLowerCase().indexOf(query) != -1) ||
      (contact.lastName &&
        contact.lastName.toLowerCase().indexOf(query) != -1) ||
      (contact.firstName + " " + contact.lastName)
        .toLowerCase()
        .indexOf(query) != -1
    ) {
      return true;
    }
    return false;
  }

  formatPeople(contacts: any[]) {
    if (contacts === null) {
      return [];
    }
    const group = {};
    for (const contact of contacts) {
      if (contact.userId !== this.userAccount.userId) {
        group["peopleSearchResults"] = group["peopleSearchResults"] || [];
        group["peopleSearchResults"].push(contact);
      }
    }
    const pairs = [];
    if (
      group["peopleSearchResults"] &&
      group["peopleSearchResults"].length > 0
    ) {
      pairs.push({
        departmentName: "Colleagues",
        subtitle: "Found in this workspace",
        contacts: group["peopleSearchResults"],
      });
    }
    return pairs;
  }

  unsubscribe() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  unsubscribeGetDepartments() {
    if (this.departments_sub) {
      this.departments_sub.unsubscribe();
    }
  }

  public handleRoleClicked(team: Team) {
    this.networkService.selectTeam(team);
    if (this.searchTerm && this.teamsSearchResults.length) {
      this.analyticsService.buttonClickEvent("user_card", {
        flow: "workspace",
        card_type: "searched_team",
      });
    }
  }

  public showOrHideRole(team: Team) {
    if (
      this.sidenavType === SidenavType.ROLE_DETAILS &&
      this.selectedTeam?.id === team.id
    ) {
      this.selectedTeam = null;
      this.closeSidenav();
      return;
    }
    this.selectedTeam = team;
    this.sidenavType = SidenavType.ROLE_DETAILS;
  }

  public handleShowTeamMemberProfile(member: TeamMember) {
    if (!member.user?.userId) return;
    this.selectedUserId = member.user?.userId;
    this.sidenavType = SidenavType.USER_PROFILE;
  }

  public handleUpdateTeam(team: Team) {
    if (team.isActive) {
      let newTeams = addOrReplaceElement(
        this.teams,
        team,
        (t) => t.id === team.id
      );
      newTeams = this.teamsService.sortWorkspaceTeams(newTeams);
      this.teams = newTeams;
      if (this.selectedTeam?.id === team.id) {
        this.selectedTeam = team;
      }
    } else {
      // Remove team and close sidenav if open
      this.teams = this.teams.filter((t) => t.id !== team.id);

      if (this.selectedTeam?.id === team.id) {
        this.selectedTeam = null;
      }

      if (this.sidenavType === SidenavType.ROLE_DETAILS) {
        this.closeSidenav();
      }
    }
  }

  public handleCreateRoleClicked() {
    const matDialogRef = CreateRoleComponent.openDialog(this.matDialog, {
      workspaceId: this.workspaceId,
    });

    const subscription = matDialogRef.beforeClosed().subscribe({
      next: (result) => {
        if (!result?.team) return;
        this.teams = this.teamsService.sortWorkspaceTeams([
          ...this.teams,
          result.team,
        ]);
      },
    });

    this.subscriptions.add(subscription);
  }

  public onAvatarError() {
    this.isAvatarInvalid = true;
  }
}
