import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RoleDetailsDialogComponent } from "@modules/roles/role-details-dialog/role-details-dialog.component";
import { Team } from "types";
import { TeamsService } from "./../../core";

interface TeamWithMetadata extends Team {
  metadata: {
    isCurrentUserOnCall: boolean;
  };
}

@Component({
  selector: "app-profile-roles-list",
  templateUrl: "./profile-roles-list.component.html",
  styleUrls: ["./profile-roles-list.component.scss"],
})
export class ProfileRolesListComponent implements OnChanges {
  @Input() public teams: Team[] | null = null;

  public teamsWithMetadata: TeamWithMetadata[] | null = null;

  public constructor(
    private teamsService: TeamsService,
    private matDialog: MatDialog
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    const teams = changes["teams"]?.currentValue;
    if (teams === undefined) return;
    if (teams === null) {
      this.teamsWithMetadata = null;
    } else {
      this.teamsWithMetadata = this.teams.map((team) => {
        const isCurrentUserOnCall = this.teamsService.isCurrentUserOnCall(team);
        return {
          ...team,
          metadata: {
            isCurrentUserOnCall,
          },
        };
      });
    }
  }

  public handleTeamClick(team: TeamWithMetadata) {
    if (!team.workspace?.id) return;
    RoleDetailsDialogComponent.openDialog(this.matDialog, {
      team,
      workspaceId: team.workspace.id,
    });
  }
}
