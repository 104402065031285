<div *ngIf="!loading" class="page">
  <div class="content">
    <div class="floater">
      <div class="appear_anim_1">
        <img class="logo" src="../../assets/logo-v2.svg" alt="" />
        <div class="tagline">Where healthcare comes together</div>
      </div>
      <div class="buttons appear_anim_2">
        <div class="button">
          <a href="{{signUpUrl}}">
            <button id="signup" mat-button class="celo-secondary-button">
              SIGN UP
            </button>
          </a>
        </div>
        <div class="button">
          <button
            *ngIf="!loading"
            id="login"
            (click)="login()"
            mat-button
            class="celo-primary-button login-button"
          >
            <span>LOG IN</span>
          </button>
          <div *ngIf="loading" class="loading-spinner">
            <div class="spinner">
              <mat-spinner diameter="20" strokeWidth="3"></mat-spinner>
            </div>
          </div>
        </div>
        <div class="learn-more">
          <a class="celo-link" target="_blank" href="https://www.celohealth.com"
            >Learn more</a
          >
        </div>
      </div>
    </div>
    <div class="mobile appear_anim_4">
      <div class="text">Download Celo on your mobile</div>
      <div class="buttons">
        <a [href]="app.target_url" target="_blank" *ngFor="let app of apps">
          <img [src]="app.badge_url" alt="" />
        </a>
      </div>
    </div>
  </div>
  <div class="content carousel">
    <div class="container">
      <app-carousel>
        <div class="slide" *appCarouselItem>
          <div class="figure">
            <img src="../../assets/carousel/web-carousel-welcome-to-celo.svg" />
          </div>
          <h1>Welcome to Celo</h1>
          <p>Secure and compliant messaging for healthcare professionals.</p>
        </div>
        <div class="slide" *appCarouselItem>
          <div class="figure">
            <img
              src="../../assets/carousel/web-carousel-invite-your-team.svg"
            />
          </div>
          <h1>Invite your team. Big or small.</h1>
          <p>Designed to scale from small teams to big hospitals.</p>
        </div>
        <div class="slide" *appCarouselItem>
          <div class="figure">
            <img
              src="../../assets/carousel/web-carousel-keep-work-and-personal-separate.svg"
            />
          </div>
          <h1>Keep work and personal separate</h1>
          <p>Mute conversations and switch off on days off.</p>
        </div>
        <div class="slide" *appCarouselItem>
          <div class="figure">
            <img
              src="../../assets/carousel/web-carousel-capture-save-share.svg"
            />
          </div>
          <h1>Capture. Save. Share.</h1>
          <p>
            Dedicated secure library to separate patient images from your
            personal camera roll.
          </p>
        </div>
      </app-carousel>
    </div>
  </div>
</div>
<div *ngIf="loading" class="loading-screen">
  <app-empty-state
    [no_message]="true"
    [isLoading]="true"
    [spinner]="false"
    [animation]="true"
    [diameter]="70"
  ></app-empty-state>
</div>
<div *ngIf="!production" class="version">{{ version }}</div>
