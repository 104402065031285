import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { Team, TeamMember } from "types";
import { concatNotNull, createDialogConfig, updateElement } from "utils";
import { SnackbarService } from "./../../core/services/snackbar.service";
import { TeamsService } from "./../../core/services/teams.service";
import {
  UserSelectionList,
  UserSelectionListItem,
} from "./../../shared/basic-user-selection-list/basic-user-selection-list.component";

export interface RolesClockOutData {
  team: Team;
  minQueryLength?: number;
}

export interface RoleClockOutDialogResult {
  isClockedOut: boolean;
  clockedInUserIds: string[];
}

const MIN_QUERY_LENGTH = 3;

@Component({
  selector: "app-roles-clock-out",
  templateUrl: "./roles-clock-out.component.html",
  styleUrls: ["./roles-clock-out.component.scss"],
})
export class RolesClockOutComponent implements OnInit {
  public query = new UntypedFormControl("");
  private users: UserSelectionList | null = null;
  public filteredUsers: UserSelectionList = [];
  public selectedMember: TeamMember | null = null;

  private valueChangeSubscription: Subscription | null = null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: RolesClockOutData,
    private matDialogRef: MatDialogRef<
      RolesClockOutComponent,
      RoleClockOutDialogResult
    >,
    private snackbarService: SnackbarService,
    private teamsService: TeamsService,
    private matDialog: MatDialog
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: RolesClockOutData
  ): MatDialogRef<RolesClockOutComponent, RoleClockOutDialogResult> {
    const config = createDialogConfig<RolesClockOutData>(data);
    return matDialog.open<
      RolesClockOutComponent,
      RolesClockOutData,
      RoleClockOutDialogResult
    >(RolesClockOutComponent, config);
  }

  public ngOnInit(): void {
    const members = this.teamsService.filterOutLeftTeamMembers(
      this.dialogData.team.members ?? [],
      true
    );
    this.users = members.map((member) => {
      const user = member.user;

      if (!user?.userId) {
        throw new Error("Failed to find userId for team member");
      }

      const item: UserSelectionListItem = {
        name:
          user?.fullName ?? concatNotNull([user?.firstName, user?.lastName]),
        fetchImage: user?.profilePic != null,
        isDisabled: false,
        isSelected: false,
        isIdentityVerified: user?.identityVerified,
        isProfessionVerified: user?.professionVerified,
        isWorkspaceVerified: user?.workspaceVerified,
        id: user?.userId,
      };
      return item;
    });

    this.filteredUsers = this.users;

    this.observeQueryValue();
  }

  private observeQueryValue() {
    // Cancel any pending debounced function calls
    this.valueChangeSubscription?.unsubscribe();
    this.valueChangeSubscription = this.query.valueChanges
      .pipe(
        tap(this.clearIfEmpty.bind(this)),
        distinctUntilChanged(),
        debounceTime(200)
      )
      .subscribe({ next: this.search.bind(this) });
  }

  public clearIfEmpty(value: string) {
    if (value) return;
    this.observeQueryValue();
    this.clearSearch();
  }

  public handleEnter() {
    this.observeQueryValue();
    this.search(this.query.value);
  }

  private search(value: string) {
    const minQueryLength = this.dialogData.minQueryLength ?? MIN_QUERY_LENGTH;
    const lowerCaseValue = value.toLowerCase();
    if (value.length >= minQueryLength) {
      this.filteredUsers =
        this.users?.filter((u) =>
          u.name?.toLowerCase().includes(lowerCaseValue)
        ) ?? [];
    } else if (value.length === 0) {
      this.clearSearch();
    } else {
      this.snackbarService.show(
        `Please enter at least ${minQueryLength} characters to begin your search.`
      );
    }
  }

  private clearSearch() {
    this.filteredUsers = this.users ?? [];
  }

  private updateUserSelectionList(
    user: UserSelectionListItem,
    selectionList: UserSelectionList
  ): UserSelectionList {
    const unselectedUsers = selectionList.map((u) => ({
      ...u,
      isSelected: false,
    }));
    const updated = updateElement(
      unselectedUsers,
      (u) => u.id === user.id,
      () => user
    );
    return updated;
  }

  public handleUser(user: UserSelectionListItem) {
    this.users = this.updateUserSelectionList(user, this.users ?? []);
    this.filteredUsers = this.updateUserSelectionList(user, this.filteredUsers);

    if (user.isSelected) {
      this.selectedMember =
        this.dialogData.team.members?.find((m) => m.user?.userId === user.id) ??
        null;
    } else {
      this.selectedMember = null;
    }
  }

  public handleAssignAndClockOut() {
    if (!this.dialogData.team.id || !this.selectedMember?.user?.userId) return;
    const clockInUserIds = [this.selectedMember.user?.userId];
    this.teamsService
      .clockOut(this.dialogData.team.id, clockInUserIds)
      .subscribe({
        next: () =>
          this.matDialogRef.close({
            isClockedOut: true,
            clockedInUserIds: clockInUserIds,
          }),
        error: () => AlertComponent.openErrorDialog(this.matDialog),
      });
  }

  public handleClockOut() {
    if (!this.dialogData.team.id) return;
    this.teamsService.clockOut(this.dialogData.team.id).subscribe({
      next: () =>
        this.matDialogRef.close({
          isClockedOut: true,
          clockedInUserIds: [],
        }),
      error: () => AlertComponent.openErrorDialog(this.matDialog),
    });
  }
}
