import { Injectable } from "@angular/core";
import { first, map, Observable } from "rxjs";
import {
  ApiRequestOptions,
  CompanyBasicModelApiPagedResult,
  CompanyModelPagedResult,
  ContactModelPagedResult,
  GetCompaniesRequestOptions,
  GetCompanyContactsRequestOptions,
  PagedRequestOptions,
  CompanyModel,
} from "types";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  public constructor(private apiService: ApiService) {}

  public getContacts({
    companyId,
    fetchAll,
    ...options
  }: GetCompanyContactsRequestOptions &
    ApiRequestOptions): Observable<ContactModelPagedResult> {
    const path = `/api/Companies/${companyId}/Contacts`;
    const defaultOptions: Omit<GetCompanyContactsRequestOptions, "companyId"> =
      {
        pageSize: 100,
      };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }

  public getCompaniesV1({
    fetchAll,
    ...options
  }: PagedRequestOptions &
    ApiRequestOptions): Observable<CompanyModelPagedResult> {
    const path = `/api/Companies`;
    const defaultOptions: GetCompaniesRequestOptions = {
      pageSize: 100,
    };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }

  public getCompanyV1(companyId: string): Observable<CompanyModel> {
    const defaultOptions: GetCompaniesRequestOptions = {
      pageSize: 100,
    };
    const path = `/api/Companies`;

    // Note: would be a lot better if the backend had an endpoint to do this. Alternatively we could store this data
    // locally, but it could become stale.
    return this.apiService
      .getAllByOffset<CompanyModelPagedResult>({
        path,
        queryParams: { ...defaultOptions },
      })
      .pipe(
        map((response) => response.data.filter((c) => c.id === companyId)?.[0]),
        first()
      );
  }

  public getCompaniesV2({
    fetchAll,
    ...options
  }: GetCompaniesRequestOptions &
    ApiRequestOptions): Observable<CompanyBasicModelApiPagedResult> {
    const path = `/api/v2/Companies`;
    const defaultOptions: GetCompaniesRequestOptions = {
      pageSize: 100,
    };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }
}
