import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-feedback-page",
  templateUrl: "./feedback-page.component.html",
  styleUrls: ["./feedback-page.component.scss"],
})
export class FeedbackPageComponent {
  @Input() src = "../../../assets/company-added.png";
  @Input() title = "";
  @Input() subtitle = "";
  @Input() subsubtitle = "";
  @Output() primaryAction = new EventEmitter<any>();
}
