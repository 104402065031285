<h4 class="mat-h4 title">External Participants</h4>

<app-basic-user-avatar-card
  [userId]="externalUserId"
  [name]="externalFullName"
  [descriptionItems]="[
    { content: externalDateOfBirth | dateFormat: 'dateOfBirth' },
    { content: externalPhoneNumber | phoneFormat }
  ]"
  [fetchImage]="false"
  [padding]="'9px 20px'"
  [isExternal]="true"
>
  <div end class="flex place-items-center">
    <button
      *ngIf="isCurrentUserAdmin"
      class="celo-link"
      [style.height]="'min-content'"
      mat-button
      (click)="editExternalDetails()"
    >
      Edit
    </button>
  </div>
</app-basic-user-avatar-card>

<mat-divider></mat-divider>

<div class="button">
  <button
    mat-button
    class="celo-tertiary-button celo-hoverable fw celo-extended-button export-button"
    (click)="onExportToPdf()"
  >
    <i class="material-icons ml-2">file_download</i>
    <span class="text">Export to PDF</span>
  </button>
</div>

<mat-divider></mat-divider>

<h4 class="mat-h4 title participants">Participants</h4>

<button
  *ngIf="isCurrentUserAdmin"
  mat-button
  class="celo-hoverable fw add-participants"
  (click)="addParticipants()"
>
  <div class="content">
    <img
      src="../../../../assets/icons/ic-add-filled.svg"
      alt="Add participants icon"
      height="28"
      width="28"
    />
    <span class="text">Add participants</span>
  </div>
</button>

<app-conversation-participants-list
  [participants]="participants"
  [currentUserId]="currentUserId"
  removeParticipantText="Remove from External Chat"
  (action)="handleParticipantAction($event)"
></app-conversation-participants-list>

<div *ngIf="showLeaveButton" class="leave-button celo-hoverable mt-2">
  <button
    (click)="leaveExternalChat(); closeClick.emit()"
    mat-button
    class="fw"
  >
    <img src="../../../../assets/icons/icon-leave.svg" />
    <span>Leave External Chat</span>
  </button>
</div>
