import { ApiRequestOptions, FullUserProfileModel, GetUserWorkspacesOptions, UserWorkspaceModel, UserWorkspaceModelApiPagedResult } from "@types";
import { BehaviorSubject, Observable, distinctUntilChanged, map } from "rxjs";
import { UserServiceProvider } from "../services";

export class FakeUserService implements UserServiceProvider {
    user: FullUserProfileModel | null = null;

    currentUserSubject = new BehaviorSubject<FullUserProfileModel | null>(null);
    currentUser$ = this.currentUserSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    userId$ = this.currentUserSubject.asObservable().pipe(
        map((value) => value?.userId ?? null),
        distinctUntilChanged()
    );

    isWorkspaceAdmin$: Observable<boolean>;
    isNewExternalChatEnabled$: Observable<boolean>;

    public setTestUser(user: FullUserProfileModel) {
        this.user = user;
    }

    updateIsWorkspaceAdminStatus(): void {
        throw new Error("Method not implemented.");
    }
    getUserObservable(): Observable<FullUserProfileModel> {
        throw new Error("Method not implemented.");
    }
    loadUser(): void {
        throw new Error("Method not implemented.");
    }
    getUser(): FullUserProfileModel {
        return this.user;
    }
    getUserId(required?: boolean): string {
        return this.user?.userId ?? null;
    }
    getUserWorkspaces(options?: GetUserWorkspacesOptions & ApiRequestOptions): Observable<UserWorkspaceModelApiPagedResult> {
        throw new Error("Method not implemented.");
    }
    getUserWorkspace(workspaceId: string): Observable<UserWorkspaceModel> {
        throw new Error("Method not implemented.");
    }
    getCountryCode(): string {
        throw new Error("Method not implemented.");
    }
    isDoNotDisturbActive(): boolean {
        throw new Error("Method not implemented.");
    }

}