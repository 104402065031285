import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "possessive",
})
export class PossessivePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    // Note - this will not handle every case, and it will only with English.
    if (typeof value !== "string") return value;
    if (value.endsWith("s")) {
      return `${value}'`;
    }
    return `${value}'s`;
  }
}
