import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MessageComposeMemoryModel } from '@modules/core/old/memory.service';
import {
  ContactModel,
  FullUserProfileModel,
  MentionModel,
  MentionType,
  ParticipantModel,
} from "types";
import { MessageService, SharedService } from "../../modules/core";
import { MessageComposeModel } from "../message-input/message-input.component";

const spanOpeningRegx = /<span[^>]*>/i;
const spanClosingRegx = /<\/span>/;

const MENTION_EXIT_KEYS = ["\n"];
const VALID_MENTION_INITIATING_KEYS = ["@"];
const MAX_ALLOWED_CHARACTERS = 3000;

@Component({
  selector: "app-messages-compose",
  templateUrl: "./messages-compose.component.html",
  styleUrls: ["./messages-compose.component.scss"],
})
export class MessagesComposeComponent implements OnInit, OnChanges, OnDestroy {
  default_height = 50;
  @ViewChild("txtArea", { static: true })
  textArea: ElementRef<HTMLTextAreaElement>;

  @Output() pastedEvent = new EventEmitter<any>();
  @Output() submitEvent = new EventEmitter<any>();
  @Output() textChangeEvent = new EventEmitter<any>();
  @Input() conversationId: string;
  @Input() typedMessagesCache: MessageComposeMemoryModel = {};
  @Input() participants: ParticipantModel[];
  @Input() participantsList: ParticipantModel[];
  @Input() userAccount: FullUserProfileModel;

  contentHtml: string = "";
  composedMessage: MessageComposeModel = null;
  caretIndex: number = 0;
  previousInputs: string[] = [];
  showSuggestion: boolean = false;
  lastWordBlock: string;
  emojiSub: any;
  messageSub: any;
  quoteSub: any;
  conversationIdPrev: string;

  constructor(
    private messageService: MessageService,
    private sharedService: SharedService,
    private changeDetecterRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.default_height = this.textArea.nativeElement.offsetHeight;
    this.focusTextarea();
    this.messageSub = this.messageService.onMessageSubmitSubject.subscribe(
      () => {
        this.clearAll();
      }
    );

    this.emojiSub = this.messageService.onInsertEmojiSubject.subscribe(
      (event) => {
        this.addEmoji(event);
      }
    );

    this.quoteSub = this.messageService.messageQuoted.subscribe(() => {
      this.focusTextarea();
    });
  }

  ngOnDestroy() {
    if (this.emojiSub) this.emojiSub.unsubscribe();
    if (this.quoteSub) this.quoteSub.unsubscribe();
    if (this.messageSub) this.messageSub.unsubscribe();
  }

  clearAll = () => {
    this.textArea.nativeElement.innerText = "";
    this.textArea.nativeElement.innerHTML = "";
    this.composedMessage = { content: "" };
    this.closeSuggestions();
  };

  ngOnChanges() {
    if (this.conversationId != this.conversationIdPrev) {
      this.conversationIdPrev = this.conversationId;
      this.contentHtml = "";
      this.focusTextarea();
      this.composedMessage = this.typedMessagesCache[this.conversationId];
      if (!this.composedMessage || !this.composedMessage.content) {
        this.clearAll();
      }
      this.contentHtml = this.messageService.getFormatedHtmlWithMentions(
        this.typedMessagesCache[this.conversationId].content,
        this.typedMessagesCache[this.conversationId].mentions,
        "mention"
      );
      this.changeDetecterRef.detectChanges();
      this.resetIds(this.typedMessagesCache[this.conversationId].mentions);
      this.caretIndex = this.textArea.nativeElement.innerText.length;
      this.resetContentEditable();
    }
    this.conversationIdPrev = this.conversationId;
  }

  initialize() {
    this.formatToHtml();
  }

  resetMentionsBasedOnCurrentMarkup() {
    if (!this.typedMessagesCache[this.conversationId]) {
      this.typedMessagesCache[this.conversationId] = {
        content: "",
      };
    }

    this.typedMessagesCache[this.conversationId].mentions = [];
    const target = this.textArea.nativeElement;
    const innerHtml: string = target.innerHTML;
    let tempHtml = innerHtml;
    const mentions = [];
    tempHtml = tempHtml.replace(/&nbsp;/g, " ");
    let startIndex = 0;
    let endIndex = 0;
    let tag = "";
    let name = "";
    let id = "";
    while (tempHtml.indexOf(`<span class="mention"`) != -1) {
      startIndex = tempHtml.indexOf(`<span class="mention"`);
      endIndex = tempHtml.indexOf(`</span>`);
      tag = tempHtml.substr(startIndex, endIndex - startIndex + 7);
      const idStartIndex = tag.indexOf("mentionid=");
      id = tag.substr(idStartIndex + 11, tag.length);
      const idEndIndex = id.indexOf(`"`);
      id = id.substr(0, idEndIndex);
      tempHtml = tempHtml.replace(spanOpeningRegx, "");
      tempHtml = tempHtml.replace(spanClosingRegx, "");
      name = tag;
      name = name.replace(spanOpeningRegx, "");
      name = name.replace(spanClosingRegx, "");
      mentions.push({
        index: startIndex,
        name,
        id,
      });
      this.typedMessagesCache[this.conversationId].mentions = mentions;
    }
  }

  formatToHtml() {
    if (this.typedMessagesCache[this.conversationId]?.content) {
      this.contentHtml = this.textArea.nativeElement.innerHTML;
      // This is required to force change detection.
      // This forces the contentHtml to be updated in next event loop thereby causing change detection.
      setTimeout(() => {
        this.contentHtml = this.messageService.getFormatedHtmlWithMentions(
          this.typedMessagesCache[this.conversationId].content,
          this.typedMessagesCache[this.conversationId].mentions,
          "mention"
        );
      }, 0);
      this.changeDetecterRef.detectChanges();
      this.resetIds(this.typedMessagesCache[this.conversationId].mentions);

      setTimeout(() => {
        this.resetContentEditable();
        if (this.textArea.nativeElement.innerText)
          this.caretIndex = this.textArea.nativeElement.innerText.length;
      }, 100);
    }
  }

  resetContentEditable() {
    let editable_elements = document.querySelectorAll(".mention");
    editable_elements.forEach((element) => {
      element.setAttribute("contenteditable", "false");
    });
  }

  resetIds(mentions: MentionModel[]) {
    setTimeout(() => {
      let editable_elements = document.querySelectorAll(".mention");
      let index = 0;
      editable_elements.forEach((element) => {
        element.setAttribute("mentionid", mentions[index].id);
        index++;
      });
    }, 100);
  }

  highlightMentions() {
    let index = 3;
    if (this.textArea?.nativeElement?.setSelectionRange)
      this.textArea.nativeElement.setSelectionRange(index, index + 8);
  }

  focusTextarea() {
    setTimeout(() => {
      this.setEndOfContenteditable(this.textArea.nativeElement);
    }, 100);
  }

  setEndOfContenteditable = (contentEditableElement: Node) => {
    let range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    let selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  };

  onNewMentionClicked(participant: ContactModel) {
    this.closeSuggestions();
    let correction = this.clearIncompleteMentionText();
    this.offestExistingMentionsWithCorrection(correction, this.caretIndex);
    let content = this.typedMessagesCache[this.conversationId]?.content;
    let mentionName = `@${participant.firstName} ${participant.lastName}`;
    content =
      content.slice(0, this.caretIndex) +
      mentionName +
      " " +
      content.slice(this.caretIndex);

    this.typedMessagesCache[this.conversationId].content = content;

    this.insertNewMention(
      {
        id: participant.userId,
        name: mentionName,
        index: this.caretIndex,
        type: MentionType.User,
      },
      mentionName.length + 2 //  +2 for @ at the beginning and space at the end
    );
    this.formatToHtml();
    this.focusTextarea();
  }

  clearIncompleteMentionText = (): number => {
    let currentContent = this.typedMessagesCache[this.conversationId].content;
    let index = this.caretIndex;
    for (; index >= 0; index--) {
      if (currentContent[index] === "@") break;
    }
    currentContent =
      currentContent.substring(0, index) +
      currentContent.substring(this.caretIndex, currentContent.length);
    this.typedMessagesCache[this.conversationId].content = currentContent;
    let correctionLength = this.caretIndex - index;
    this.caretIndex = index;
    return correctionLength;
  };

  insertNewMention(newMention: MentionModel, offset: number) {
    let pivot = 0;
    let mentions = this.getCurrentMentions();
    mentions.forEach((mention) => {
      if (mention.index >= newMention.index) return;
      pivot++;
    });

    mentions.splice(pivot, 0, newMention);
    pivot++;
    for (; pivot < mentions.length; pivot++) {
      mentions[pivot].index += offset;
    }
    this.typedMessagesCache[this.conversationId].mentions = mentions;
  }

  getCurrentMentions = (): MentionModel[] => {
    let mentions = [];
    if (this.typedMessagesCache[this.conversationId]?.mentions)
      mentions = this.typedMessagesCache[this.conversationId].mentions;
    return mentions;
  };

  offestExistingMentionsWithCorrection(error, index) {
    if (!this.typedMessagesCache[this.conversationId].mentions) return;
    this.typedMessagesCache[this.conversationId].mentions.forEach((mention) => {
      if (mention.index >= index) mention.index -= error + 1;
    });
  }

  /**
   * Get the last block of word ending at the current index in question
   */
  getLastWordBlock = (text, index): string => {
    let lastWord = "";
    if (!text) return lastWord;
    for (let i = index; i >= 0; i--) {
      if (!text[i]) continue;
      if (this.isIndexAlreadyAMention(i)) {
        // index is already a mention
        break;
      }
      if (MENTION_EXIT_KEYS.indexOf(text[i]) != -1) {
        break;
      } else if (VALID_MENTION_INITIATING_KEYS.indexOf(text[i]) != -1) {
        lastWord = text[i] + lastWord;
        if (!text[i - 1] || text[i - 1] == " ") {
          break;
        }
      } else {
        lastWord = text[i] + lastWord;
      }
    }
    return lastWord;
  };

  isIndexAlreadyAMention = (index: number): boolean => {
    let mentioned = false;
    let mentions = this.getCurrentMentions();
    mentions.forEach((mention) => {
      let startIndex = mention.index;
      if (index >= startIndex && index <= startIndex + mention.name.length) {
        mentioned = true;
        return;
      }
    });
    return mentioned;
  };

  change = () => {
    let prevCaretIndex = this.caretIndex;
    this.caretIndex = this.getCaretIndex();
    if (Math.abs(prevCaretIndex - this.caretIndex) > 1) {
      this.closeSuggestions();
    }
    let content = this.typedMessagesCache[this.conversationId]?.content;
    this.lastWordBlock = this.getLastWordBlock(content, this.caretIndex - 1);
    if (
      this.lastWordBlock &&
      VALID_MENTION_INITIATING_KEYS.indexOf(this.lastWordBlock[0]) != -1
    ) {
      setTimeout(() => {
        this.closeSuggestions();
        if (this.lastWordBlock) {
          this.showSuggestions();
          this.filterParticipants(this.lastWordBlock);
        }
      }, 100);
    } else {
      this.closeSuggestions();
    }
    this.resetMentionsBasedOnCurrentMarkup();
    // this.contentHtml = this.textArea.nativeElement.innerHTML;
  };

  showSuggestions() {
    this.showSuggestion = true;
  }

  closeSuggestions() {
    this.showSuggestion = false;
    this.participantsList = [];
  }

  /**
   * Get the current index of the caret
   */
  getCaretIndex() {
    let element = this.textArea.nativeElement;
    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    return position;
  }

  filterParticipants = (keyword: string) => {
    let searchKeyword = keyword.substring(1);
    if (!searchKeyword) {
      this.participantsList = this.participants;
      return;
    }
    let name: string;
    let firstName: string;
    let lastName: string;
    searchKeyword = searchKeyword.toLocaleLowerCase();
    this.participantsList = this.participants.filter(
      (participant: ParticipantModel) => {
        firstName = participant.firstName ? participant.firstName : "";
        firstName = firstName.toLocaleLowerCase();
        lastName = participant.lastName ? participant.lastName : "";
        lastName = lastName.toLocaleLowerCase();
        name = firstName + " " + lastName;
        if (
          firstName.startsWith(searchKeyword) ||
          lastName.startsWith(searchKeyword) ||
          name.startsWith(searchKeyword)
        ) {
          return true;
        }
        // if (name.indexOf(searchKeyword) == -1) {
        //   return false;
        // }
        return false;
      }
    );
  };

  onChange(event) {
    // allow back space, arrowkeys, shift,cmd
    if (
      event.keyCode === 8 ||
      event.keyCode === 16 ||
      event.keyCode === 91 ||
      event.key.startsWith("Arrow") ||
      event.key.startsWith("Alt") ||
      event.key.startsWith("Contol") ||
      event.key.startsWith("Meta")
    )
      return;
    let content: string = this.textArea.nativeElement.innerText;
    if (content.length >= MAX_ALLOWED_CHARACTERS) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    // TODO: as content is being edited programmatically here it will not work with
    // the default undo (e.g. ctrl-z) behaviour. A possible solution is to implement as 'undo stack'
    // and handle things manually.

    event.preventDefault();
    let text = event.clipboardData.getData("text/plain");

    // Insert text at the current position of caret
    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    // Filter all the html formatting from the pasted content.
    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    this.typedMessagesCache[this.conversationId] = this.typedMessagesCache[
      this.conversationId
    ]
      ? this.typedMessagesCache[this.conversationId]
      : { content: "" };
    this.typedMessagesCache[this.conversationId].content =
      this.textArea.nativeElement.innerText;

    setTimeout(() => {
      let content: string = this.textArea.nativeElement.innerText;
      if (content.length >= MAX_ALLOWED_CHARACTERS) {
        this.truncateContent();
        this.focusTextarea();
        this.change();
      }
    }, 0);

    this.pastedEvent.emit(event);
    this.change();
  }

  truncateContent() {
    let content: string = this.textArea.nativeElement.innerText;
    if (content.length >= MAX_ALLOWED_CHARACTERS) {
      content = content.substring(0, MAX_ALLOWED_CHARACTERS);
      this.textArea.nativeElement.innerText = content;
      this.typedMessagesCache[this.conversationId].content = content;
    }
  }

  addEmoji(event) {
    this.closeSuggestions();
    if (!this.typedMessagesCache[this.conversationId]) {
      this.typedMessagesCache[this.conversationId] = { content: "" };
    }

    const startPos = this.caretIndex;
    if (event && event["emoji"] && event["emoji"].native) {
      const emoji = event["emoji"].native;
      const message = this.typedMessagesCache[this.conversationId];
      let newMessage =
        message.content.substring(0, startPos) +
        emoji +
        message.content.substring(startPos);
      this.typedMessagesCache[this.conversationId].content = newMessage;
      this.offestExistingMentionsWithCorrection(
        -1 * (emoji.length + 1),
        startPos
      );
      this.formatToHtml();
      this.focusTextarea();
    }
  }

  returnPressed() {
    if (
      this.showSuggestion &&
      this.participantsList &&
      this.participantsList.length
    )
      return;
    this.submitEvent.emit();
  }
}
