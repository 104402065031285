import { environment } from "@env";

export interface GenerateUpgradeUrlOptions {
  /** Should be prefixed with a leading slash. If there is none one will be automatically added. */
  redirectPath?: string;
}

export const generateUpgradeUrl = (
  options?: GenerateUpgradeUrlOptions
): string => {
  let path: string = "";
  if (options?.redirectPath && !options.redirectPath.startsWith("/")) {
    path = `/${path}`;
  }

  const upgradeUrlSearchParams = new URLSearchParams();
  upgradeUrlSearchParams.set("ref", `${location.origin}${path}`);

  const upgradeUrl = `${environment.upgradeUrl}?${upgradeUrlSearchParams}`;

  return upgradeUrl;
};

export const generateManageUsersUrl = (companyId: string): string => {
  return `${environment.workspaceAdminUrl}workspaces/${companyId}`;
};

export const generateAddBillingInfoReturnUrl = (companyId: string): string => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set("returnUrl", window.location.pathname);
  urlSearchParams.set("action", "add-billing-info");
  urlSearchParams.set("companyId", companyId);

  return `${window.origin}/processing?${urlSearchParams}`;
};
