import { Component, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-celo-button-pair",
  templateUrl: "./celo-button-pair.component.html",
  styleUrls: ["./celo-button-pair.component.scss"],
})
export class CeloButtonPairComponent {
  @Input() height = 40;
  @Input() primaryText = "Save";
  @Input() secondaryText = "Cancel";
  @Input() primaryDisabled = false;
  @Input() secondaryDisabled = false;
  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();
}
