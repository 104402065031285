<div>
  <button
    mat-button
    class="celo-secondary-button thin-font mr-2"
    (click)="secondaryAction.emit($event)"
    [style.height.px]="height"
    [disabled]="secondaryDisabled"
  >
    {{ secondaryText }}
  </button>
  <button
    mat-button
    class="celo-primary-button mr-2"
    (click)="primaryAction.emit($event)"
    [style.height.px]="height"
    [disabled]="primaryDisabled"
  >
    {{ primaryText }}
  </button>
</div>
