import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Role } from "@types";
import {
  OAuthEvent,
  OAuthInfoEvent,
  OAuthService,
  TokenResponse,
} from "angular-oauth2-oidc";
import { authCodeFlowConfig } from "app/auth-code-flow.config";
import { environment } from "environments/environment";
import { JWTPayload, decodeJwt } from "jose";
import { BehaviorSubject, Observable, merge, of } from "rxjs";
import { distinctUntilChanged, filter, map, share, take } from "rxjs/operators";
import { ApplicationInsightsService } from "./application-insights.service";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private userIdSubject = new BehaviorSubject<string | null>(null);
  public userId$ = this.userIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isDepartmentAdminSubject = new BehaviorSubject<boolean>(false);
  public isDepartmentAdmin$ = this.isDepartmentAdminSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isBroadcasterSubject = new BehaviorSubject<boolean>(false);
  public isBroadcaster$ = this.isBroadcasterSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private dataRegionSubject = new BehaviorSubject<string | null>(null);
  public dataRegion$ = this.dataRegionSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private timeoutMs: number = 5000;
  private timeout: NodeJS.Timeout | null = null;

  private storageListener: ((event: StorageEvent) => void) | null = null;
  private refreshTokenPromise: Promise<TokenResponse> | null = null;

  private automaticRefreshTokenPollingInterval: number = 5000;
  private automaticRefreshTokenTimeout: NodeJS.Timeout | null = null;
  private automaticRefreshMaxRetries: number = 3;

  private onboardingDataRegion: string | null = null;
  public isRefreshingToken: boolean = false;

  public constructor(
    private oauthService: OAuthService,
    private appInsightsService: ApplicationInsightsService,
    private router: Router
  ) {
    this.oauthService.events.subscribe({
      next: (oauthEvent) => this.handleOAuthEvent(oauthEvent),
    });

    this.storageListener = (event: StorageEvent) => {
      if (event.key !== "access_token") return;
      const isAuthenticated = this.oauthService.hasValidAccessToken();
      this.isAuthenticatedSubject.next(isAuthenticated);
      if (this.isAuthenticated()) return;
      this.router.navigate(["/"]);
    };

    window.addEventListener("storage", this.storageListener);

    this.updateState();
  }

  public refreshToken(): Observable<void> {
    return new Observable<void>((subscriber) => {
      if (!this.refreshTokenPromise) {
        if (!environment.production) {
          // eslint-disable-next-line no-console
          console.log(`[${new Date().toISOString()}] Refreshing token`);
        }
        this.refreshTokenPromise = this.oauthService.refreshToken();
      }

      this.refreshTokenPromise
        .then(() => {
          subscriber.next();
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.error(e);
        })
        .finally(() => {
          this.refreshTokenPromise = null;
        });
    }).pipe(share());
  }

  private setupAutomaticTokenRefresh() {
    let isTokenExpiring = false;
    let retryCount = 0;

    // CELO-9985
    const scheduleCheck = (ms?: number, isRetry?: boolean) => {
      this.automaticRefreshTokenTimeout = setTimeout(
        () => checkAndRefreshToken(isRetry),
        ms ?? this.automaticRefreshTokenPollingInterval
      );
    };

    const scheduleRetry = () => {
      const isMaxRetryAttempts = retryCount >= this.automaticRefreshMaxRetries;
      const delay = isMaxRetryAttempts
        ? this.automaticRefreshTokenPollingInterval
        : Math.min(2 ** retryCount * 1000, 60000);
      scheduleCheck(delay, !isMaxRetryAttempts);

      if (isMaxRetryAttempts) {
        // eslint-disable-next-line no-console
        console.log(
          `[${new Date().toISOString()}] Logging user out as token refresh failed after ${retryCount} retry attempt(s)`
        );
        this.logout(
          `Token refresh failed after ${retryCount} retry attempt(s)`
        );
        retryCount = 0;
        return;
      }

      // eslint-disable-next-line no-console
      console.log(
        `[${new Date().toISOString()}] Retrying token refresh in ${delay} milliseconds`
      );
    };

    const checkAndRefreshToken = (isRetry?: boolean) => {
      if (isRetry) retryCount += 1;

      // Refresh if the user has authenticated and their access token is no longer valid or about to expire
      const isRefreshRequired =
        this.oauthService.getAccessToken() &&
        (!this.oauthService.hasValidAccessToken() || isTokenExpiring) &&
        !this.isRefreshingToken;

      if (!isRefreshRequired) {
        retryCount = 0;
        scheduleCheck();
        return;
      }

      if (!this.oauthService.getRefreshToken()) {
        // We can't do anything if there's no refresh token, but there's a chance another tab may set one
        // eslint-disable-next-line no-console
        console.log(
          `[${new Date().toISOString()}] Failed to find refresh token`
        );

        if (this.oauthService.hasValidAccessToken()) {
          scheduleCheck();
        } else {
          scheduleRetry();
        }
        return;
      }

      this.isRefreshingToken = true;
      this.refreshToken().subscribe({
        complete: () => {
          this.isRefreshingToken = false;
          isTokenExpiring = false;
          retryCount = 0;
          scheduleCheck();
        },
        error: (e) => {
          this.isRefreshingToken = false;
          //Error received is from auth server
          if (e.status === 400 && e.error.error === "invalid_grant") {
            scheduleRetry();
            return;
          }
          scheduleCheck();
        },
      });
    };

    this.oauthService.events
      .pipe(
        filter((e) => e.type === "token_expires"),
        map((e) => e as OAuthInfoEvent),
        filter((e) => e.info === "access_token")
      )
      .subscribe({
        next: () => {
          isTokenExpiring = true;
        },
      });

    window.addEventListener("storage", (event: StorageEvent) => {
      if (event.key !== "access_token") return;
      const remainingLifeTime =
        this.oauthService.getAccessTokenExpiration() - Date.now();
      isTokenExpiring = remainingLifeTime < 60000;
    });

    checkAndRefreshToken();
  }

  private saveToLocalStorage(key: string, value: string) {
    if (!key || !value) return;
    localStorage.setItem(key, value);
  }

  private getParamFromUrl(param: string): string | null {
    try {
      const url = new URL(location.href);
      const code = url.searchParams.get(param);
      return code ?? null;
    } catch (err) {
      return null;
    }
  }

  public getInvitationCode(): string | null {
    const invitationCode = localStorage.getItem("invitation_code");
    return invitationCode ?? null;
  }

  public getProductTrialType(): string | null {
    const productTrialType = localStorage.getItem("product_trial_type");
    return productTrialType ?? null;
  }

  public removeInvitationCode() {
    localStorage.removeItem("invitation_code");
  }

  /**
   * This method should be called during app initialization.
   *
   * See: https://angular.io/api/core/APP_INITIALIZER
   */
  public initialize(): Observable<void> {
    const observable = new Observable<void>((subscriber) => {
      // eslint-disable-next-line no-console
      console.log(`[${new Date().toISOString()}] Initializing auth`);

      this.oauthService.configure(authCodeFlowConfig);

      const invitationCode = this.getParamFromUrl("invitation_code");
      if (invitationCode) {
        this.saveToLocalStorage("invitation_code", invitationCode)
      }

      const productTrialType = this.getParamFromUrl("product_trial_type");
      if (productTrialType) {
        this.saveToLocalStorage("product_trial_type", invitationCode)
      }

      this.oauthService
        .loadDiscoveryDocumentAndTryLogin({
          preventClearHashAfterLogin: true,
        })
        .then(() => {
          if (
            this.oauthService.hasValidAccessToken() ||
            !this.oauthService.getRefreshToken()
          ) {
            return Promise.resolve();
          }
          return this.refreshToken()
            .toPromise()
            .then(() => {
              return Promise.resolve();
            })
            .catch(() => {
              this.logout("Failed to refresh token during app initialization");
              return Promise.reject();
            });
        })
        .then(() => {
          subscriber.complete();
        })
        .catch(() => {
          subscriber.complete();
        })
        .finally(() => {
          this.setupAutomaticTokenRefresh();
        });
    });

    return observable;
  }

  private beforeLogin() {
    // Don't remove invitation code as invite code may be for a new user
    const invitationCode = this.getInvitationCode();
    const productTrialType = this.getProductTrialType();

    // This is to support legacy code in the onboarding flow
    const onboardInfo = localStorage.getItem("onboard_info");

    localStorage.clear();

    if (invitationCode) {
      this.saveToLocalStorage("invitation_code", invitationCode);
    }

    if (productTrialType) {
      this.saveToLocalStorage("product_trial_type", productTrialType);
    }

    if (onboardInfo) {
      localStorage.setItem("onboard_info", onboardInfo);
    }
  }

  public login() {
    this.beforeLogin();
    this.oauthService.customQueryParams = {
      prompt: "login",
    };
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  public loginWithoutPrompt() {
    this.beforeLogin();
    this.oauthService.customQueryParams = {
      prompt: "none",
    };
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  public logout(reason: string) {
    this.appInsightsService.trackTrace("Logout", {
      reason,
    });
    this.appInsightsService.flush().subscribe({
      complete: () => this.oauthService.revokeTokenAndLogout({}, true),
      error: () => this.oauthService.revokeTokenAndLogout({}, true),
    });
  }

  public afterLogout() {
    localStorage.clear();
  }

  public ngOnDestroy(): void {
    if (this.automaticRefreshTokenTimeout) {
      clearTimeout(this.automaticRefreshTokenTimeout);
    }

    this.clearRefreshTimeout();

    if (this.storageListener) {
      window.removeEventListener("storage", this.storageListener);
    }
  }

  private handleOAuthEvent(event: OAuthEvent) {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log(`[${new Date().toISOString()}]`, event);
    }
    this.updateState();
  }

  private updateState() {
    this.clearRefreshTimeout();
    this.isDepartmentAdminSubject.next(this.hasRole(Role.DepartmentAdmin));
    this.isBroadcasterSubject.next(this.hasRole(Role.Broadcaster));
    this.userIdSubject.next(this.getClaim<string>("sub"));

    // Only update the data region if there is an actual value, otherwise use whatever the current value is
    const dataRegion = this.getClaim<string>("data_region");
    if (dataRegion) this.dataRegionSubject.next(dataRegion);

    this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
    this.startRefreshTimeout();
  }

  private clearRefreshTimeout() {
    if (this.timeout === null) return;
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  private startRefreshTimeout() {
    // Force state to be refreshed if it hasn't been updated within `timeoutMs`
    this.timeout = setTimeout(() => this.updateState(), this.timeoutMs);
  }

  public getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  public hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public getAccessTokenObservable(): Observable<string> {
    // Immediately emit current access token, or wait until a token is available
    const observable = merge(
      of(this.getAccessToken()).pipe(filter((token) => !!token)),
      this.oauthService.events.pipe(
        filter((e) => e.type === "token_received"),
        map(() => this.getAccessToken()),
        filter((token) => !!token)
      )
    ).pipe(take(1));
    return observable;
  }

  public getRefreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public getClaims(): JWTPayload {
    const token = this.getAccessToken();
    if (typeof token !== "string") return {};
    return decodeJwt(token);
  }

  private parseClaim(claim: unknown): string[] {
    if (typeof claim === "string") {
      return [claim];
    } else if (Array.isArray(claim)) {
      return claim;
    }
    return [];
  }

  public getClaim<T = unknown>(key: string, required: true): T;
  public getClaim<T = unknown>(key: string): T | null;
  public getClaim<T = unknown>(
    key: string,
    required: boolean = false
  ): T | null {
    const claims = this.getClaims();
    const value = claims[key];

    if (required && (value === null || value === undefined)) {
      throw new Error(`Failed to get claim '${key}'`);
    }

    return (value ?? null) as T | null;
  }

  public getRoles(): string[] {
    const claims = this.getClaims();
    let roles = this.parseClaim(claims.role);
    return roles;
  }

  public hasRole(role: Role): boolean {
    return this.getRoles().includes(role);
  }

  public hasRoles(roles: Role[]): boolean {
    return roles.every((role) => this.hasRole(role));
  }

  public isForceCreatePassword(): boolean {
    return this.getClaim<string>("force_create_password") === "true";
  }

  public isAuthenticated(): boolean {
    this.updateState();
    return this.isAuthenticatedSubject.value;
  }

  public getUserId(): string | null {
    return (this.getClaims().sub ?? null) as string | null;
  }

  public getDataRegion(): string | null {
    return this.onboardingDataRegion ?? this.dataRegionSubject.value;
  }

  /**
   * This should only be used to set the data region when a user is onboarding.
   */
  public setOnboardingDataRegion(dataRegion: string) {
    this.onboardingDataRegion = dataRegion;
  }
}
