import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { CustomDialogComponent } from "@modules/shared/custom-dialog/custom-dialog.component";
import { WorkspaceDetailsModel } from "@types";
import { createDialogConfig } from "@utils";
import { map, Observable, of, switchMap } from "rxjs";
import { SharedService } from "../../modules/core/old/shared.service";

export interface CreateWorkspaceDialogData {
  additionalSubmitAction?: (data: WorkspaceDetailsModel) => Observable<void>;
  disableBackdropClose?: boolean;
}

export type CreateWorkspaceDialogResult =
  | WorkspaceDetailsModel
  | { type: "join_workspace" };

@Component({
  selector: "app-create-workspace",
  templateUrl: "./create-workspace.component.html",
  styleUrls: ["./create-workspace.component.scss", "../../../celo-input.scss"],
})
export class CreateWorkspaceComponent {
  name: string;
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: CreateWorkspaceDialogData,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<CustomDialogComponent>
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: CreateWorkspaceDialogData
  ): MatDialogRef<CreateWorkspaceComponent, CreateWorkspaceDialogResult> {
    const config = createDialogConfig<CreateWorkspaceDialogData>(data, {
      disableClose: data?.disableBackdropClose,
      autoFocus: false,
      panelClasses: ["alert-panel"],
    });
    return matDialog.open<
      CreateWorkspaceComponent,
      CreateWorkspaceDialogData,
      CreateWorkspaceDialogResult
    >(CreateWorkspaceComponent, config);
  }

  createWorkspace() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    this.dialogRef.disableClose = true;
    this.loading = true;

    this.sharedService
      .createWorkspace(this.name)
      .pipe(
        switchMap((data) => {
          if (!this.dialogData?.additionalSubmitAction) return of(data);
          return this.dialogData
            .additionalSubmitAction(data)
            .pipe(map(() => data));
        })
      )
      .subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  joinWorkspace() {
    if (this.loading) return;
    this.dialogRef.close({ type: "join_workspace" });
  }
}
