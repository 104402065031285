<div *ngFor="let account of accounts" >
  <div
    mat-ripple
    class="celo-hoverable tile celo-cursor-pointer"
    (click)="
      editMode ? delete.emit(account.username) : selected.emit(account.username)
    "
  >
    <div class="avatar">
      <app-avatar
        [name]="account.firstName + ' ' + account.lastName"
        [width]="40"
        [height]="40"
        [hasImage]="false"
      >
      </app-avatar>
    </div>
    <div class="text">
      <div>
        <span>{{ account.firstName }} </span>
        <span>{{ account.lastName }}</span>
      </div>
      <div class="email">{{ account.username }}</div>
    </div>
    <div class="top_right">
      <div *ngIf="!editMode" class="hint">Signed out</div>
      <div *ngIf="editMode">
        <i class="material-icons">close</i>
      </div>
    </div>
  </div>
  <hr />
</div>
