import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { UserAccountService } from "../../../core";
import { AvatarService } from "./avatar.service";
import { isFutureDate } from "@utils";

const imageUrlPlaceholder = "/assets/profilepic.png";

export enum AvatarTypes {
  "Profile",
  "Workspace",
}

/** @deprecated Use `BasicCeloAvatarComponent` */
@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges, OnDestroy {
  isLoading = false;
  imageUrl: any = imageUrlPlaceholder;
  @Input() height = "24";
  @Input() width = "24";
  @Input() image_size = 300;
  @Input() user;
  @Input() userId;
  @Input() type = AvatarTypes.Profile;
  @Input() refreshCount = 0;
  @Input() nocache;
  @Input() hasImage = false;
  @Input() picUrl = "";
  @Input() name;
  @Input() name_identity_verified_as;
  @Input() title;
  @Input() doNotDisturbToUtc: string;
  @Input() dndMessage: boolean;
  @Input() badgeBorder = "2";
  @Input() badgeMargin = "10";
  @Input() dndBadgeSize = "30";
  @Input() badgeSize = 17;
  @Input() isOnDND: boolean;
  sub: number;
  @Input() showBadge = false;
  @Input() marginRight = 0;
  @Input() showTooltip = false;
  @Input() identityVerificationStatus;
  @Input() workspaces = [];
  @Input() clickable = false;
  @Input() isWorkspaceVerified = false;
  @Input() isProfessionVerified = false;
  @Input() isExternal: boolean = false;
  subscriber: any;

  constructor(
    private avatarService: AvatarService,
    private userService: UserAccountService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges() {
    this.imageUrl = imageUrlPlaceholder;
    if (this.hasImage) {
      let avatarUrl = "";
      if (this.picUrl) avatarUrl = "/" + this.picUrl;
      this.loadAvatar(avatarUrl);
    }
    const instance = this;
    instance.setDND();
    if (this.doNotDisturbToUtc) {
      this.sub = window.setInterval(function () {
        instance.setDND();
      }, 5000);
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      clearInterval(this.sub);
      this.sub = undefined;
    }
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  setDND() {
    this.isOnDND = false;
    if (this.doNotDisturbToUtc) {
      this.isOnDND = isFutureDate(this.doNotDisturbToUtc);
    }
  }

  private loadAvatar(avatarUrl) {
    this.isLoading = true;
    this.imageUrl = imageUrlPlaceholder;
    this.subscriber = this.avatarService
      .loadPicture(this.userId, 300, this.nocache, avatarUrl)
      .subscribe((url) => {
        this.isLoading = false;
        this.imageUrl = url;
        this.changeDetectorRef.detectChanges();
      });
  }
}
