import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import {
  AccountService,
  AnalyticsService,
  AuthService,
  CompaniesService,
  ConService,
  ConversationsService,
  SharedService,
  UpgradeBannerService,
  UserAccountService,
  UserLimits,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { PaymentsService } from "@modules/core/services/payments.service";
import { VoipService } from "@modules/core/services/voip.service";
import { RolesCtaDialogComponent } from "@modules/shared/roles-cta-dialog/roles-cta-dialog.component";
import {
  exponentialRetry,
  flatMap,
  generateAddBillingInfoReturnUrl,
  getPlanDisplayName,
  isFutureDate,
  SubscriptionContainer,
  UserLimitsUtils,
} from "@utils";
import { NotificationsService } from "app/modules/notifications/notifications.service";
import { ProfileService } from "app/modules/profile/profile.service";
import { environment } from "environments/environment";
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  ReplaySubject,
  Subject,
} from "rxjs";
import { filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import {
  CompanyModel,
  FeatureState,
  NofificationSeenStatusModel,
  SubscriptionTier,
  Team,
} from "types";
import { PinscreenService } from "../pinscreen/pinscreen.service";
import { TeamsService } from "./../modules/core/services/teams.service";
import { UserService } from "./../modules/core/services/user.service";

interface Tab {
  link: string;
  id: string;
  button_id: string;
  name: string;
  notifications?: number;
  isActive: boolean;
}

@Component({
  selector: "[app-nav-top]",
  templateUrl: "./nav-top.component.html",
  styleUrls: ["./nav-top.component.scss"],
})
export class NavTopComponent implements OnInit, OnDestroy {
  @Input() public upgradeUrl: string;

  sub1 = null;
  showLogouts = false;
  userAccount: any;
  pending = 0;

  private ROLES_TAB_ID: string = "nav-roles";
  private EXTERNAL_TAB_ID: string = "external";

  private payload = [
    {
      display: "For 1 hour",
      value: 60,
    },
    {
      display: "For 8 hours",
      value: 480,
    },
    {
      display: "For 48 hours",
      value: 2880,
    },
    {
      display: "For 7 days",
      value: 10080,
    },
    {
      display: "Until I turn it back on",
      value: -1,
    },
  ];

  public tabs: Tab[] = [
    {
      link: "conversations",
      id: "nav-conversations",
      button_id: "nav_chat",
      name: "Messages",
      notifications: 0,
      isActive: true,
    },
    {
      link: "roles",
      id: this.ROLES_TAB_ID,
      button_id: "nav_roles",
      name: "Roles",
      notifications: 0,
      isActive: false,
    },
    {
      link: "external",
      id: this.EXTERNAL_TAB_ID,
      button_id: "nav_external",
      name: "External",
      notifications: 0,
      isActive: false,
    },
    {
      link: "network",
      id: "nav-directory",
      button_id: "nav_network",
      name: "Network",
      isActive: true,
    },
    {
      link: "secure",
      id: "nav-secure-library",
      button_id: "nav_library",
      name: "Secure Library",
      isActive: true,
    },
  ];

  public role_tabs = [];

  private broadcast_roles = [
    {
      link: "broadcast",
      id: "broadcast",
      button_id: "nav_broadcast",
      name: "Celo Broadcast",
    },
  ];

  private admin_roles = [
    {
      link: "admin",
      id: "nav-admin-panel",
      button_id: "nav_admin",
      name: "Admin Panel",
    },
  ];

  // isLoggedIn$: Observable<boolean>;
  // needsPin$: Observable<boolean>;
  isAdmin: boolean;
  @ViewChild(MatMenuTrigger, { static: true }) menu: MatMenuTrigger;
  isBroadcaster: boolean;

  doNotDisturb = false;

  account: any;
  avatarRefreshCount = 0;
  subscription: any;
  ConnectionChangeSub: any;

  public teams$: Observable<Team[]> | null = null;
  public isAuthenticated$: Observable<boolean> | null = null;

  public workspaceAdminUrl: string = environment.workspaceAdminUrl;
  public isAdminPortalLinkEnabled = false;

  public SubscriptionTier = SubscriptionTier;
  private planSubject = new ReplaySubject<SubscriptionTier>(1);
  public plan$: Observable<SubscriptionTier> = this.planSubject.asObservable();
  public planDisplayName$: Observable<string> = this.plan$.pipe(
    map(getPlanDisplayName)
  );

  private paidCompanySubject = new BehaviorSubject<CompanyModel | null>(null);
  public paidCompany$ = this.paidCompanySubject.asObservable();
  public isTrial$ = this.paidCompanySubject.pipe(
    map((c) => c?.trialEndDate && isFutureDate(c.trialEndDate))
  );
  public hasBillingMethod$ = this.paidCompanySubject.pipe(
    map((c) => c?.hasBillingMethod ?? false)
  );
  public trialEndDate$ = this.paidCompanySubject.pipe(
    map((c) => c?.trialEndDate)
  );

  public isBillingOwner = false;

  public isAllowedToDisplayUpgrade$ =
    this.upgradeBannerService.isAllowedToDisplay$;

  private profileMenuClickedSubject = new Subject<void>();

  private subscriptions = new SubscriptionContainer();

  public userLimits$: Observable<UserLimits | null>;

  public isCustomerPortalSessionLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private conService: ConService,
    private sharedService: SharedService,
    public profileService: ProfileService,
    public notificationsService: NotificationsService,
    public analyticsService: AnalyticsService,
    private userAccountService: UserAccountService,
    private router: Router,
    private pinService: PinscreenService,
    private teamsService: TeamsService,
    private userService: UserService,
    private conversationsService: ConversationsService,
    private accountService: AccountService,
    private voipService: VoipService,
    private companiesService: CompaniesService,
    private upgradeBannerService: UpgradeBannerService,
    private paymentsService: PaymentsService,
    private matDialog: MatDialog
  ) {}

  shareProfile() {
    this.profileService.shareProfile();
  }

  tabClicked(
    tab: Tab,
    tabData: {
      isAuthenticated?: boolean | null;
      teams?: Team[] | null;
      userLimits?: UserLimits | null;
    }
  ) {
    this.analyticsService.buttonClickEvent(tab.button_id);

    if (tab.id !== this.ROLES_TAB_ID || tabData.teams?.length) return;

    switch (tabData.userLimits?.teams) {
      case FeatureState.Invisible:
        return;
      case FeatureState.Enabled:
        RolesCtaDialogComponent.openDialog(this.matDialog, {
          hasAccess: true,
        });
        break;
      case FeatureState.Unauthorized:
      case FeatureState.Upgradeable:
      case FeatureState.Disabled:
        RolesCtaDialogComponent.openDialog(this.matDialog, {
          hasAccess: false,
        });
        return;
      default:
        throw new Error(`Unhandled state`, tabData.userLimits?.teams);
    }
  }

  menuCliked() {
    this.profileMenuClickedSubject.next();
    this.userService.updateIsWorkspaceAdminStatus();
    this.analyticsService.buttonClickEvent("nav_settings");
  }

  ngOnInit() {
    this.userAccountService.profilePicChangeSubject.subscribe((data) => {
      this.avatarRefreshCount++;
    });
    const i = this;
    this.userAccountService.getUserAccount(false, function (account) {
      if (!account) {
        return;
      }
      i.userAccount = account;
      i.listenToUserDND(account.userId);
      i.setDnd();
      i.getConnectionRequests();
      i.getActivities();
      // i.getUnseenConnections();
      i.getNotificationStatuses();
    });
    this.userAccountService.accountSubject.subscribe((acc) => {
      this.userAccount = acc;
      this.listenToUserDND(this.userAccount.userId);
      this.setDnd();
      // this.getConnectionRequests();
      // this.getUnseenConnections();
    });
    const instance = this;
    window.setInterval(function () {
      instance.setDnd();
    }, 5000);

    this.sub1 = this.sharedService.notificationCount$.subscribe((count) => {
      this.tabs[0]["notifications"] = count;
    });
    combineLatest([
      this.authService.isDepartmentAdmin$,
      this.authService.isBroadcaster$,
    ]).subscribe(([isDepartmentAdmin, isBroadcaster]) => {
      this.role_tabs = [];

      if (isDepartmentAdmin) {
        this.setAdminTab();
      }
      if (isBroadcaster) {
        this.setBroadcasterTab();
      }
    });

    this.ConnectionChangeSub = this.conService.ConnectionChange.subscribe(
      (connection) => {
        if (
          connection &&
          connection.connection &&
          connection.connection.state == "Pending"
        ) {
          this.profileService.pendingConnections = [];
          this.getConnectionRequests();
        }
      }
    );

    this.teams$ = this.teamsService.userTeams$;

    this.isAuthenticated$ = this.userService.userId$.pipe(
      map((id) => id !== null)
    );

    this.userLimits$ = this.accountService.userLimits$;

    const rolesTab = this.tabs.find((tab) => tab.id === this.ROLES_TAB_ID);
    combineLatest([
      this.teamsService.userTeams$,
      this.accountService.userLimits$,
    ]).subscribe(([teams, userLimits]) => {
      if (!rolesTab || !teams || !userLimits) return;

      rolesTab.isActive = userLimits.teams !== FeatureState.Invisible;

      rolesTab.notifications = teams.reduce((value, team) => {
        if (!team.unreadConversationIds?.length) return value;
        return value + 1;
      }, 0);
    });

    const externalTab = this.tabs.find(
      (tab) => tab.id === this.EXTERNAL_TAB_ID
    );

    combineLatest([
      this.accountService.userLimits$,
      this.conversationsService.conversations$,
    ])
      .pipe(
        map(([userLimits, conversations]) => {
          const externalConversations = conversations
            ? this.conversationsService.filterExternalConversations(
                conversations
              )
            : [];
          return { userLimits, externalConversations };
        })
      )
      .subscribe({
        next: ({ userLimits, externalConversations }) => {
          if (!userLimits) return;

          externalTab.isActive = UserLimitsUtils.isExternalChatTabVisible(
            userLimits,
            externalConversations?.length ?? 0
          );

          const unreadConversationsCount =
            externalConversations?.reduce((value, conversation) => {
              if (!conversation.unreadMessageIds?.length) return value;
              return value + 1;
            }, 0) ?? 0;

          externalTab.notifications = unreadConversationsCount;
        },
      });

    const companiesSubscription = merge(
      this.paymentsService.trialStarted$,
      this.paymentsService.trialEnded$,
      this.profileMenuClickedSubject,
      this.isAllowedToDisplayUpgrade$
    )
      .pipe(
        withLatestFrom(this.isAllowedToDisplayUpgrade$),
        filter(([_, isAllowedToDisplayUpgrade]) => isAllowedToDisplayUpgrade),
        switchMap(() => {
          return this.companiesService
            .getCompaniesV1({
              fetchAll: true,
              pageSize: 100,
            })
            .pipe(flatMap((page) => page.data));
        }),
        switchMap((companies) => {
          return this.userService
            .getUserWorkspaces({ fetchAll: true, pageSize: 100 })
            .pipe(
              flatMap((page) => page.data),
              map((workspaces) => ({ companies, workspaces }))
            );
        })
      )
      .subscribe({
        next: ({ companies, workspaces }) => {
          const paidCompany: CompanyModel | null = companies.find(
            (c) => c.plan !== SubscriptionTier.Free && c.isActive
          );
          this.planSubject.next(paidCompany?.plan ?? SubscriptionTier.Free);
          this.paidCompanySubject.next(paidCompany ?? null);

          this.isBillingOwner = workspaces.some(
            (w) => w.claims?.workspaceBillingManage === "true"
          );

          this.isAdminPortalLinkEnabled = workspaces.some(
            (w) =>
              !w.leftOnUtc &&
              (w.claims?.workspaceBillingManage === "true" ||
                w.claims?.workspaceUserManage === "true")
          );
        },
      });

    this.subscriptions.add(companiesSubscription);
  }

  showNotifications() {
    this.getConnectionRequests();
    this.markNotificationsRead();
    this.getActivities();
    this.analyticsService.buttonClickEvent("nav_feed");
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.conService.userDND$.subscribe((userDND) => {
      if (userDND.userId == userId) {
        this.userAccount["doNotDisturbToUtc"] = userDND.doNotDisturbToUtc;
        // this.setDND()
      }
    });
  }

  setDnd() {
    if (this.userAccount) {
      this.userAccount["doNotDisturb"] = false;
      this.userAccount["doNotDisturbIndefinite"] = true;
      this.userAccount["doNotDisturb"] = this.userAccountService.isOnDND(
        this.userAccount
      );
      this.userAccount["doNotDisturbIndefinite"] =
        this.userAccountService.isOnDNDIndefinite();
    }
  }

  updateUnread(unread: number) {
    this.tabs.forEach((tab) => {
      if (tab.link == "conversations") {
        tab.notifications = unread;
      }
    });
  }

  setAdminTab() {
    this.role_tabs = this.mergeTabsUniquelyByField(
      this.role_tabs,
      this.admin_roles,
      "id"
    );
  }

  setBroadcasterTab() {
    this.role_tabs = this.mergeTabsUniquelyByField(
      this.role_tabs,
      this.broadcast_roles,
      "id"
    );
  }

  mergeTabsUniquelyByField(array: any[], child, fieldName) {
    let hit = false;
    array.forEach((element) => {
      if (element[fieldName] == child[fieldName]) {
        hit = true;
        return;
      }
    });
    if (!hit) {
      array = array.concat(child);
    }
    return array;
  }

  logout() {
    this.analyticsService.buttonClickEvent("logout_and_forget_me");
    this.voipService.openLeaveCallDialogIfRequired().subscribe({
      next: (isCallInProgress) => {
        if (isCallInProgress) return;
        this.sharedService.isLoading = true;
        this.authService.logout("Top navigation");
      },
    });
  }

  addColleagues() {
    this.sharedService.invite("user");
  }

  isOnDoNotDisturb() {
    if (this.userAccount && this.userAccount["doNotDisturb"]) {
      return true;
    }
    return false;
  }

  doNotDisturbClick() {
    const instance = this;
    if (this.isOnDoNotDisturb()) {
      this.doNotDisturbChange(0, function () {
        instance.userAccount["doNotDisturb"] = null;
      });
    } else {
      // show pop up
      this.doNotDisturbPopup();
    }
  }

  doNotDisturbChange(interval, callback) {
    const instance = this;
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.userAccountService.doNotDisturbChange(interval, function (change) {
      if (change) {
        callback();
        instance.userAccount["doNotDisturbInterval"] =
          change["doNotDisturbInterval"];
        instance.userAccount["doNotDisturbIndefinite"] =
          instance.userAccountService.isOnDNDIndefinite(
            change["doNotDisturbToUtc"]
          );
      }
    });
  }

  // doNotDisturbChange(interval,callback){
  //   let path = environment.celoApiEndpoint + '/api/Account/DoNotDisturb';
  //   let params = {
  //     doNotDisturbInterval : interval
  //   }
  //   this.sharedService.postObjectById(path, {}, params).subscribe(
  //     resp=>{
  //       callback();
  //       // this.doNotDisturb = false;
  //     }
  //   )
  // }
  doNotDisturbPopup() {
    const data = {};
    data["payload"] = this.payload;
    data["default"] = 2880;
    const instance = this;
    this.sharedService.openDNDDialog(data).subscribe((result) => {
      if (result) {
        this.doNotDisturbChange(result, function () {
          instance.userAccount["doNotDisturb"] = true;
        });
        // this.muteConversation(result);
      }
    });
  }

  lock() {
    this.analyticsService.buttonClickEvent("pin_lock");
    this.voipService.openLeaveCallDialogIfRequired().subscribe({
      next: (isCallInProgress) => {
        if (isCallInProgress) return;
        this.pinService.lock();
      },
    });
  }

  openHelp() {
    const url = "https://www.celohealth.com/legal/";
    const win = window.open(url, "_blank");
    win.focus();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.sub1.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.ConnectionChangeSub) {
      this.ConnectionChangeSub.unsubscribe();
    }
  }

  getConnectionRequests() {
    const instance = this;
    this.profileService.getPendingConnectionRequest(10, 0, function (resp) {
      if (!resp || !resp.data) {
        return;
      }
      instance.profileService.pendingConnections = resp.data;
    });
  }

  getActivities() {
    this.notificationsService.getActivities(false).subscribe();
  }

  getNotificationStatuses() {
    const instance = this;
    this.profileService.getNotificationStatuses(function (
      resp: NofificationSeenStatusModel
    ) {
      if (!resp) {
        return;
      }
      instance.notificationsService.unseenConnections =
        !resp.connectionRequestSeen;
      instance.notificationsService.unseenActivityFeed = !resp.activityFeedSeen;
    });
  }

  markNotificationsRead() {
    const instance = this;
    this.profileService.updateNotificationStatuses(true, true, function (resp) {
      if (!resp) {
        return;
      }
      instance.notificationsService.unseenActivityFeed = false;
      instance.notificationsService.unseenConnections = false;
    });
  }

  public handleTeamClicked(team: Team) {
    if (!team.id) return;
    this.router.navigate(["/"]).then(() => {
      this.router.navigate(["roles", team.id]);
    });
  }

  public addBillingInfo() {
    const company = this.paidCompanySubject.value;
    if (!company) return;
    this.isCustomerPortalSessionLoading = true;
    this.paymentsService
      .createStripeCustomerPortalSession({
        idempotencyKey: crypto.randomUUID(),
        companyId: company.id,
        returnUrl: generateAddBillingInfoReturnUrl(company.id),
      })
      .pipe(exponentialRetry())
      .subscribe({
        next: ({ url }) => {
          window.location.href = url;
        },
        error: () => {
          AlertComponent.openErrorDialog(this.matDialog);
          this.isCustomerPortalSessionLoading = false;
        },
      });
  }
}
