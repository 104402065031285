import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { isFutureDate } from "@utils";
import { EMPTY, expand, map, Observable, of, timer } from "rxjs";

const pollingPeriodMilliseconds = 60000;
const millisecondsInADay = 8.64e7;

@Component({
  selector: "app-trial-countdown",
  templateUrl: "./trial-countdown.component.html",
  styleUrls: ["./trial-countdown.component.scss"],
})
export class TrialCountdownComponent implements OnChanges {
  @Input() public trialEndDate: string;

  public days$: Observable<number>;

  public ngOnChanges(changes: SimpleChanges): void {
    const trialEndDate = changes["trialEndDate"];
    if (!trialEndDate) {
      this.days$ = of(0);
      return;
    }

    const date = new Date(trialEndDate.currentValue);

    this.days$ = timer(0).pipe(
      expand(() => {
        if (!isFutureDate(date)) return EMPTY;

        const difference = date.getTime() - Date.now();
        const pollingPeriod = Math.min(difference, pollingPeriodMilliseconds);

        return timer(pollingPeriod);
      }),
      map(() => {
        if (!isFutureDate(date)) return 0;

        const difference = date.getTime() - Date.now();
        return Math.max(Math.ceil(difference / millisecondsInADay), 1);
      })
    );
  }
}
