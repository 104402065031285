<mat-list dense style="background-color: white">
  <mat-list-item>
    <span mat-line>
      <span class="fifty">Patient ID</span>
      <span>{{ patient.uid }}</span>
    </span>
  </mat-list-item>
  <mat-list-item>
    <span mat-line>
      <span class="fifty">Last Name</span>
      <span>{{ patient.lastName }}</span>
    </span>
  </mat-list-item>
  <mat-list-item>
    <span mat-line>
      <span class="fifty">First Name</span>
      <span>{{ patient.firstName }}</span>
    </span>
  </mat-list-item>
  <mat-list-item>
    <span mat-line>
      <span class="fifty">Date of Birth</span>
      <span>{{ patient.dateOfBirth }}</span>
    </span>
  </mat-list-item>
  <mat-list-item>
    <span mat-line>
      <span class="fifty">Gender</span>
      <span>{{ patient.gender }}</span>
    </span>
  </mat-list-item>
  <mat-divider></mat-divider>
</mat-list>
