import { Component, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConversationsService } from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { createDialogConfig } from "@utils";
import { Subscription } from "rxjs";
import { PatientDetails } from "./../../shared/patient-details-form/patient-details-form.component";

export interface NewExternalChatDialogData {}

export interface NewExternalChatDialogResult {
  conversationId?: string;
}

@Component({
  selector: "app-new-external-chat-dialog",
  templateUrl: "./new-external-chat-dialog.component.html",
  styleUrls: ["./new-external-chat-dialog.component.scss"],
})
export class NewExternalChatDialogComponent implements OnDestroy {
  public isSubmitting: boolean = false;
  public isPatientDetailsValid: boolean = false;

  private createConversationSubscription: Subscription | null = null;

  public constructor(
    private matDialogRef: MatDialogRef<
      NewExternalChatDialogData,
      NewExternalChatDialogResult
    >,
    private conversationsService: ConversationsService,
    private matDialog: MatDialog
  ) {}

  public static openDialog(
    matDialog: MatDialog
  ): MatDialogRef<NewExternalChatDialogComponent, NewExternalChatDialogResult> {
    const config = createDialogConfig<NewExternalChatDialogData>(
      {},
      {
        panelClass: ["mat-panel-reset"],
      }
    );

    return matDialog.open<
      NewExternalChatDialogComponent,
      NewExternalChatDialogData,
      NewExternalChatDialogResult
    >(NewExternalChatDialogComponent, config);
  }

  public ngOnDestroy(): void {
    this.createConversationSubscription?.unsubscribe();
  }

  public onSubmit(patientDetails: PatientDetails) {
    this.isSubmitting = true;

    this.conversationsService.createExternalChat(patientDetails).subscribe({
      next: (conversation) => {
        this.matDialogRef.close({
          conversationId: conversation.id,
        });
      },
      error: () => {
        AlertComponent.openErrorDialog(this.matDialog)
          .afterClosed()
          .subscribe({
            next: () => {
              this.isSubmitting = false;
            },
          });
      },
    });
  }
}
