import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-roles-cta",
  templateUrl: "./roles-cta.component.html",
  styleUrls: ["./roles-cta.component.scss"],
})
export class RolesCtaComponent {
  @Input() public hasAccess = false;

  @Output() public dismiss = new EventEmitter<void>();
}
