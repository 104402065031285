import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SharedService } from "@modules/core";

@Component({
  selector: "app-celo-no-results-found-message",
  templateUrl: "./celo-no-results-found-message.component.html",
  styleUrls: ["./celo-no-results-found-message.component.scss"],
})
export class CeloNoResultsFoundMessageComponent {
  @Input() pic = "/assets/no-results-found-directory-v2.svg";
  @Input() hidePic: boolean;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showInviteButton: boolean;
  @Input() showInviteViaLinkButton: boolean;
  @Input() style = "tertiary";
  @Input() showAddWorkspaceButton: boolean;
  @Input() showGetStartedButton: boolean;
  @Output() buttonClick = new EventEmitter<any>();

  constructor(private sharedService: SharedService) {}

  addColleagues() {
    this.sharedService.invite("user");
  }

  joinWorkspace() {
    this.buttonClick.emit();
  }
}
