<app-basic-cta
  [title]="'Roles'"
  [primaryButtonText]="'Done'"
  (primaryClick)="dismiss.emit()"
  [hasCloseButton]="true"
  (closeClick)="dismiss.emit()"
  [titleFontSize]="'16px'"
  [padding]="'30px 65px 45px 65px'"
>
  <img
    image
    ngSrc="../../../../assets/ctas/in-app-roles.svg"
    width="228"
    height="221"
    priority
    placeholder
  />

  <div class="content" content>
    <ng-container *ngIf="hasAccess; else noAccessContent">
      Role-based messaging makes it easy to send a message to the right person
      responsible for a particular team or role.
      <br />
      <br />
      Easily contact an 'on call', 'on duty' person or an admin role by finding
      them in the Celo directory by role rather than having to know who they are
      by name. To learn more about roles, click
      <a
        class="celo-link"
        href="https://hello.celohealth.com/role-based-messaging"
        target="_blank"
        >here.</a
      >
      <br />
      <br />
      To set up Roles, navigate to your workspace’s directory.
      <a
        class="celo-link"
        href="https://support.celohealth.com/hc/en-us/articles/4415136161817-Creating-and-managing-a-role"
        target="_blank"
        >Learn more.</a
      >
    </ng-container>

    <ng-template #noAccessContent>
      Role-based messaging makes it easy to send a message to the right person
      responsible for a particular team or role.
      <br />
      <br />
      Easily contact an 'on call', 'on duty' person or an admin role by finding
      them in the Celo directory by role rather than having to know who they are
      by name. To learn more about roles, click
      <a
        class="celo-link"
        href="https://hello.celohealth.com/role-based-messaging"
        target="_blank"
        >here.</a
      >
      <br />
      <br />
      To set up Roles, please contact your workspace admin.
      <a
        class="celo-link"
        href="https://support.celohealth.com/hc/en-us/articles/4415128452761-Celo-role-based-messaging"
        target="_blank"
        >Learn more.</a
      >
    </ng-template>
  </div>
</app-basic-cta>
