<div class="feedback">
  <div class="feedback-content">
    <img class="appear_anim_1" [src]="src" alt="" />
    <div class="appear_anim_2">
      <div *ngIf="title" class="title">{{ title }}</div>
      <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
    </div>
    <div class="subsubtitle appear_anim_3">
      <div *ngIf="subsubtitle">
        {{ subsubtitle }}
      </div>
    </div>
    <div class="button appear_anim_4">
      <button
        (click)="primaryAction.emit()"
        mat-button
        class="celo-primary-button fw"
      >
        Done
      </button>
    </div>
  </div>
</div>
