import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-upgrade-banner",
  templateUrl: "./upgrade-banner.component.html",
  styleUrls: ["./upgrade-banner.component.scss"],
})
export class UpgradeBannerComponent {
  @Input() public upgradeUrl: string | null = null;
  @Input() public target: string = "_self";

  @Output() public close = new EventEmitter<void>();
}
