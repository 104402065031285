import { Component } from "@angular/core";

@Component({
  selector: "app-add-email-confirm",
  templateUrl: "./add-email-confirm.component.html",
  styleUrls: ["./add-email-confirm.component.scss"],
})
export class AddEmailConfirmComponent {
  constructor() {}
}
