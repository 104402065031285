<form
  [formGroup]="contactsForm"
  class="inactive"
  [ngClass]="{ active: selectedContacts && selectedContacts.length > 0 }"
>
  <mat-form-field class="celo-less-bottom-margin">
    <div class="list_content" #listScroll>
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let contact of selectedContacts"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="checkAndRemove(contact)"
          class="chip no-hover-effect slide_l_0_4"
          matChipRemove
        >
          <app-avatar
            [name]="contact.firstName + ' ' + contact.lastName"
            [userId]="contact.userId"
            [width]="27"
            [height]="27"
            [hasImage]="!!contact.profilePic"
          ></app-avatar>
          {{ contact.firstName }}
          <i *ngIf="removable" class="material-icons">cancel</i>
        </mat-chip-row>
        <span
          *ngIf="
            !disabled &&
            (!contactInput || !contactInput.value) &&
            selectedContacts &&
            selectedContacts.length
          "
          class="placeholder"
        >
          <span class="appear_1">Search for more</span>
        </span>
        <input
          [placeholder]="
            !selectedContacts.length
              ? placeholder
              : selectedContacts.length +
                ' participant' +
                (selectedContacts.length > 1 ? 's' : '') +
                ' selected'
          "
          class="nameinput"
          #contactInput
          formControlName="contactsGroup"
          [matChipInputFor]="chipList"
          [disabled]="disabled"
          [matAutocomplete]="autoGroup"
        />
      </mat-chip-grid>
    </div>
    <mat-autocomplete #autoGroup="matAutocomplete">
      <mat-optgroup
        *ngFor="let group of contactsGroupOptions | async"
        [label]="group.departmentName"
      >
        <!-- <mat-option *ngFor="let contact of group.departmentContacts"
                  (onSelectionChange)="contactSelect($event, contact)"
                  [value]="contact">
        {{isSelected(contact) ? '✓ ' : ''}}{{contact.firstName}}
      </mat-option> -->
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>
