import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FullUserProfileModel } from "@types";
import {
  createDialogConfig,
  isCeloErrorResponse,
  isHttpErrorResponse,
} from "@utils";
import { CompanyErrorComponent } from "app/company-error/company-error.component";
import { getErrorDescriptor } from "utils/error-utils";
import { environment } from "../../../environments/environment";
import { AlertService } from "../../modules/core/old/alert.service";
import { SharedService } from "../../modules/core/old/shared.service";
import { UserAccountService } from "../../modules/core/old/user-account.service";

export type AddDialogData = {
  step?: "step1" | "step2" | "step3" | "";
  disableBackdropClose?: boolean;
};

export type AddDialogResult =
  | (FullUserProfileModel & {
      type: "FullUserProfileModel";
      recentlyAddedWorkspaceId: string;
    })
  | { type: "create_workspace" };

@Component({
  selector: "app-add-workspace",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss", "../../../celo-input.scss"],
})
export class AddComponent implements OnInit {
  protected basePath = environment.celoApiEndpoint;

  step: AddDialogData["step"] = "";
  workemail = "";
  email = "";
  code = "";
  verificationId = "";

  pics = {
    step1: "../../../assets/step1.png",
    step2: "../../../assets/step2.png",
    step3: "../../../assets/step3.png",
  };

  workspace_details: any;
  workspace_details_valid = false;
  loading = false;
  resending: boolean;
  userAccount: any = {};
  countryCode = "";

  constructor(
    private router: Router,
    private alertService: AlertService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<AddComponent, AddDialogResult>,
    private userAccountService: UserAccountService,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData,
    private matDialog: MatDialog
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data?: AddDialogData
  ): MatDialogRef<AddComponent, AddDialogResult> {
    const config = createDialogConfig<AddDialogData>(data, {
      disableClose: data?.disableBackdropClose,
      autoFocus: false,
      panelClass: "alert-panel",
    });

    return matDialog.open<AddComponent, AddDialogData, AddDialogResult>(
      AddComponent,
      config
    );
  }

  ngOnInit() {
    this.step = "step1";
    if (this.data && this.data.step) {
      this.step = this.data.step;
    }
    const instance = this;
    this.userAccountService.getUserAccount(true, function (userAccount) {
      instance.userAccount = userAccount;
      instance.countryCode = userAccount["countryCode"];
    });
  }

  navigate(step, email) {
    const params = { step };
    if (email) {
      params["email"] = email;
    }
    this.router.navigate(["/add-company", params]);
  }

  resendCode(email) {
    this.submitStep1(email);
    this.resending = true;
  }

  submitStep1(email) {
    // this.registerWorkspace('sdad')
    // return;
    // this.successStep2();
    // return;

    // make api call to send code
    this.loading = true;
    this.requestVerifyEmail(email).subscribe(
      (data) => {
        this.verificationId = data["verificationId"];
        this.successStep1();
        this.loading = false;
        this.alertService.showSnackBar("We've sent a code to " + email, 4);
      },
      (err) => {
        const message = this.sharedService.getErrorMessageFromError(err);
        // this.sharedService.displayErrorDialog(err);
        this.loading = false;
        return this.alertService.confirm("", message, "Close", "", true);
      },
      () => {
        this.resending = false;
      }
    );
  }

  requestVerifyEmail(email) {
    const path = this.basePath + `/api/account/emailverifications`;
    return this.sharedService.postObjectById(path, {}, { email });
  }

  successStep1() {
    this.step = "step2";
  }

  submitStep2(email) {
    // make api to confirm email code
    this.loading = true;
    this.verifyEmail(email).subscribe(
      (data) => {
        this.loading = false;
        this.successStep2();
      },
      (err) => {
        // this.loading = false;
        // this.sharedService.displayErrorDialog(err);
        const message = this.sharedService.getErrorMessageFromError(err);
        this.loading = false;
        return this.alertService.confirm("", message, "Close", "", true);
      }
    );
  }

  verifyEmail(email) {
    const path = this.basePath + `/api/account/emailverifications/check`;
    const body = {
      email,
      verificationId: this.verificationId,
      code: this.code,
    };
    return this.sharedService.postObjectById(path, "", body);
  }

  successStep2() {
    this.step = "step3";
  }

  save() {
    // api call to add Workspace
    this.dialogRef.disableClose = true;
    this.loading = true;
    this.sharedService
      .joinWorkspace(
        this.email,
        this.verificationId,
        this.workspace_details.companyId,
        this.workspace_details.departmentId,
        this.workspace_details.position
      )
      .subscribe({
        next: (data) => {
          this.dialogRef.disableClose = false;
          this.loading = false;
          this.onSaveSuccess(data, this.workspace_details.companyId);
        },
        error: (err) => {
          this.dialogRef.disableClose = false;
          this.loading = false;

          if (isHttpErrorResponse(err) && isCeloErrorResponse(err.error)) {
            const descriptor = getErrorDescriptor(err.error);
            CompanyErrorComponent.openDialog(this.matDialog, {
              title: this.workspace_details.company.companyName,
              content: descriptor.message,
            });
            return;
          }

          let reason;
          reason = this.sharedService.getErrorMessage(err);
          this.alertService
            .confirm("", reason, "Start again", "Cancel", false)
            .subscribe((result) => {
              if (result) {
              }
            });
        },
      });
  }

  onSaveSuccess(data: FullUserProfileModel, companyId: string) {
    this.dialogRef.close({
      ...data,
      recentlyAddedWorkspaceId: companyId,
      type: "FullUserProfileModel",
    });
  }

  createWorkspace() {
    this.dialogRef.close({ type: "create_workspace" });
  }
}
