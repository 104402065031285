import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig, generateUpgradeUrl } from "@utils";

export type UpgradePromptDialogData = unknown;

export type UpgradePromptDialogResult = unknown;

@Component({
  selector: "app-upgrade-prompt-dialog",
  templateUrl: "./upgrade-prompt-dialog.component.html",
  styleUrls: ["./upgrade-prompt-dialog.component.scss"],
})
export class UpgradePromptDialogComponent {
  public upgradeUrl = generateUpgradeUrl({
    redirectPath: "/network",
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: UpgradePromptDialogData,
    private matDialogRef: MatDialogRef<
      UpgradePromptDialogData,
      UpgradePromptDialogResult
    >
  ) {}

  public static openDialog(
    matDialog: MatDialog
  ): MatDialogRef<UpgradePromptDialogComponent, UpgradePromptDialogResult> {
    const config = createDialogConfig<UpgradePromptDialogData>(null, {
      panelClasses: ["upgrade-prompt-dialog"],
      maxWidth: "400px",
    });
    config.autoFocus = "dialog";
    return matDialog.open<
      UpgradePromptDialogComponent,
      UpgradePromptDialogData,
      UpgradePromptDialogResult
    >(UpgradePromptDialogComponent, config);
  }

  public onDismiss() {
    this.matDialogRef.close();
  }
}
