<app-basic-cta
  [title]="'Your trial ended.'"
  [primaryButtonText]="'Select Your Plan'"
  [secondaryButtonText]="'Downgrade to Celo Free'"
  [primaryButtonHref]="upgradeUrl"
  (secondaryClick)="downgradeClick.emit()"
  [padding]="'30px 85px 45px 85px'"
>
  <img
    image
    ngSrc="../../../../assets/ctas/trial-ended@2x.png"
    width="200"
    height="200"
    priority
    placeholder
  />

  <ng-container content>
    Thank you for giving Celo premium features a try.
    <br />
    <br />
    If you would like to continue using premium features, please upgrade to one
    of our paid plans.
  </ng-container>
</app-basic-cta>
