import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-basic-cta",
  templateUrl: "./basic-cta.component.html",
  styleUrls: ["./basic-cta.component.scss"],
})
export class BasicCtaComponent {
  @Input() public title: string | null = null;
  @Input() public primaryButtonText: string;
  @Input() public secondaryButtonText: string | null;

  @Input() public isPrimaryButtonLoading: boolean = false;

  @Input() public primaryButtonHref: string | null = null;

  @Input() public hasCloseButton: boolean = false;

  @Output() public primaryClick = new EventEmitter();
  @Output() public secondaryClick = new EventEmitter();
  @Output() public closeClick = new EventEmitter();

  @Input() public titleFontSize: string | null = null;

  @Input()
  @HostBinding("style.padding")
  public padding: string = "";
}
