import { UserLimits } from "@modules/core";
import { FeatureState } from "@types";

const videoCallVisibleStates = new Set<FeatureState>([
  FeatureState.Enabled,
  FeatureState.Upgradeable,
]);

const newExternalChatVisibleStates = new Set<FeatureState>([
  FeatureState.Enabled,
  FeatureState.Upgradeable,
]);

const exportCaseToPdfVisibleStates = new Set<FeatureState>([
  FeatureState.Enabled,
  FeatureState.Upgradeable,
]);

export class UserLimitsUtils {
  public static isVideoCallVisible(userLimits: UserLimits) {
    return videoCallVisibleStates.has(userLimits.video_calls);
  }

  public static isNewExternalChatVisible(userLimits: UserLimits) {
    return newExternalChatVisibleStates.has(userLimits.external_conversations);
  }

  public static isExternalChatTabVisible(
    userLimits: UserLimits,
    externalConversationCount: number
  ) {
    return (
      userLimits.external_conversations === FeatureState.Enabled ||
      (externalConversationCount &&
        userLimits.external_conversations !== FeatureState.Invisible)
    );
  }

  public static isExportCaseToPdfVisible(userLimits: UserLimits) {
    return exportCaseToPdfVisibleStates.has(userLimits.export_cases);
  }

  public static isAddRoleEnabled(
    userLimits: UserLimits,
    hasWorkspaceTeamManageClaim: boolean
  ) {
    return (
      userLimits.teams === FeatureState.Enabled && hasWorkspaceTeamManageClaim
    );
  }
}
