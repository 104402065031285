import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
  AuthService,
  SnackbarService,
  UpgradeBannerService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { PaymentsService } from "@modules/core/services/payments.service";
import { BillingInterval, SubscriptionTier } from "@types";
import {
  createDialogConfig,
  exponentialRetry,
  generateUpgradeUrl,
} from "@utils";
import { AddComponent } from "app/workspace/add/add.component";
import {
  CreateWorkspaceComponent,
  CreateWorkspaceDialogData,
} from "app/workspace/create-workspace/create-workspace.component";
import { finalize, map, Observable, of, switchMap } from "rxjs";

export interface StartTrialCtaDialogData {
  trialData?: {
    subscriptionTier?: SubscriptionTier;
    billingInterval: BillingInterval;
  } | null;
}

export interface StartTrialCtaDialogResult {}

interface CreateOrJoinWorkspaceResult {
  companyId: string | null;
  isCreated: boolean;
}

@Component({
  selector: "app-start-trial-cta-dialog",
  templateUrl: "./start-trial-cta-dialog.component.html",
  styleUrls: ["./start-trial-cta-dialog.component.scss"],
})
export class StartTrialCtaDialogComponent implements OnInit {
  public isStartTrialLoading = false;

  public upgradeUrl: string | null = null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: StartTrialCtaDialogData,
    private matDialogRef: MatDialogRef<
      StartTrialCtaDialogData,
      StartTrialCtaDialogResult
    >,
    private paymentsService: PaymentsService,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService,
    private router: Router,
    private upgradeBannerService: UpgradeBannerService,
    private authService: AuthService
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: StartTrialCtaDialogData
  ): MatDialogRef<StartTrialCtaDialogComponent, StartTrialCtaDialogResult> {
    const config = createDialogConfig<StartTrialCtaDialogData>(data, {
      disableClose: true,
      panelClasses: ["start-trial-cta-dialog"],
      maxWidth: "400px",
    });
    config.autoFocus = "dialog";
    return matDialog.open<
      StartTrialCtaDialogComponent,
      StartTrialCtaDialogData,
      StartTrialCtaDialogResult
    >(StartTrialCtaDialogComponent, config);
  }

  public ngOnInit(): void {
    if (
      !this.dialogData.trialData?.subscriptionTier ||
      this.dialogData.trialData.subscriptionTier === SubscriptionTier.Free ||
      this.dialogData.trialData.subscriptionTier === SubscriptionTier.Enterprise
    ) {
      this.upgradeUrl = generateUpgradeUrl({
        redirectPath: "/network",
      });
    }
  }

  // This is a mess. Refactor when there's time.
  public joinWorkspace(): Observable<CreateOrJoinWorkspaceResult> {
    return AddComponent.openDialog(this.matDialog, {
      disableBackdropClose: true,
    })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return of(null);
          if (result.type === "create_workspace") {
            return this.createWorkspace();
          }

          this.snackbarService.show(
            "Workspace has been added succcessfully",
            3
          );

          if (!result.workplaces?.length) {
            return of({ companyId: null, isCreated: false });
          } else {
            const companyId = result.workplaces[0].companyId;
            return of({ companyId, isCreated: false });
          }
        })
      );
  }

  public createWorkspace(
    data?: CreateWorkspaceDialogData
  ): Observable<CreateOrJoinWorkspaceResult> {
    return CreateWorkspaceComponent.openDialog(this.matDialog, data)
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return of(null);
          if (result.type === "join_workspace") {
            return this.joinWorkspace();
          }

          this.snackbarService.show(
            "Workspace has been created succcessfully",
            4
          );

          return of({ companyId: result.id, isCreated: true });
        })
      );
  }

  // This is only called if the plan is null or Free
  public handleStartTrial() {
    this.isStartTrialLoading = true;

    this.matDialogRef.close();

    this.createWorkspace({
      disableBackdropClose: true,
      additionalSubmitAction: (data) => {
        return this.authService.refreshToken().pipe(
          switchMap((result) => {
            return this.paymentsService
              .getPrice(
                this.dialogData.trialData.subscriptionTier,
                this.dialogData.trialData.billingInterval
              )
              .pipe(
                exponentialRetry(),
                map((price) => ({
                  priceId: price.id,
                  companyId: data.id,
                }))
              );
          }),
          switchMap((result) => {
            if (!result) return of(result);

            const { priceId, companyId } = result as {
              priceId: string;
              companyId: string;
            };

            const idempotencyKey = crypto.randomUUID();

            return this.paymentsService
              .startTrial(companyId, {
                idempotencyKey,
                trialItems: [
                  {
                    priceId,
                  },
                ],
              })
              .pipe(exponentialRetry());
          }),
          map(() => null)
        );
      },
    })
      .pipe(
        switchMap((result) => {
          return this.authService.refreshToken().pipe(map(() => result));
        })
      )
      .subscribe({
        next: (result: CreateOrJoinWorkspaceResult | { companyId: string }) => {
          if (!result) {
            this.upgradeBannerService.updateIsBannerDismissed();
            this.upgradeBannerService.setIsAllowedToDisplay(true);
            return;
          }

          const { companyId } = result;
          this.router.navigate(["/network/workspace", companyId]);
          this.upgradeBannerService.setIsAllowedToDisplay(true);
        },
        error: () => {
          AlertComponent.openErrorDialog(this.matDialog);
          this.upgradeBannerService.updateIsBannerDismissed();
          this.upgradeBannerService.setIsAllowedToDisplay(true);
        },
      });
  }

  public handleIgnoreTrial() {
    this.matDialogRef.close();

    this.createWorkspace({
      disableBackdropClose: true,
    })
      .pipe(
        finalize(() => {
          this.upgradeBannerService.updateIsBannerDismissed();
          this.upgradeBannerService.setIsAllowedToDisplay(true);
        })
      )
      .subscribe({
        next: (result) => {
          if (!result?.companyId) return;
          this.router.navigate(["/network/workspace", result.companyId]);
        },
      });
  }
}
