import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatOptionSelectionChange } from "@angular/material/core";
import { Observable, Subject } from "rxjs";
import { startWith, switchMap, tap } from "rxjs/operators";
import { AlertService, MiscService } from "@modules/core";

export interface ContactGroup {
  departmentName: string;
  departmentContacts: any[];
}

export interface SelectedContacts {
  map: { [key: string]: any };
  list: any[];
}

@Component({
  selector: "app-celo-topbar-contact-chips",
  templateUrl: "./celo-topbar-contact-chips.component.html",
  styleUrls: ["./celo-topbar-contact-chips.component.scss"],
})
export class CeloTopbarContactChipsComponent implements OnInit, OnChanges {
  visible = true;
  selectable = true;
  removable = true;
  @ViewChild("listScroll", { static: true }) private listScroll: ElementRef;
  // addOnBlur = true;
  // separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedContacts: any[] = [];
  selectedContactsAsMap: { [key: string]: any } = {};

  contactsForm: UntypedFormGroup = this.fb.group({
    contactsGroup: "",
  });

  contactCtrl = this.contactsForm.get("contactsGroup");

  @Input() groupedContacts$: Observable<ContactGroup[]>;
  @Input() addContact$: Observable<any>;
  @Input() canRemoveContact$: Observable<any>;
  @Input() removeContact$: Subject<any>;
  @Input() placeholder = "Select or type the name of the participants";
  @Input() isAdmin: boolean;
  @Input() disabled: boolean;
  @Output() search = new EventEmitter<string>();
  @Output() changeSelection = new EventEmitter<SelectedContacts>(); // user-id: any
  contactsGroupOptions: Observable<ContactGroup[]>;

  @ViewChild("contactInput", { static: true })
  contactInput: ElementRef<HTMLInputElement>;

  @ViewChild("autoGroup", { static: true }) matAutocomplete: MatAutocomplete;

  constructor(
    private fb: UntypedFormBuilder,
    public alertService: AlertService,
    public miscService: MiscService
  ) {
    // this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
  }

  ngOnInit() {
    this.contactsGroupOptions = this.contactsForm
      .get("contactsGroup")
      .valueChanges.pipe(
        startWith(""),
        tap((searchTerm) => {
          if (typeof searchTerm === "string") {
            this.search.emit(searchTerm);
          } else {
            this.search.emit("");
          }
        }),
        switchMap(() => this.groupedContacts$)
        // map(value => this._filterGroup(value))
      );

    this.addContact$.subscribe((contact: any) => {
      this.add(contact);
    });
    this.canRemoveContact$.subscribe((contact: any) => {
      this.remove(contact);
    });

    // this.removeContact$.subscribe((contact: any) => {
    //   this.remove(contact);
    // });
  }

  ngOnChanges() {
    this.onUpdate();
  }

  // add(event: MatChipInputEvent): void {
  //   // Add fruit only when MatAutocomplete is not open
  //   // To make sure this does not conflict with OptionSelected Event
  //   if (!this.matAutocomplete.isOpen) {
  //     const input = event.input;
  //     const value = event.value;
  //
  //     alert('here..' + JSON.stringify([input.value, value]));
  //     // // Add our fruit
  //     // if ((value || '').trim()) {
  //     //   this.fruits.push(value.trim());
  //     // }
  //     //
  //     // // Reset the input value
  //     // if (input) {
  //     //   input.value = '';
  //     // }
  //     //
  //     // this.fruitCtrl.setValue(null);
  //   }
  // }

  checkAndRemove(contact: any) {
    this.removeContact$.next(contact);
    // if(!this.isAdmin){
    //   this.alertService.confirm("","Only the admin of this conversation is allowed to remove participants.","Cancel",'',true);
    //   return;
    // }
    // this.remove(contact);
  }

  remove(contact: any): void {
    this.onUpdate();

    this.selectedContacts = this.selectedContacts.filter(
      (c) => c.userId !== contact.userId
    );
    if (this.selectedContactsAsMap[contact.userId]) {
      delete this.selectedContactsAsMap[contact.userId];
      this.emitSelectionChange();
    }
  }

  onUpdate() {
    this.scrollTo(this.listScroll);
    if (this.contactInput.nativeElement.value) {
      this.contactInput.nativeElement.value = "";
      this.search.emit("");
    }
  }

  scrollTo(el: any) {
    try {
      el.nativeElement.scrollTop = el.nativeElement.scrollHeight;
    } catch (err) {}
  }

  add(contact: any) {
    this.onUpdate();

    this.remove(contact);
    this.selectedContacts.push(contact);
    this.selectedContactsAsMap[contact.userId] = contact;
    this.emitSelectionChange();
  }

  isSelected(contact: any): boolean {
    return !!this.selectedContactsAsMap[contact.userId];
  }

  contactSelect(event: MatOptionSelectionChange, contact: any) {
    if (event.source.selected) {
      this.contactInput.nativeElement.value = "";
      this.contactCtrl.setValue(null);
      this.add(contact);
    }
  }

  emitSelectionChange() {
    this.changeSelection.emit({
      map: this.selectedContactsAsMap,
      list: this.selectedContacts,
    });
  }
}
