import {
  ConversationInvitationModel,
  ConversationType,
  PatientDataModel,
} from "./api-v2";
import { MessageSkeletonModelV3 } from "./api-v3";
import { ConversationParticipant } from "./ConversationParticipant";
import { Message } from "./Message";

export interface Conversation {
  /** Used in type predicates for type narrowing */
  _type: "Conversation";

  id: string;
  teamIds?: string[] | null;
  subject?: string | null;
  name?: string | null;
  createdBy?: string | null;
  type?: ConversationType;

  /** @format date-time */
  createdOnUtc?: string;

  /** @format date-time */
  lastModifiedOnUtc?: string;
  lastMessage?: Message;
  patientData?: PatientDataModel;
  isReal?: boolean;
  invitation?: ConversationInvitationModel;

  participants?: ConversationParticipant[];
  photoId?: string;
  profilePictureUri?: string | null;

  unreadMessages: MessageSkeletonModelV3[];
  unreadMessageLimit: number;

  unreadMessageCount: number;
  pinnedOnUtc?: string | null;
  sortTime?: string;

  isArchived: boolean;

  /** @format date-time */
  localAsReadToUtc?: string | null;
}
