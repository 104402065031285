import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatOptionSelectionChange } from "@angular/material/core";

@Component({
  selector: "app-department-picker",
  templateUrl: "./department-picker.component.html",
  styleUrls: ["./department-picker.component.scss"],
})
export class DepartmentPickerComponent implements OnInit {
  departmentCtrl: UntypedFormControl;
  filteredDepartments: Observable<Array<any>>;
  // showSearch = false
  @Output() selectDepartment = new EventEmitter();
  @Input() selectedDepartment: any;
  @Input() departments: any[];
  selected = false;

  constructor() {
    this.departmentCtrl = new UntypedFormControl();
  }

  ngOnInit() {
    this.filteredDepartments = this.departmentCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterDepartments(name))
    );
  }

  levenshtein = (a, b) => {
    const alen = a.length;
    const blen = b.length;
    if (alen === 0) {
      return blen;
    }
    if (blen === 0) {
      return alen;
    }
    let tmp;
    let i;
    let j;
    let prev;
    let val;
    let row;
    let ma;
    let mb;
    let mc;
    let md;
    let bprev;

    if (alen > blen) {
      tmp = a;
      a = b;
      b = tmp;
    }

    row = new Int8Array(alen + 1);
    // init the row
    for (i = 0; i <= alen; i++) {
      row[i] = i;
    }

    // fill in the rest
    for (i = 1; i <= blen; i++) {
      prev = i;
      bprev = b[i - 1];
      for (j = 1; j <= alen; j++) {
        if (bprev === a[j - 1]) {
          val = row[j - 1];
        } else {
          ma = prev + 1;
          mb = row[j] + 1;
          mc = ma - ((ma - mb) & ((mb - ma) >> 7));
          md = row[j - 1] + 1;
          val = mc - ((mc - md) & ((md - mc) >> 7));
        }
        row[j - 1] = prev;
        prev = val;
      }
      row[alen] = prev;
    }
    return row[alen];
  };

  nearMatch = (s, txt) => {
    s = s.toLowerCase();
    txt = txt.toLowerCase();
    if (s.indexOf(txt) !== -1) {
      // sub string
      return true;
    }
    if (txt.length > 2) {
      const d = this.levenshtein(txt, s.substr(0, txt.length + 1));
      return d < 4;
    }
    return false;
  };

  filterDepartments(val) {
    if (val && val.name) {
      val = val.name;
    }
    return val
      ? this.departments.filter((s) => this.nearMatch(s.name, val))
      : this.departments;
  }

  displayFn = (department: any | string) => {
    if (department === "") {
      return "";
    }
    if (!department) {
      // first time is null or undefined
      return this.selectedDepartment ? this.selectedDepartment.name : "";
    }
    return typeof department !== "string" ? department.name : "";
  };

  departmentSelect(event: MatOptionSelectionChange, department: any) {
    if (event.source.selected) {
      this.selectedDepartment = department;
      this.selectDepartment.emit(department);
    }
  }
}
