import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import {
  AccountService,
  AnalyticsService,
  AuthService,
  CompaniesService,
  ConService,
  ConversationModelV2WithMetadata,
  ConversationsService,
  SharedService,
  UserAccountService,
} from "@modules/core";
import { VoipService } from "@modules/core/services/voip.service";
import { SubscriptionContainer } from "@utils";
import { NotificationsService } from "app/modules/notifications/notifications.service";
import { ProfileService } from "app/modules/profile/profile.service";
import { environment } from "environments/environment";
import { Observable, combineLatest, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  CompanyModel,
  CompanyPlan,
  NofificationSeenStatusModel,
  Team,
} from "types";
import { PinscreenService } from "../pinscreen/pinscreen.service";
import { TeamsService } from "./../modules/core/services/teams.service";
import { UserService } from "./../modules/core/services/user.service";

@Component({
  selector: "[app-nav-top]",
  templateUrl: "./nav-top.component.html",
  styleUrls: ["./nav-top.component.scss"],
})
export class NavTopComponent implements OnInit, OnDestroy {
  @Input() public upgradeUrl: string;

  sub1 = null;
  showLogouts = false;
  userAccount: any;
  pending = 0;

  private ROLES_TAB_ID: string = "nav-roles";
  private EXTERNAL_TAB_ID: string = "external";

  private payload = [
    {
      display: "For 1 hour",
      value: 60,
    },
    {
      display: "For 8 hours",
      value: 480,
    },
    {
      display: "For 48 hours",
      value: 2880,
    },
    {
      display: "For 7 days",
      value: 10080,
    },
    {
      display: "Until I turn it back on",
      value: -1,
    },
  ];

  public tabs = [
    {
      link: "conversations",
      id: "nav-conversations",
      button_id: "nav_chat",
      name: "Messages",
      notifications: 0,
      isActive: true,
    },
    {
      link: "roles",
      id: this.ROLES_TAB_ID,
      button_id: "nav_roles",
      name: "Roles",
      notifications: 0,
      isActive: false,
    },
    {
      link: "external",
      id: this.EXTERNAL_TAB_ID,
      button_id: "nav_external",
      name: "External",
      notifications: 0,
      isActive: false,
    },
    {
      link: "network",
      id: "nav-directory",
      button_id: "nav_network",
      name: "Network",
      isActive: true,
    },
    {
      link: "secure",
      id: "nav-secure-library",
      button_id: "nav_library",
      name: "Secure Library",
      isActive: true,
    },
  ];

  public role_tabs = [];

  private broadcast_roles = [
    {
      link: "broadcast",
      id: "broadcast",
      button_id: "nav_broadcast",
      name: "Celo Broadcast",
    },
  ];

  private admin_roles = [
    {
      link: "admin",
      id: "nav-admin-panel",
      button_id: "nav_admin",
      name: "Admin Panel",
    },
  ];

  // isLoggedIn$: Observable<boolean>;
  // needsPin$: Observable<boolean>;
  isAdmin: boolean;
  @ViewChild(MatMenuTrigger, { static: true }) menu: MatMenuTrigger;
  isBroadcaster: boolean;

  doNotDisturb = false;

  account: any;
  avatarRefreshCount = 0;
  subscription: any;
  ConnectionChangeSub: any;

  public teams$: Observable<Team[]> | null = null;
  public isAuthenticated$: Observable<boolean> | null = null;

  public workspaceAdminUrl: string = environment.workspaceAdminUrl;
  public isWorkspaceAdmin$ = this.userService.isWorkspaceAdmin$;

  public CompanyPlan = CompanyPlan;
  public plan$: Observable<CompanyPlan> = of(CompanyPlan.Free);
  public paidCompany$: Observable<CompanyModel | null> = of(null);

  private subscriptions = new SubscriptionContainer();

  constructor(
    private authService: AuthService,
    private conService: ConService,
    private sharedService: SharedService,
    public profileService: ProfileService,
    public notificationsService: NotificationsService,
    public analyticsService: AnalyticsService,
    private userAccountService: UserAccountService,
    private router: Router,
    private pinService: PinscreenService,
    private teamsService: TeamsService,
    private userService: UserService,
    private conversationsService: ConversationsService,
    private accountService: AccountService,
    private voipService: VoipService,
    private companiesService: CompaniesService
  ) {}

  shareProfile() {
    this.profileService.shareProfile();
  }

  tabClicked(tab) {
    this.analyticsService.buttonClickEvent(tab.button_id);
  }

  menuCliked() {
    this.userService.updateIsWorkspaceAdminStatus();
    this.analyticsService.buttonClickEvent("nav_settings");
  }

  ngOnInit() {
    this.userAccountService.profilePicChangeSubject.subscribe((data) => {
      this.avatarRefreshCount++;
    });
    const i = this;
    this.userAccountService.getUserAccount(false, function (account) {
      if (!account) {
        return;
      }
      i.userAccount = account;
      i.listenToUserDND(account.userId);
      i.setDnd();
      i.getConnectionRequests();
      i.getActivities();
      // i.getUnseenConnections();
      i.getNotificationStatuses();
    });
    this.userAccountService.accountSubject.subscribe((acc) => {
      this.userAccount = acc;
      this.listenToUserDND(this.userAccount.userId);
      this.setDnd();
      // this.getConnectionRequests();
      // this.getUnseenConnections();
    });
    const instance = this;
    window.setInterval(function () {
      instance.setDnd();
    }, 5000);

    this.sub1 = this.sharedService.notificationCount$.subscribe((count) => {
      this.tabs[0]["notifications"] = count;
    });
    combineLatest([
      this.authService.isDepartmentAdmin$,
      this.authService.isBroadcaster$,
    ]).subscribe(([isDepartmentAdmin, isBroadcaster]) => {
      this.role_tabs = [];

      if (isDepartmentAdmin) {
        this.setAdminTab();
      }
      if (isBroadcaster) {
        this.setBroadcasterTab();
      }
    });

    this.ConnectionChangeSub = this.conService.ConnectionChange.subscribe(
      (connection) => {
        if (
          connection &&
          connection.connection &&
          connection.connection.state == "Pending"
        ) {
          this.profileService.pendingConnections = [];
          this.getConnectionRequests();
        }
      }
    );

    this.teams$ = this.teamsService.userTeams$;

    this.isAuthenticated$ = this.userService.userId$.pipe(
      map((id) => id !== null)
    );

    const rolesTab = this.tabs.find((tab) => tab.id === this.ROLES_TAB_ID);
    combineLatest([
      this.teamsService.userTeams$,
      this.accountService.userFeatureFlags$,
    ]).subscribe(([teams, featureFlags]) => {
      if (!rolesTab || !teams || !teams.length || !featureFlags) return;
      rolesTab.isActive = teams.length > 0 && featureFlags.hasRoles;
      rolesTab.notifications = teams.reduce((value, team) => {
        if (!team.unreadConversationIds?.length) return value;
        return value + 1;
      }, 0);
    });

    const externalTab = this.tabs.find(
      (tab) => tab.id === this.EXTERNAL_TAB_ID
    );

    combineLatest([
      this.userService.isExternalConversationsEnabled$,
      this.conversationsService.conversations$,
    ])
      .pipe(
        startWith([false, null]),
        map<
          [boolean, ConversationModelV2WithMetadata[] | null],
          [boolean, ConversationModelV2WithMetadata[]]
        >(([isExternalConversationsEnabled, conversations]) => {
          const externalConversations = conversations
            ? this.conversationsService.filterExternalConversations(
                conversations
              )
            : [];
          return [isExternalConversationsEnabled, externalConversations];
        })
      )
      .subscribe({
        next: ([isExternalConversationsEnabled, externalConversations]) => {
          externalTab.isActive =
            isExternalConversationsEnabled || externalConversations.length > 0;

          const unreadConversationsCount = externalConversations.reduce(
            (value, conversation) => {
              if (!conversation.unreadMessageIds?.length) return value;
              return value + 1;
            },
            0
          );
          externalTab.notifications = unreadConversationsCount;
        },
      });

    const companies: CompanyModel[] = [];
    const companiesSubscription = this.companiesService
      .getCompaniesV1({
        fetchAll: true,
      })
      .subscribe({
        next: (page) => {
          companies.push(...page.data);
        },
        complete: () => {
          const paidCompany: CompanyModel | null = companies.find(
            (c) => c.plan !== CompanyPlan.Free && c.isActive
          );
          this.plan$ = of(paidCompany?.plan ?? CompanyPlan.Free);
          this.paidCompany$ = of(paidCompany ?? null);
        },
      });

    this.subscriptions.add(companiesSubscription);
  }

  showNotifications() {
    this.getConnectionRequests();
    this.markNotificationsRead();
    this.getActivities();
    this.analyticsService.buttonClickEvent("nav_feed");
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.conService.userDND$.subscribe((userDND) => {
      if (userDND.userId == userId) {
        this.userAccount["doNotDisturbToUtc"] = userDND.doNotDisturbToUtc;
        // this.setDND()
      }
    });
  }

  setDnd() {
    if (this.userAccount) {
      this.userAccount["doNotDisturb"] = false;
      this.userAccount["doNotDisturbIndefinite"] = true;
      this.userAccount["doNotDisturb"] = this.userAccountService.isOnDND(
        this.userAccount
      );
      this.userAccount["doNotDisturbIndefinite"] =
        this.userAccountService.isOnDNDIndefinite();
    }
  }

  updateUnread(unread: number) {
    this.tabs.forEach((tab) => {
      if (tab.link == "conversations") {
        tab.notifications = unread;
      }
    });
  }

  setAdminTab() {
    this.role_tabs = this.mergeTabsUniquelyByField(
      this.role_tabs,
      this.admin_roles,
      "id"
    );
  }

  setBroadcasterTab() {
    this.role_tabs = this.mergeTabsUniquelyByField(
      this.role_tabs,
      this.broadcast_roles,
      "id"
    );
  }

  mergeTabsUniquelyByField(array: any[], child, fieldName) {
    let hit = false;
    array.forEach((element) => {
      if (element[fieldName] == child[fieldName]) {
        hit = true;
        return;
      }
    });
    if (!hit) {
      array = array.concat(child);
    }
    return array;
  }

  logout() {
    this.analyticsService.buttonClickEvent("logout_and_forget_me");
    this.voipService.openLeaveCallDialogIfRequired().subscribe({
      next: (isCallInProgress) => {
        if (isCallInProgress) return;
        this.sharedService.isLoading = true;
        this.authService.logout("Top navigation");
      },
    });
  }

  addColleagues() {
    this.sharedService.invite("user");
  }

  isOnDoNotDisturb() {
    if (this.userAccount && this.userAccount["doNotDisturb"]) {
      return true;
    }
    return false;
  }

  doNotDisturbClick() {
    const instance = this;
    if (this.isOnDoNotDisturb()) {
      this.doNotDisturbChange(0, function () {
        instance.userAccount["doNotDisturb"] = null;
      });
    } else {
      // show pop up
      this.doNotDisturbPopup();
    }
  }

  doNotDisturbChange(interval, callback) {
    const instance = this;
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.userAccountService.doNotDisturbChange(interval, function (change) {
      if (change) {
        callback();
        instance.userAccount["doNotDisturbInterval"] =
          change["doNotDisturbInterval"];
        instance.userAccount["doNotDisturbIndefinite"] =
          instance.userAccountService.isOnDNDIndefinite(
            change["doNotDisturbToUtc"]
          );
      }
    });
  }

  // doNotDisturbChange(interval,callback){
  //   let path = environment.celoApiEndpoint + '/api/Account/DoNotDisturb';
  //   let params = {
  //     doNotDisturbInterval : interval
  //   }
  //   this.sharedService.postObjectById(path, {}, params).subscribe(
  //     resp=>{
  //       callback();
  //       // this.doNotDisturb = false;
  //     }
  //   )
  // }
  doNotDisturbPopup() {
    const data = {};
    data["payload"] = this.payload;
    data["default"] = 2880;
    const instance = this;
    this.sharedService.openDNDDialog(data).subscribe((result) => {
      if (result) {
        this.doNotDisturbChange(result, function () {
          instance.userAccount["doNotDisturb"] = true;
        });
        // this.muteConversation(result);
      }
    });
  }

  lock() {
    this.analyticsService.buttonClickEvent("pin_lock");
    this.voipService.openLeaveCallDialogIfRequired().subscribe({
      next: (isCallInProgress) => {
        if (isCallInProgress) return;
        this.pinService.lock();
      },
    });
  }

  openHelp() {
    const url = "https://www.celohealth.com/legal/";
    const win = window.open(url, "_blank");
    win.focus();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.sub1.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.ConnectionChangeSub) {
      this.ConnectionChangeSub.unsubscribe();
    }
  }

  getConnectionRequests() {
    const instance = this;
    this.profileService.getPendingConnectionRequest(10, 0, function (resp) {
      if (!resp || !resp.data) {
        return;
      }
      instance.profileService.pendingConnections = resp.data;
    });
  }

  getActivities() {
    this.notificationsService.getActivities(false).subscribe();
  }

  getNotificationStatuses() {
    const instance = this;
    this.profileService.getNotificationStatuses(function (
      resp: NofificationSeenStatusModel
    ) {
      if (!resp) {
        return;
      }
      instance.notificationsService.unseenConnections =
        !resp.connectionRequestSeen;
      instance.notificationsService.unseenActivityFeed = !resp.activityFeedSeen;
    });
  }

  markNotificationsRead() {
    const instance = this;
    this.profileService.updateNotificationStatuses(true, true, function (resp) {
      if (!resp) {
        return;
      }
      instance.notificationsService.unseenActivityFeed = false;
      instance.notificationsService.unseenConnections = false;
    });
  }

  public handleTeamClicked(team: Team) {
    if (!team.id) return;
    this.router.navigate(["/"]).then(() => {
      this.router.navigate(["roles", team.id]);
    });
  }
}
