import { Conversation, Message, UnreadConversationSkeleton } from "@types";

export type PaginationData = {
  /** Start from zero with zero being the first page. `null` if there is no next page. */
  nextPageNumber: number | null;
  totalPages: number;
  hasNext: boolean;
};

export type ConversationsState = {
  skeletonConversations: UnreadConversationSkeleton[];

  isInboxLoading: boolean;
  isInboxLoadingMore: boolean;
  conversations: Conversation[];
  inboxPaginationData: PaginationData;

  isInboxSearchLoading: boolean;
  isInboxSearchLoadingMore: boolean;
  inboxSearchResults: Conversation[];
  inboxSearchQuery: string;
  inboxSearchResultsPaginationData: PaginationData;

  isExternalInboxLoading: boolean;
  isExternalSearchInboxLoading: boolean;
  isExternalInboxLoadingMore: boolean;
  isExternalInboxSearchLoadingMore: boolean;
  externalInboxPaginationData: PaginationData;
  externalInboxSearchQuery: string;
  externalInboxSearchResults: Conversation[];
  externalSearchResultsPaginationData: PaginationData;

  selectedTeamId: string | null;
  isRolesInboxLoading: boolean;
  isRolesInboxLoadingMore: boolean;
  rolesInboxPaginationData: PaginationData;
  isRolesInboxSearchLoading: boolean;
  isRolesInboxSearchLoadingMore: boolean;
  rolesInboxSearchQuery: string;
  rolesInboxSearchResults: Conversation[];
  rolesInboxSearchResultsPaginationData: PaginationData;

  isSelectedConversationLoading: boolean;
  selectedConversationId: string | null;
  isInitialPageOfMessagesLoading: boolean;
  isNextPageOfMessagesLoading: boolean;
  isPreviousPageOfMessagesLoading: boolean;
  selectedConversationMessages: Message[] | null;

  isSaving: boolean;

  selectedReplyMessage: Message | null;
};
