import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import * as XRegExp from "xregexp";
import { SharedService, UserAccountService } from "../../modules/core";

@Component({
  selector: "app-edit-profession",
  templateUrl: "./edit-profession.component.html",
  styleUrls: ["./edit-profession.component.scss", "../../../celo-input.scss"],
})
export class EditProfessionComponent implements OnInit, OnChanges {
  @Input() hideVerifyButton: boolean;
  @Input() editMode: boolean;
  @Input() professionReadonly: boolean;
  @Input() hidehint: boolean;
  profession: any;
  @Input() myCategory: string;
  @Input() myProfession: string;
  @Input() status: string;
  profession_specify: any;
  professions: any = [];
  selectedProfessions: any = [];
  loading: boolean;
  edited: boolean;
  @Output() dataChange = new EventEmitter<any>();
  @Output() validity = new EventEmitter<any>();
  @Output() confirmButtonTextChange = new EventEmitter<any>();
  @Output() verifyProfession = new EventEmitter<any>();
  @Output() removeProfession = new EventEmitter<any>();
  regex = XRegExp("^[\\p{L} ,.'-]*$");
  valid: boolean;

  professionBackup: any = {};
  specifyBackup: any;

  constructor(
    private userAccountService: UserAccountService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getProfessions();
    this.validity.emit(false);
    this.setSelectedProfession();
  }

  setSelectedProfession() {
    if (!this.myProfession) {
      return;
    }
    for (const iterator of this.professions) {
      if (iterator.name == this.myCategory) {
        this.profession = iterator;
        this.profession_specify = this.myProfession ? this.myProfession : "";
      }
    }

    this.professionBackup = this.profession;
    this.specifyBackup = this.profession_specify;

    this.setValidity();
  }

  ngOnChanges() {
    this.setSelectedProfession();
  }

  hasChanged() {
    if (
      this.professionBackup.name != this.profession.name ||
      !this.profession
    ) {
      return true;
    }
    if (!this.profession.allowFreeformProfession) {
      return false;
    }

    if (this.specifyBackup != this.profession_specify) {
      return true;
    }
    return false;
  }

  onKey(value) {
    this.selectedProfessions = this.search(value);
  }

  search(value: string) {
    const filter = value.toLowerCase();
    return this.professions.filter((profession_ob) => {
      if (
        profession_ob.name.toLowerCase().indexOf(filter) != -1 ||
        profession_ob.allowFreeformProfession
      ) {
        return true;
      }
      return false;
    });
  }

  getProfessions() {
    this.loading = true;
    const instance = this;
    this.userAccountService.getProfessions(function (professions) {
      instance.loading = false;
      if (professions) {
        instance.professions = professions;
      }
      instance.selectedProfessions = instance.professions;
      instance.setSelectedProfession();
    });
  }

  onProfessionSelected(profession) {
    this.profession = profession;
    this.profession_specify = "";
    this.emit();
  }

  verifyProfessionClick(profession) {
    profession["specify"] = this.profession_specify;
    this.verifyProfession.emit(profession);
  }

  removeProfessionClick(profession) {
    profession["specify"] = this.profession_specify;
    this.removeProfession.emit(profession);
  }

  setValidity() {
    if (
      !this.profession ||
      (this.profession.allowFreeformProfession &&
        !this.isValidField(this.profession_specify)) ||
      !this.hasChanged()
    ) {
      this.valid = false;
    } else {
      if (
        (this.profession.allowFreeformProfession && this.profession_specify) ||
        !this.profession.allowFreeformProfession
      ) {
        this.valid = true;
      }
    }
  }

  isValidField(value) {
    return this.regex.test(value);
  }

  emit() {
    this.edited = true;
    this.dataChange.emit({
      name: this.profession["name"],
      specify: this.profession_specify,
      canVerify: this.profession.canVerify,
    });
    this.valid = false;

    this.setValidity();
    this.validity.emit(this.valid);
    if (this.profession && !this.profession.canVerify) {
      this.confirmButtonTextChange.emit("SAVE");
    } else {
      this.confirmButtonTextChange.emit("NEXT");
    }
  }

  inputChange() {
    setTimeout(() => {
      this.sanitiseFields();
      this.emit();
    }, 100);
  }

  sanitiseFields() {
    this.profession_specify = this.sharedService.removeInvalidChars(
      this.profession_specify
    );
  }
}
