<div *ngIf="isBrowserSupported && isBigScreen && !isMobile">
  <div class="page-container">
    <div *ngIf="!navHidden && !sharedService?.isLoading && !isCall()">
      <app-upgrade-banner
        *ngIf="!(isBannerDismissed$ | async)"
        [upgradeUrl]="upgradeUrl"
        (close)="handleCloseBanner()"
      ></app-upgrade-banner>
      <nav app-nav-top #nav [upgradeUrl]="upgradeUrl"></nav>
    </div>
    <div
      class="celo-app-content relative"
      [class.full-height]="navHidden || sharedService?.isLoading"
    >
      <router-outlet></router-outlet>
      <div class="loading-screen" *ngIf="sharedService?.isLoading">
        <app-empty-state
          [no_message]="true"
          [isLoading]="true"
          [spinner]="false"
          [animation]="true"
          [diameter]="70"
        ></app-empty-state>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isBrowserSupported || !isBigScreen || isMobile"
  class="unsupported"
>
  <div class="get-container">
    <img src="/assets/logo-v2.svg" class="logo" />
    <img src="./assets/doctorcartoon.png" class="img-container" />

    <div *ngIf="!isBrowserSupported">
      <h1 class="bold text-center text-32">
        Uh oh, looks like you're using an unsupported browser
      </h1>

      <ng-container *ngIf="!isMobile">
        <div class="text-22">
          Celo currently supports the following browsers
        </div>
        <div class="browser-card-container">
          <a class="browser-card mt-12" href="https://www.google.com/chrome">
            <img
              class="icon"
              src="../assets/icons/chrome@3x.png"
              alt="Google Chrome icon"
            />
            <div class="content">
              <div class="bold text-22">Google Chrome</div>
              <div class="text-18">MacOS, Windows 7 and above</div>
            </div>
          </a>
        </div>
        <div class="browser-card-container">
          <a
            class="browser-card mt-12"
            href="https://www.microsoft.com/en-us/edge"
          >
            <img
              class="icon"
              src="../assets/icons/edge@3x.png"
              alt="Microsoft Edge icon"
            />
            <div class="content">
              <div class="bold text-22">Microsoft Edge</div>
              <div class="text-18">MacOS, Windows 7 and above</div>
            </div>
          </a>
        </div>
      </ng-container>

      <div class="text-22 mt-42">
        Check out our mobile app for Celo on the go!
      </div>
    </div>

    <div class="text" *ngIf="isBrowserSupported">
      You're using <u>Chrome</u>, But your screen is too small for Celo Desktop.
      Please download the mobile app.
    </div>

    <div class="app-download-buttons mt-24">
      <a
        href="https://itunes.apple.com/nz/app/celo-secure-messaging-for/id1176847079?mt=8"
      >
        <img src="/assets/app-store-badge.svg" height="45" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=nz.co.celo.app">
        <img
          src="/assets/google-play-badge.svg"
          height="45"
          alt="Android app on Google Play"
        />
      </a>
    </div>
  </div>
</div>
