<div class="flex flex-col items-center g-10px mt-20px mb-40px">
  <app-basic-avatar [name]="fullName" [size]="128"></app-basic-avatar>
  <h2 class="mat-h2 title">{{ fullName }}</h2>

  <button
    mat-button
    class="celo-secondary-button uppercase"
    (click)="editDetails()"
  >
    Edit Details
  </button>
</div>

<h3 class="mat-h3 fields-title">Contact Details</h3>

<div class="field">
  <h6 class="mat-body label mb-0">Last Name</h6>
  <p class="mat-subtitle-2 value">{{ lastName }}</p>
</div>

<div class="field">
  <h6 class="mat-body label mb-0">First Name</h6>
  <p class="mat-subtitle-2 value">{{ firstName }}</p>
</div>

<div class="field">
  <h6 class="mat-body label mb-0">Date of Birth</h6>
  <p class="mat-subtitle-2 value">
    {{ dateOfBirth | dateFormat: "dateOfBirth" }}
  </p>
</div>

<div class="field">
  <h6 class="mat-body label mb-0">Mobile</h6>
  <p class="mat-subtitle-2 value">{{ phoneNumber | phoneFormat }}</p>
</div>
