import { Injectable, OnDestroy } from "@angular/core";
import {
  ChangePasswordModel,
  FullUserProfileModel,
  IdentityVerificationStatus,
  ProfessionVerificationStatus,
  UserPrivacyModel,
  VerificationStatus,
} from "@types";
import { BehaviorSubject, Observable, Subscription, pipe } from "rxjs";
import { map, tap } from "rxjs/operators";
import { UserLimitsResponseModelV3 } from "./../../../../types/api-v3";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

export interface ParsedUserPrivacyModel
  extends Omit<UserPrivacyModel, "settings"> {
  name: string;
  settings: Partial<Record<string, boolean>>;
}

export interface UserPrivacyPhoneModel extends ParsedUserPrivacyModel {
  name: "phone";
  settings: {
    myConnections?: boolean;
    myDepartments?: boolean;
    otherDepartments?: boolean;
    otherWorkplaces?: boolean;
  };
}

export interface UserPrivacyEmailModel extends ParsedUserPrivacyModel {
  name: "email";
  settings: {
    myConnections?: boolean;
    myDepartments?: boolean;
    otherDepartments?: boolean;
    otherWorkplaces?: boolean;
  };
}

export interface UserPrivacyDiscoverabilityModel
  extends ParsedUserPrivacyModel {
  name: "discoverability";
  settings: {
    network?: boolean;
  };
}

export type UserPrivacyModelType =
  | UserPrivacyPhoneModel
  | UserPrivacyEmailModel
  | UserPrivacyDiscoverabilityModel;

export type UserPrivacyModelName = UserPrivacyModelType["name"];

export interface UserPrivacySettings {
  phone: UserPrivacyPhoneModel["settings"];
  email: UserPrivacyEmailModel["settings"];
  discoverability: UserPrivacyDiscoverabilityModel["settings"];
}

export type UserLimits = UserLimitsResponseModelV3;

@Injectable({
  providedIn: "root",
})
export class AccountService implements OnDestroy {
  private userAccountSubject = new BehaviorSubject<FullUserProfileModel | null>(
    null
  );

  public userAccount$ = this.userAccountSubject.asObservable();

  private userPrivacySettingsSubject =
    new BehaviorSubject<UserPrivacySettings | null>(null);

  public userPrivacySettings$ = this.userPrivacySettingsSubject.asObservable();

  public isDiscoverable$: Observable<boolean> | null =
    this.userPrivacySettingsSubject.pipe(
      map(() => this.isDiscoverableOnNetwork())
    );

  public isVerified$ = this.userAccountSubject.pipe(
    map(() => this.isVerified())
  );

  private isAuthenticatedSubscription: Subscription;

  private userLimitsSubject = new BehaviorSubject<UserLimits | null>(null);
  public userLimits$ = this.userLimitsSubject.asObservable();

  public constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {
    // Update user when authentication state changes
    this.isAuthenticatedSubscription =
      this.authService.isAuthenticated$.subscribe({
        next: (isAuthenticated) => {
          if (isAuthenticated) {
            this.getAccount().subscribe();
            this.getPrivacySettings().subscribe();
            this.getUserLimits().subscribe();
          } else {
            this.userAccountSubject.next(null);
            this.userPrivacySettingsSubject.next(null);
            this.userLimitsSubject.next(null);
          }
        },
      });
  }

  public ngOnDestroy(): void {
    this.isAuthenticatedSubscription.unsubscribe();
  }

  private updateUserLimitsTap() {
    return pipe(
      tap<UserLimitsResponseModelV3>({
        next: (response) => {
          this.userLimitsSubject.next(response);
        },
      })
    );
  }

  public getUserLimits(): Observable<UserLimitsResponseModelV3> {
    const path = `/api/v3/User/limits`;
    return this.apiService
      .get<UserLimitsResponseModelV3>({
        path,
      })
      .pipe(this.updateUserLimitsTap());
  }

  private updateUserPrivacySettingsTap() {
    return pipe(
      tap<UserPrivacySettings>({
        next: (settings) => this.userPrivacySettingsSubject.next(settings),
      })
    );
  }

  public getPrivacySettings(): Observable<UserPrivacySettings> {
    const path = `/api/v2/Account/Privacy/Settings`;
    return this.apiService.get<UserPrivacyModel[]>({ path }).pipe(
      map((models) => this.parseUserPrivacyModels(models)),
      this.updateUserPrivacySettingsTap()
    );
  }

  private parseUserPrivacyModels(
    models: UserPrivacyModel[]
  ): UserPrivacySettings {
    // #TODO Cleanup parsing code, avoid unsafe type assertions
    const parsedModels: UserPrivacyModelType[] = models.map(
      this.parseUserPrivacyModel.bind(this)
    );
    return this.convertParsedModelToSettings(parsedModels);
  }

  private convertParsedModelToSettings(
    models: UserPrivacyModelType[]
  ): UserPrivacySettings {
    const entries = models.map((model) => [model.name, model.settings]);
    return Object.fromEntries(entries);
  }

  private parseUserPrivacyModel(model: UserPrivacyModel): UserPrivacyModelType {
    return {
      name: model.name ?? "",
      settings: this.parseUserPrivacyModelSettings(model.settings),
    } as unknown as UserPrivacyModelType;
  }

  private parseUserPrivacyModelSettings<T extends UserPrivacyModelType>(
    settings: UserPrivacyModel["settings"]
  ): T["settings"] {
    const entries = Object.entries(settings ?? {});

    const mapBooleanString = (value: string): boolean => {
      return value === "true";
    };

    const pairs: [string, boolean][] = entries.map(([key, value]) => [
      key,
      mapBooleanString(value),
    ]);

    return Object.fromEntries(pairs) as unknown as T["settings"];
  }

  private updateUserAccountTap() {
    return pipe(
      tap<FullUserProfileModel>({
        next: (profile) => this.userAccountSubject.next(profile),
      })
    );
  }

  public getAccount(): Observable<FullUserProfileModel> {
    const path = "/api/v2/Account";
    return this.apiService
      .get<FullUserProfileModel>({ path })
      .pipe(this.updateUserAccountTap());
  }

  public getCurrentAccount(): FullUserProfileModel | null {
    return this.userAccountSubject.value;
  }

  public changePassword(options: ChangePasswordModel): Observable<void> {
    const path = "/api/Account/Password/Change";
    return this.apiService.post<void>({
      path,
      body: { ...options },
    });
  }

  public updatePresence(): Observable<void> {
    const path = "/api/Account/Presence";
    return this.apiService.post<void>({
      path,
    });
  }

  public isIdentityVerified(): boolean {
    return (
      this.userAccountSubject.value?.identityVerificationStatus ===
      IdentityVerificationStatus.Verified
    );
  }

  public isWorkspaceVerified(): boolean {
    return (
      this.userAccountSubject.value?.workplaces?.some(
        (w) => w.verificationStatus === VerificationStatus.Verified
      ) ?? false
    );
  }

  public isProfessionVerified(): boolean {
    return (
      this.userAccountSubject.value?.professions?.some(
        (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
      ) ?? false
    );
  }

  public isDiscoverableOnNetwork(): boolean {
    return (
      this.userPrivacySettingsSubject.value?.discoverability?.network ?? false
    );
  }

  public isVerified(): boolean {
    return (
      this.isIdentityVerified() ||
      this.isWorkspaceVerified() ||
      this.isProfessionVerified()
    );
  }

  public isDiscoverableAndVerified(): boolean {
    return this.isDiscoverableOnNetwork() && this.isVerified();
  }
}
