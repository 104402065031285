/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type TokenPagedResultOfInvoice = PagedResultOfInvoice & {
  hasPrevious?: boolean;
  hasNext?: boolean;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  total?: number;
  token?: string | null;
};

export type PagedResultOfInvoice = PagedResultMetadata & {
  data?: Invoice[] | null;
};

/**
 * Represents an invoice
 *
 */
export interface Invoice {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   *
   * @format date-time
   */
  created?: string;
  /**
   * The link to download the PDF for the invoice. If the invoice has not been finalized yet,
   * this will be null.
   *
   */
  invoicePdf?: string;
  /**
   * End of the usage period during which invoice items were added to this invoice.
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Start of the usage period during which invoice items were added to this invoice.
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Subscription tier for the subscription associated with this invoice.
   *
   */
  subscriptionTier?: SubscriptionTier;
  /**
   * This is the URL to view the receipt for the latest charge associated with this invoice. The receipt is kept
   * up-to-date to the latest state of the charge, including any refunds. If the charge is for an Invoice,
   * the receipt will be stylized as an Invoice receipt.
   *
   */
  receiptUrl?: string;
  /**
   * Three-letter
   *
   *     ISO currency
   *     code
   *
   * , in lowercase. Must be a
   *
   *     supported
   *     currency
   *
   * .
   *
   */
  currency?: string;
  /**
   * The individual line items that make up the invoice. lines is sorted as follows:
   * (1) pending invoice items (including prorations) in reverse chronological order, (2)
   * subscription items in reverse chronological order, and (3) invoice items added after
   * invoice creation in chronological order.
   *
   */
  lines?: InvoiceLineItem[];
  /**
   * Total of all subscriptions, invoice items, and prorations on the invoice before any
   * invoice level discount or exclusive tax is applied. Item discounts are already
   * incorporated.
   *
   * @format int64
   */
  subtotal?: number;
  /**
   * Total after discounts and taxes.
   *
   * @format int64
   */
  total?: number;
}

/**
 * Subscription tiers.
 *
 */
export enum SubscriptionTier {
  Free = "Free",
  Business = "Business",
  Enterprise = "Enterprise",
  Growth = "Growth",
  BusinessPro = "BusinessPro",
  BusinessV2 = "BusinessV2",
}

export interface InvoiceLineItem {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * The amount, in cents (or local equivalent).
   *
   * @format int64
   */
  amount?: number;
  /**
   * An arbitrary string attached to the object. Often useful for displaying to users.
   *
   */
  description?: string;
  /**
   * Whether this is a proration.
   *
   */
  proration?: boolean;
  /**
   * The quantity of the subscription, if the line item is a subscription or a proration.
   *
   * @format int64
   */
  quantity?: number | null;
}

export interface PagedResultMetadata {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  total?: number;
  /** @format date-time */
  pageProcessedOn?: string;
  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
  /** @format int32 */
  previousPage?: number | null;
  /** @format int32 */
  nextPage?: number | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

/**
 * PaymentMethod objects represent your customer's payment instruments. You can use them
 * with PaymentIntents to
 * collect payments or save them to Customer objects to store instrument details for future
 * payments.
 * Related guides:
 *
 *     Payment
 *     Methods
 *
 * and
 *
 *     More
 *     Payment Scenarios
 *
 * .
 *
 */
export interface PaymentMethod {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  acssDebit?: PaymentMethodAcssDebit;
  affirm?: PaymentMethodAffirm;
  afterpayClearpay?: PaymentMethodAfterpayClearpay;
  alipay?: PaymentMethodAlipay;
  auBecsDebit?: PaymentMethodAuBecsDebit;
  bacsDebit?: PaymentMethodBacsDebit;
  bancontact?: PaymentMethodBancontact;
  billingDetails?: PaymentMethodBillingDetails;
  blik?: PaymentMethodBlik;
  boleto?: PaymentMethodBoleto;
  card?: PaymentMethodCard;
  cardPresent?: PaymentMethodCardPresent;
  cashapp?: PaymentMethodCashapp;
  /**
   * (ID of the Customer)
   * The ID of the Customer to which this PaymentMethod is saved. This will not be set when
   * the PaymentMethod has not been saved to a Customer.
   *
   */
  customerId?: string;
  customerBalance?: PaymentMethodCustomerBalance;
  eps?: PaymentMethodEps;
  fpx?: PaymentMethodFpx;
  giropay?: PaymentMethodGiropay;
  grabpay?: PaymentMethodGrabpay;
  ideal?: PaymentMethodIdeal;
  interacPresent?: PaymentMethodInteracPresent;
  klarna?: PaymentMethodKlarna;
  konbini?: PaymentMethodKonbini;
  link?: PaymentMethodLink;
  /**
   * Set of key-value pairs that you can
   * attach to an object. This can be useful for storing additional information about the
   * object in a structured format.
   *
   */
  metadata?: Record<string, string>;
  oxxo?: PaymentMethodOxxo;
  p24?: PaymentMethodP24;
  paynow?: PaymentMethodPaynow;
  paypal?: PaymentMethodPaypal;
  pix?: PaymentMethodPix;
  promptpay?: PaymentMethodPromptpay;
  /**
   * Options to configure Radar. See
   *
   *     Radar Session
   *
   * for more
   * information.
   *
   */
  radarOptions?: PaymentMethodRadarOptions;
  revolutPay?: PaymentMethodRevolutPay;
  sepaDebit?: PaymentMethodSepaDebit;
  sofort?: PaymentMethodSofort;
  /**
   * The type of the PaymentMethod. An additional hash is included on the PaymentMethod with
   * a name matching this value. It contains additional information specific to the
   * PaymentMethod type.
   * One of: acss_debit, affirm, afterpay_clearpay, alipay,
   * au_becs_debit, bacs_debit, bancontact, blik, boleto,
   * card, card_present, cashapp, customer_balance, eps,
   * fpx, giropay, grabpay, ideal, interac_present,
   * klarna, konbini, link, oxxo, p24, paynow,
   * paypal, pix, promptpay, revolut_pay, sepa_debit,
   * sofort, us_bank_account, wechat_pay, or zip.
   *
   */
  type?: string;
  usBankAccount?: PaymentMethodUsBankAccount;
  wechatPay?: PaymentMethodWechatPay;
  zip?: PaymentMethodZip;
}

export interface PaymentMethodAcssDebit {
  /**
   * Name of the bank associated with the bank account.
   *
   */
  bankName?: string;
  /**
   * Uniquely identifies this particular bank account. You can use this attribute to check
   * whether two bank accounts are the same.
   *
   */
  fingerprint?: string;
  /**
   * Institution number of the bank account.
   *
   */
  institutionNumber?: string;
  /**
   * Last four digits of the bank account number.
   *
   */
  last4?: string;
  /**
   * Transit number of the bank account.
   *
   */
  transitNumber?: string;
}

export type PaymentMethodAffirm = object;

export type PaymentMethodAfterpayClearpay = object;

export type PaymentMethodAlipay = object;

export interface PaymentMethodAuBecsDebit {
  /**
   * Six-digit number identifying bank and branch associated with this bank account.
   *
   */
  bsbNumber?: string;
  /**
   * Uniquely identifies this particular bank account. You can use this attribute to check
   * whether two bank accounts are the same.
   *
   */
  fingerprint?: string;
  /**
   * Last four digits of the bank account number.
   *
   */
  last4?: string;
}

export interface PaymentMethodBacsDebit {
  /**
   * Uniquely identifies this particular bank account. You can use this attribute to check
   * whether two bank accounts are the same.
   *
   */
  fingerprint?: string;
  /**
   * Last four digits of the bank account number.
   *
   */
  last4?: string;
  /**
   * Sort code of the bank account. (e.g., 10-20-30).
   *
   */
  sortCode?: string;
}

export type PaymentMethodBancontact = object;

export interface PaymentMethodBillingDetails {
  /**
   * Billing address.
   *
   */
  address?: Address;
  /**
   * Email address.
   *
   */
  email?: string;
  /**
   * Full name.
   *
   */
  name?: string;
  /**
   * Billing phone number (including extension).
   *
   */
  phone?: string;
}

export interface Address {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
}

export type PaymentMethodBlik = object;

export interface PaymentMethodBoleto {
  /**
   * Uniquely identifies the customer tax id (CNPJ or CPF).
   *
   */
  taxId?: string;
}

export interface PaymentMethodCard {
  /**
   * Card brand. Can be amex, diners, discover, eftpos_au,
   * jcb, mastercard, unionpay, visa, or unknown.
   *
   */
  brand?: string;
  /**
   * Checks on Card address and CVC if provided.
   *
   */
  checks?: PaymentMethodCardChecks;
  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute
   * to get a sense of the international breakdown of cards you've collected.
   *
   */
  country?: string;
  /**
   * A high-level description of the type of cards issued in this range. (For internal use
   * only and not typically available in standard API requests.).
   *
   */
  description?: string;
  /**
   * Two-digit number representing the card's expiration month.
   *
   * @format int64
   */
  expMonth?: number;
  /**
   * Four-digit number representing the card's expiration year.
   *
   * @format int64
   */
  expYear?: number;
  /**
   * Uniquely identifies this particular card number. You can use this attribute to check
   * whether two customers who’ve signed up with you are using the same card number, for
   * example. For payment methods that tokenize card information (Apple Pay, Google Pay), the
   * tokenized number might be provided instead of the underlying card number.
   *
   *     As of May 1, 2021, card fingerprint in India for Connect changed to allow two
   *     fingerprints for the same card---one for India and one for the rest of the world.
   *
   * .
   *
   */
  fingerprint?: string;
  /**
   * Card funding type. Can be credit, debit, prepaid, or
   * unknown.
   *
   */
  funding?: string;
  /**
   * Issuer identification number of the card. (For internal use only and not typically
   * available in standard API requests.).
   *
   */
  iin?: string;
  /**
   * The name of the card's issuing bank. (For internal use only and not typically available
   * in standard API requests.).
   *
   */
  issuer?: string;
  /**
   * The last four digits of the card.
   *
   */
  last4?: string;
  /**
   * Contains information about card networks that can be used to process the payment.
   *
   */
  networks?: PaymentMethodCardNetworks;
  /**
   * Contains details on how this Card may be used for 3D Secure authentication.
   *
   */
  threeDSecureUsage?: PaymentMethodCardThreeDSecureUsage;
  /**
   * If this Card is part of a card wallet, this contains the details of the card wallet.
   *
   */
  wallet?: PaymentMethodCardWallet;
}

export interface PaymentMethodCardChecks {
  /**
   * If a address line1 was provided, results of the check, one of pass, fail,
   * unavailable, or unchecked.
   *
   */
  addressLine1Check?: string;
  /**
   * If a address postal code was provided, results of the check, one of pass,
   * fail, unavailable, or unchecked.
   *
   */
  addressPostalCodeCheck?: string;
  /**
   * If a CVC was provided, results of the check, one of pass, fail,
   * unavailable, or unchecked.
   *
   */
  cvcCheck?: string;
}

export interface PaymentMethodCardNetworks {
  /**
   * All available networks for the card.
   *
   */
  available?: string[];
  /**
   * The preferred network for the card.
   *
   */
  preferred?: string;
}

export interface PaymentMethodCardThreeDSecureUsage {
  /**
   * Whether 3D Secure is supported on this card.
   *
   */
  supported?: boolean;
}

export interface PaymentMethodCardWallet {
  amexExpressCheckout?: PaymentMethodCardWalletAmexExpressCheckout;
  applePay?: PaymentMethodCardWalletApplePay;
  /**
   * (For tokenized numbers only.) The last four digits of the device account number.
   *
   */
  dynamicLast4?: string;
  googlePay?: PaymentMethodCardWalletGooglePay;
  link?: PaymentMethodCardWalletLink;
  masterpass?: PaymentMethodCardWalletMasterpass;
  samsungPay?: PaymentMethodCardWalletSamsungPay;
  /**
   * The type of the card wallet, one of amex_express_checkout, apple_pay,
   * google_pay, masterpass, samsung_pay, visa_checkout, or
   * link. An additional hash is included on the Wallet subhash with a name matching
   * this value. It contains additional information specific to the card wallet type.
   * One of: amex_express_checkout, apple_pay, google_pay, link,
   * masterpass, samsung_pay, or visa_checkout.
   *
   */
  type?: string;
  visaCheckout?: PaymentMethodCardWalletVisaCheckout;
}

export type PaymentMethodCardWalletAmexExpressCheckout = object;

export type PaymentMethodCardWalletApplePay = object;

export type PaymentMethodCardWalletGooglePay = object;

export type PaymentMethodCardWalletLink = object;

export interface PaymentMethodCardWalletMasterpass {
  /**
   * Owner's verified billing address. Values are verified or provided by the wallet directly
   * (if supported) at the time of authorization or settlement. They cannot be set or
   * mutated.
   *
   */
  billingAddress?: Address;
  /**
   * Owner's verified email. Values are verified or provided by the wallet directly (if
   * supported) at the time of authorization or settlement. They cannot be set or mutated.
   *
   */
  email?: string;
  /**
   * Owner's verified full name. Values are verified or provided by the wallet directly (if
   * supported) at the time of authorization or settlement. They cannot be set or mutated.
   *
   */
  name?: string;
  /**
   * Owner's verified shipping address. Values are verified or provided by the wallet
   * directly (if supported) at the time of authorization or settlement. They cannot be set
   * or mutated.
   *
   */
  shippingAddress?: Address;
}

export type PaymentMethodCardWalletSamsungPay = object;

export interface PaymentMethodCardWalletVisaCheckout {
  /**
   * Owner's verified billing address. Values are verified or provided by the wallet directly
   * (if supported) at the time of authorization or settlement. They cannot be set or
   * mutated.
   *
   */
  billingAddress?: Address;
  /**
   * Owner's verified email. Values are verified or provided by the wallet directly (if
   * supported) at the time of authorization or settlement. They cannot be set or mutated.
   *
   */
  email?: string;
  /**
   * Owner's verified full name. Values are verified or provided by the wallet directly (if
   * supported) at the time of authorization or settlement. They cannot be set or mutated.
   *
   */
  name?: string;
  /**
   * Owner's verified shipping address. Values are verified or provided by the wallet
   * directly (if supported) at the time of authorization or settlement. They cannot be set
   * or mutated.
   *
   */
  shippingAddress?: Address;
}

export interface PaymentMethodCardPresent {
  /**
   * Card brand. Can be amex, diners, discover, eftpos_au,
   * jcb, mastercard, unionpay, visa, or unknown.
   *
   */
  brand?: string;
  /**
   * The cardholder name as read from the card, in
   *
   *     ISO 7813
   *
   * format. May include
   * alphanumeric characters, special characters and first/last name separator (/). In
   * some cases, the cardholder name may not be available depending on how the issuer has
   * configured the card. Cardholder name is typically not available on swipe or contactless
   * payments, such as those made with Apple Pay and Google Pay.
   *
   */
  cardholderName?: string;
  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute
   * to get a sense of the international breakdown of cards you've collected.
   *
   */
  country?: string;
  /**
   * A high-level description of the type of cards issued in this range. (For internal use
   * only and not typically available in standard API requests.).
   *
   */
  description?: string;
  /**
   * Two-digit number representing the card's expiration month.
   *
   * @format int64
   */
  expMonth?: number;
  /**
   * Four-digit number representing the card's expiration year.
   *
   * @format int64
   */
  expYear?: number;
  /**
   * Uniquely identifies this particular card number. You can use this attribute to check
   * whether two customers who’ve signed up with you are using the same card number, for
   * example. For payment methods that tokenize card information (Apple Pay, Google Pay), the
   * tokenized number might be provided instead of the underlying card number.
   *
   *     As of May 1, 2021, card fingerprint in India for Connect changed to allow two
   *     fingerprints for the same card---one for India and one for the rest of the world.
   *
   * .
   *
   */
  fingerprint?: string;
  /**
   * Card funding type. Can be credit, debit, prepaid, or
   * unknown.
   *
   */
  funding?: string;
  /**
   * Issuer identification number of the card. (For internal use only and not typically
   * available in standard API requests.).
   *
   */
  iin?: string;
  /**
   * The name of the card's issuing bank. (For internal use only and not typically available
   * in standard API requests.).
   *
   */
  issuer?: string;
  /**
   * The last four digits of the card.
   *
   */
  last4?: string;
  /**
   * Contains information about card networks that can be used to process the payment.
   *
   */
  networks?: PaymentMethodCardPresentNetworks;
  /**
   * How card details were read in this transaction.
   * One of: contact_emv, contactless_emv, contactless_magstripe_mode,
   * magnetic_stripe_fallback, or magnetic_stripe_track2.
   *
   */
  readMethod?: string;
}

export interface PaymentMethodCardPresentNetworks {
  /**
   * All available networks for the card.
   *
   */
  available?: string[];
  /**
   * The preferred network for the card.
   *
   */
  preferred?: string;
}

export interface PaymentMethodCashapp {
  /**
   * A unique and immutable identifier assigned by Cash App to every buyer.
   *
   */
  buyerId?: string;
  /**
   * A public identifier for buyers using Cash App.
   *
   */
  cashtag?: string;
}

export type PaymentMethodCustomerBalance = object;

export interface PaymentMethodEps {
  /**
   * The customer's bank. Should be one of arzte_und_apotheker_bank,
   * austrian_anadi_bank_ag, bank_austria, bankhaus_carl_spangler,
   * bankhaus_schelhammer_und_schattera_ag, bawag_psk_ag, bks_bank_ag,
   * brull_kallmus_bank_ag, btv_vier_lander_bank,
   * capital_bank_grawe_gruppe_ag, deutsche_bank_ag, dolomitenbank,
   * easybank_ag, erste_bank_und_sparkassen,
   * hypo_alpeadriabank_international_ag,
   * hypo_noe_lb_fur_niederosterreich_u_wien,
   * hypo_oberosterreich_salzburg_steiermark, hypo_tirol_bank_ag,
   * hypo_vorarlberg_bank_ag, hypo_bank_burgenland_aktiengesellschaft,
   * marchfelder_bank, oberbank_ag, raiffeisen_bankengruppe_osterreich,
   * schoellerbank_ag, sparda_bank_wien, volksbank_gruppe,
   * volkskreditbank_ag, or vr_bank_braunau.
   * One of: arzte_und_apotheker_bank, austrian_anadi_bank_ag,
   * bank_austria, bankhaus_carl_spangler,
   * bankhaus_schelhammer_und_schattera_ag, bawag_psk_ag, bks_bank_ag,
   * brull_kallmus_bank_ag, btv_vier_lander_bank,
   * capital_bank_grawe_gruppe_ag, deutsche_bank_ag, dolomitenbank,
   * easybank_ag, erste_bank_und_sparkassen,
   * hypo_alpeadriabank_international_ag,
   * hypo_bank_burgenland_aktiengesellschaft,
   * hypo_noe_lb_fur_niederosterreich_u_wien,
   * hypo_oberosterreich_salzburg_steiermark, hypo_tirol_bank_ag,
   * hypo_vorarlberg_bank_ag, marchfelder_bank, oberbank_ag,
   * raiffeisen_bankengruppe_osterreich, schoellerbank_ag,
   * sparda_bank_wien, volksbank_gruppe, volkskreditbank_ag, or
   * vr_bank_braunau.
   *
   */
  bank?: string;
}

export interface PaymentMethodFpx {
  /**
   * Account holder type, if provided. Can be one of individual or company.
   * One of: company, or individual.
   *
   */
  accountHolderType?: string;
  /**
   * The customer's bank, if provided. Can be one of affin_bank, agrobank,
   * alliance_bank, ambank, bank_islam, bank_muamalat,
   * bank_rakyat, bsn, cimb, hong_leong_bank, hsbc,
   * kfh, maybank2u, ocbc, public_bank, rhb,
   * standard_chartered, uob, deutsche_bank, maybank2e,
   * pb_enterprise, or bank_of_china.
   * One of: affin_bank, agrobank, alliance_bank, ambank,
   * bank_islam, bank_muamalat, bank_of_china, bank_rakyat,
   * bsn, cimb, deutsche_bank, hong_leong_bank, hsbc,
   * kfh, maybank2e, maybank2u, ocbc, pb_enterprise,
   * public_bank, rhb, standard_chartered, or uob.
   *
   */
  bank?: string;
}

export type PaymentMethodGiropay = object;

export type PaymentMethodGrabpay = object;

export interface PaymentMethodIdeal {
  /**
   * The customer's bank, if provided. Can be one of abn_amro, asn_bank,
   * bunq, handelsbanken, ing, knab, moneyou, n26,
   * rabobank, regiobank, revolut, sns_bank, triodos_bank,
   * van_lanschot, or yoursafe.
   * One of: abn_amro, asn_bank, bunq, handelsbanken, ing,
   * knab, moneyou, n26, rabobank, regiobank,
   * revolut, sns_bank, triodos_bank, van_lanschot, or
   * yoursafe.
   *
   */
  bank?: string;
  /**
   * The Bank Identifier Code of the customer's bank, if the bank was provided.
   * One of: ABNANL2A, ASNBNL21, BITSNL2A, BUNQNL2A,
   * FVLBNL22, HANDNL2A, INGBNL2A, KNABNL2H, MOYONL21,
   * NTSBDEB1, RABONL2U, RBRBNL21, REVOIE23, REVOLT21,
   * SNSBNL2A, or TRIONL2U.
   *
   */
  bic?: string;
}

export interface PaymentMethodInteracPresent {
  /**
   * Card brand. Can be interac, mastercard or visa.
   *
   */
  brand?: string;
  /**
   * The cardholder name as read from the card, in
   *
   *     ISO 7813
   *
   * format. May include
   * alphanumeric characters, special characters and first/last name separator (/). In
   * some cases, the cardholder name may not be available depending on how the issuer has
   * configured the card. Cardholder name is typically not available on swipe or contactless
   * payments, such as those made with Apple Pay and Google Pay.
   *
   */
  cardholderName?: string;
  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute
   * to get a sense of the international breakdown of cards you've collected.
   *
   */
  country?: string;
  /**
   * A high-level description of the type of cards issued in this range. (For internal use
   * only and not typically available in standard API requests.).
   *
   */
  description?: string;
  /**
   * Two-digit number representing the card's expiration month.
   *
   * @format int64
   */
  expMonth?: number;
  /**
   * Four-digit number representing the card's expiration year.
   *
   * @format int64
   */
  expYear?: number;
  /**
   * Uniquely identifies this particular card number. You can use this attribute to check
   * whether two customers who’ve signed up with you are using the same card number, for
   * example. For payment methods that tokenize card information (Apple Pay, Google Pay), the
   * tokenized number might be provided instead of the underlying card number.
   *
   *     As of May 1, 2021, card fingerprint in India for Connect changed to allow two
   *     fingerprints for the same card---one for India and one for the rest of the world.
   *
   * .
   *
   */
  fingerprint?: string;
  /**
   * Card funding type. Can be credit, debit, prepaid, or
   * unknown.
   *
   */
  funding?: string;
  /**
   * Issuer identification number of the card. (For internal use only and not typically
   * available in standard API requests.).
   *
   */
  iin?: string;
  /**
   * The name of the card's issuing bank. (For internal use only and not typically available
   * in standard API requests.).
   *
   */
  issuer?: string;
  /**
   * The last four digits of the card.
   *
   */
  last4?: string;
  /**
   * Contains information about card networks that can be used to process the payment.
   *
   */
  networks?: PaymentMethodInteracPresentNetworks;
  /**
   * EMV tag 5F2D. Preferred languages specified by the integrated circuit chip.
   *
   */
  preferredLocales?: string[];
  /**
   * How card details were read in this transaction.
   * One of: contact_emv, contactless_emv, contactless_magstripe_mode,
   * magnetic_stripe_fallback, or magnetic_stripe_track2.
   *
   */
  readMethod?: string;
}

export interface PaymentMethodInteracPresentNetworks {
  /**
   * All available networks for the card.
   *
   */
  available?: string[];
  /**
   * The preferred network for the card.
   *
   */
  preferred?: string;
}

export interface PaymentMethodKlarna {
  /**
   * The customer's date of birth, if provided.
   *
   */
  dob?: PaymentMethodKlarnaDob;
}

export interface PaymentMethodKlarnaDob {
  /**
   * The day of birth, between 1 and 31.
   *
   * @format int64
   */
  day?: number | null;
  /**
   * The month of birth, between 1 and 12.
   *
   * @format int64
   */
  month?: number | null;
  /**
   * The four-digit year of birth.
   *
   * @format int64
   */
  year?: number | null;
}

export type PaymentMethodKonbini = object;

export interface PaymentMethodLink {
  /**
   * Account owner's email address.
   *
   */
  email?: string;
  /**
   * [Deprecated] This is a legacy parameter that no longer has any function.
   *
   */
  persistentToken?: string;
}

export type PaymentMethodOxxo = object;

export interface PaymentMethodP24 {
  /**
   * The customer's bank, if provided.
   * One of: alior_bank, bank_millennium, bank_nowy_bfg_sa,
   * bank_pekao_sa, banki_spbdzielcze, blik, bnp_paribas,
   * boz, citi_handlowy, credit_agricole, envelobank,
   * etransfer_pocztowy24, getin_bank, ideabank, ing,
   * inteligo, mbank_mtransfer, nest_przelew, noble_pay,
   * pbac_z_ipko, plus_bank, santander_przelew24,
   * tmobile_usbugi_bankowe, toyota_bank, or volkswagen_bank.
   *
   */
  bank?: string;
}

export type PaymentMethodPaynow = object;

export interface PaymentMethodPaypal {
  /**
   * Owner's email. Values are provided by PayPal directly (if supported) at the time of
   * authorization or settlement. They cannot be set or mutated.
   *
   */
  payerEmail?: string;
  /**
   * PayPal account PayerID. This identifier uniquely identifies the PayPal customer.
   *
   */
  payerId?: string;
}

export type PaymentMethodPix = object;

export type PaymentMethodPromptpay = object;

export interface PaymentMethodRadarOptions {
  /**
   * A Radar Session is a snapshot
   * of the browser metadata and device details that help Radar make more accurate
   * predictions on your payments.
   *
   */
  session?: string;
}

export type PaymentMethodRevolutPay = object;

export interface PaymentMethodSepaDebit {
  /**
   * Bank code of bank associated with the bank account.
   *
   */
  bankCode?: string;
  /**
   * Branch code of bank associated with the bank account.
   *
   */
  branchCode?: string;
  /**
   * Two-letter ISO code representing the country the bank account is located in.
   *
   */
  country?: string;
  /**
   * Uniquely identifies this particular bank account. You can use this attribute to check
   * whether two bank accounts are the same.
   *
   */
  fingerprint?: string;
  /**
   * Information about the object that generated this PaymentMethod.
   *
   */
  generatedFrom?: PaymentMethodSepaDebitGeneratedFrom;
  /**
   * Last four characters of the IBAN.
   *
   */
  last4?: string;
}

export interface PaymentMethodSepaDebitGeneratedFrom {
  /**
   * (ID of the Charge)
   * The ID of the Charge that generated this PaymentMethod, if any.
   *
   */
  chargeId?: string;
  /**
   * (ID of the SetupAttempt)
   * The ID of the SetupAttempt that generated this PaymentMethod, if any.
   *
   */
  setupAttemptId?: string;
}

export interface PaymentMethodSofort {
  /**
   * Two-letter ISO code representing the country the bank account is located in.
   *
   */
  country?: string;
}

export interface PaymentMethodUsBankAccount {
  /**
   * Account holder type: individual or company.
   * One of: company, or individual.
   *
   */
  accountHolderType?: string;
  /**
   * Account type: checkings or savings. Defaults to checking if omitted.
   * One of: checking, or savings.
   *
   */
  accountType?: string;
  /**
   * The name of the bank.
   *
   */
  bankName?: string;
  /**
   * The ID of the Financial Connections Account used to create the payment method.
   *
   */
  financialConnectionsAccount?: string;
  /**
   * Uniquely identifies this particular bank account. You can use this attribute to check
   * whether two bank accounts are the same.
   *
   */
  fingerprint?: string;
  /**
   * Last four digits of the bank account number.
   *
   */
  last4?: string;
  /**
   * Contains information about US bank account networks that can be used.
   *
   */
  networks?: PaymentMethodUsBankAccountNetworks;
  /**
   * Routing number of the bank account.
   *
   */
  routingNumber?: string;
  /**
   * Contains information about the future reusability of this PaymentMethod.
   *
   */
  statusDetails?: PaymentMethodUsBankAccountStatusDetails;
}

export interface PaymentMethodUsBankAccountNetworks {
  /**
   * The preferred network.
   *
   */
  preferred?: string;
  /**
   * All supported networks.
   * One of: ach, or us_domestic_wire.
   *
   */
  supported?: string[];
}

export interface PaymentMethodUsBankAccountStatusDetails {
  blocked?: PaymentMethodUsBankAccountStatusDetailsBlocked;
}

export interface PaymentMethodUsBankAccountStatusDetailsBlocked {
  /**
   * The ACH network code that resulted in this block.
   * One of: R02, R03, R04, R05, R07, R08,
   * R10, R11, R16, R20, R29, or R31.
   *
   */
  networkCode?: string;
  /**
   * The reason why this PaymentMethod's fingerprint has been blocked.
   * One of: bank_account_closed, bank_account_frozen,
   * bank_account_invalid_details, bank_account_restricted,
   * bank_account_unusable, or debit_not_authorized.
   *
   */
  reason?: string;
}

export type PaymentMethodWechatPay = object;

export type PaymentMethodZip = object;

export type TokenPagedResultOfSubscriptionSchedule =
  PagedResultOfSubscriptionSchedule & {
    hasPrevious?: boolean;
    hasNext?: boolean;
    /** @format int32 */
    page?: number;
    /** @format int32 */
    total?: number;
    token?: string | null;
  };

export type PagedResultOfSubscriptionSchedule = PagedResultMetadata & {
  data?: SubscriptionSchedule[] | null;
};

/** The subscription schedule representing a change schedule for a subscription */
export interface SubscriptionSchedule {
  /** The schedule ID */
  id?: string;
  /** The phases of the schedule. SubscriptionSchedulePhase for more information */
  phases?: SubscriptionSchedulePhase[];
}

/** A phase of a schedule. Represents a period of time that a subscription will be in a specific state */
export interface SubscriptionSchedulePhase {
  /** A list of SubscriptionSchedulePhaseItem items representing the subscription items in this phase */
  items?: SubscriptionSchedulePhaseItem[];
  /**
   * The date that this phase starts/started
   * @format date-time
   */
  startDate?: string;
  /**
   * The date that this phase ends/ended
   * @format date-time
   */
  endDate?: string;
}

export interface SubscriptionSchedulePhaseItem {
  /** @format int64 */
  quantity?: number;
}

export type TokenPagedResultOfSubscription = PagedResultOfSubscription & {
  hasPrevious?: boolean;
  hasNext?: boolean;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  total?: number;
  token?: string | null;
};

export type PagedResultOfSubscription = PagedResultMetadata & {
  data?: Subscription[] | null;
};

/**
 * Represents a subscription.
 *
 */
export interface Subscription {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * If the subscription has been canceled, the date of that cancellation. If the
   * subscription was canceled with cancel_at_period_end, canceled_at will
   * reflect the time of the most recent update request, not the end of the subscription
   * period when the subscription is automatically moved to a canceled state.
   *
   * @format date-time
   */
  canceledAt?: string | null;
  /** If the subscription will be cancelled at the end of the current billing period  */
  cancelAtPeriodEnd?: boolean;
  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   *
   * @format date-time
   */
  created?: string;
  /**
   * End of the current period that the subscription has been invoiced for. At the end of
   * this period, a new invoice will be created.
   *
   * @format date-time
   */
  currentPeriodEnd?: string;
  /**
   * Start of the current period that the subscription has been invoiced for.
   *
   * @format date-time
   */
  currentPeriodStart?: string;
  /**
   * (ID of the Customer)
   * ID of the customer who owns the subscription.
   *
   */
  customerId?: string;
  /** ID of the schedule associated with this subscription. Null if there is no schedule */
  scheduleId?: string | null;
  /**
   * The subscription's description, meant to be displayable to the customer. Use this field
   * to optionally store an explanation of the subscription for rendering in Stripe surfaces
   * and certain local payment methods UIs.
   *
   */
  description?: string;
  /**
   * If the subscription has ended, the date the subscription ended.
   *
   * @format date-time
   */
  endedAt?: string | null;
  /**
   * List of subscription items, each with an attached price.
   *
   */
  items?: SubscriptionItem[];
  /**
   * Date when the subscription was first created. The date might differ from the
   * created date due to backdating.
   *
   * @format date-time
   */
  startDate?: string;
  /**
   * Possible values are incomplete, incomplete_expired, trialing,
   * active, past_due, canceled, or unpaid.
   * For collection_method=charge_automatically a subscription moves into
   * incomplete if the initial payment attempt fails. A subscription in this state can
   * only have metadata and default_source updated. Once the first invoice is paid, the
   * subscription moves into an active state. If the first invoice is not paid within
   * 23 hours, the subscription transitions to incomplete_expired. This is a terminal
   * state, the open invoice will be voided and no further invoices will be generated.
   * A subscription that is currently in a trial period is trialing and moves to
   * active when the trial period is over.
   * If subscription collection_method=charge_automatically, it becomes
   * past_due when payment is required but cannot be paid (due to failed payment or
   * awaiting additional user actions). Once Stripe has exhausted all payment retry attempts,
   * the subscription will become canceled or unpaid (depending on your
   * subscriptions settings).
   * If subscription collection_method=send_invoice it becomes past_due when
   * its invoice is not paid by the due date, and canceled or unpaid if it is
   * still not paid by an additional deadline after that. Note that when a subscription has a
   * status of unpaid, no subsequent invoices will be attempted (invoices will be
   * created, but then immediately automatically closed). After receiving updated payment
   * information from a customer, you may choose to reopen and pay their closed invoices.
   * One of: active, canceled, incomplete, incomplete_expired,
   * past_due, paused, trialing, or unpaid.
   *
   */
  status?: string;
  /**
   * If specified,
   *
   *     pending updates
   *
   * that will be applied to the subscription once the latest_invoice has been paid.
   *
   */
  pendingUpdate?: SubscriptionPendingUpdate | null;
}

export interface SubscriptionItem {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * The quantity of the plan
   * to which the customer should be subscribed.
   *
   * @format int64
   */
  quantity?: number;
  /**
   * Prices define the unit cost, currency, and (optional) billing cycle for both recurring
   * and one-time purchases of products.
   *
   *     Products
   *
   * help you track inventory or
   * provisioning, and prices help you track payment terms. Different physical goods or
   * levels of service should be represented by products, and pricing options should be
   * represented by prices. This approach lets you change prices without having to change
   * your provisioning scheme.
   * For example, you might have a single "gold" product that has prices for $10/month,
   * $100/year, and €9 once.
   * Related guides:
   *
   *     Set up a
   *     subscription
   *
   * ,
   *
   *     create an
   *     invoice
   *
   * , and more about
   *
   *     products and prices
   *
   * .
   *
   */
  price?: Price;
}

/**
 * Prices define the unit cost, currency, and (optional) billing cycle for both recurring
 * and one-time purchases of products.
 *
 *     Products
 *
 * help you track inventory or
 * provisioning, and prices help you track payment terms. Different physical goods or
 * levels of service should be represented by products, and pricing options should be
 * represented by prices. This approach lets you change prices without having to change
 * your provisioning scheme.
 * For example, you might have a single "gold" product that has prices for $10/month,
 * $100/year, and €9 once.
 * Related guides:
 *
 *     Set up a
 *     subscription
 *
 * ,
 *
 *     create an
 *     invoice
 *
 * , and more about
 *
 *     products and prices
 *
 * .
 *
 */
export interface Price {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * Describes how to compute the price per period. Either per_unit or tiered.
   * per_unit indicates that the fixed amount (specified in unit_amount or
   * unit_amount_decimal) will be charged per unit in quantity (for prices with
   * usage_type=licensed), or per unit of total usage (for prices with
   * usage_type=metered). tiered indicates that the unit pricing will be
   * computed using a tiering strategy as defined using the tiers and
   * tiers_mode attributes.
   * One of: per_unit, or tiered.
   *
   */
  billingScheme?: string;
  /**
   * Three-letter
   *
   *     ISO currency
   *     code
   *
   * , in lowercase. Must be a
   *
   *     supported
   *     currency
   *
   * .
   *
   */
  currency?: string;
  /**
   * Prices defined in each available currency option. Each key must be a three-letter
   *
   *     ISO currency code
   *
   * and a
   *
   *     supported currency
   *
   * .
   *
   */
  currencyOptions?: Record<string, PriceCurrencyOptions>;
  /**
   * A lookup key used to retrieve prices dynamically from a static string. This may be up to
   * 200 characters.
   *
   */
  lookupKey?: string;
  productId?: string;
  /**
   * The recurring components of a price such as interval and usage_type.
   *
   */
  recurring?: PriceRecurring;
  /**
   * Only required if a
   *
   *     default
   *     tax behavior
   *
   * was not provided in the Stripe Tax settings. Specifies whether the
   * price is considered inclusive of taxes or exclusive of taxes. One of inclusive,
   * exclusive, or unspecified. Once specified as either inclusive or
   * exclusive, it cannot be changed.
   * One of: exclusive, inclusive, or unspecified.
   *
   */
  taxBehavior?: string;
  /**
   * Each element represents a pricing tier. This parameter requires billing_scheme to
   * be set to tiered. See also the documentation for billing_scheme.
   *
   */
  tiers?: PriceTier[];
  /**
   * Defines if the tiering price should be graduated or volume based. In
   * volume-based tiering, the maximum quantity within a period determines the per
   * unit price. In graduated tiering, pricing can change as the quantity grows.
   * One of: graduated, or volume.
   *
   */
  tiersMode?: string;
  /**
   * One of one_time or recurring depending on whether the price is for a?
   * one-time purchase or a recurring (subscription) purchase.
   * One of: one_time, or recurring.
   *
   */
  type?: string;
  /**
   * The unit amount in cents (or local equivalent) to be charged, represented as a decimal
   * string with at most 12 decimal places. Only set if billing_scheme=per_unit.
   *
   * @format decimal
   */
  unitAmountDecimal?: number | null;
  /**
   * Minimum quantity that can be purchased when using this Price.
   *
   * @format int32
   */
  minimumQuantity?: number;
  /**
   * Maximum quantity that can be purchased when using this Price.
   *
   * @format int32
   */
  maximumQuantity?: number;
}

export interface PriceCurrencyOptions {
  /**
   * When set, provides configuration for the amount to be adjusted by the customer during
   * Checkout Sessions and Payment Links.
   *
   */
  customUnitAmount?: PriceCurrencyOptionsCustomUnitAmount;
  /**
   * Only required if a
   *
   *     default
   *     tax behavior
   *
   * was not provided in the Stripe Tax settings. Specifies whether the
   * price is considered inclusive of taxes or exclusive of taxes. One of inclusive,
   * exclusive, or unspecified. Once specified as either inclusive or
   * exclusive, it cannot be changed.
   * One of: exclusive, inclusive, or unspecified.
   *
   */
  taxBehavior?: string;
  /**
   * Each element represents a pricing tier. This parameter requires billing_scheme to
   * be set to tiered. See also the documentation for billing_scheme.
   *
   */
  tiers?: PriceCurrencyOptionsTier[];
  /**
   * The unit amount in cents (or local equivalent) to be charged, represented as a decimal
   * string with at most 12 decimal places. Only set if billing_scheme=per_unit.
   *
   * @format decimal
   */
  unitAmountDecimal?: number | null;
}

export interface PriceCurrencyOptionsCustomUnitAmount {
  /**
   * The maximum unit amount the customer can specify for this item.
   *
   * @format int64
   */
  maximum?: number | null;
  /**
   * The minimum unit amount the customer can specify for this item. Must be at least the
   * minimum charge amount.
   *
   * @format int64
   */
  minimum?: number | null;
  /**
   * The starting unit amount which can be updated by the customer.
   *
   * @format int64
   */
  preset?: number | null;
}

export interface PriceCurrencyOptionsTier {
  /** @format int64 */
  flatAmount?: number | null;
  /** @format decimal */
  flatAmountDecimal?: number | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format decimal */
  unitAmountDecimal?: number | null;
  /** @format int64 */
  upTo?: number | null;
}

export interface PriceRecurring {
  /**
   * Specifies a usage aggregation strategy for prices of usage_type=metered. Allowed
   * values are sum for summing up all usage during a period,
   * last_during_period for using the last usage record reported within a period,
   * last_ever for using the last usage record ever (across period bounds) or
   * max which uses the usage record with the maximum reported usage during a period.
   * Defaults to sum.
   * One of: last_during_period, last_ever, max, or sum.
   *
   */
  aggregateUsage?: string;
  /**
   * The frequency at which a subscription is billed. One of day, week,
   * month or year.
   * One of: day, month, week, or year.
   *
   */
  interval?: string;
  /**
   * The number of intervals (specified in the interval attribute) between
   * subscription billings. For example, interval=month and interval_count=3
   * bills every 3 months.
   *
   * @format int64
   */
  intervalCount?: number;
  /**
   * Default number of trial days when subscribing a customer to this price using
   *
   *     trial_from_plan=true
   *
   * .
   *
   * @format int64
   */
  trialPeriodDays?: number | null;
  /**
   * Configures how the quantity per period should be determined. Can be either
   * metered or licensed. licensed automatically bills the
   * quantity set when adding it to a subscription. metered aggregates the
   * total usage based on usage records. Defaults to licensed.
   * One of: licensed, or metered.
   *
   */
  usageType?: string;
}

export interface PriceTier {
  /**
   * Same as flat_amount, but contains a decimal value with at most 12 decimal places.
   *
   * @format decimal
   */
  flatAmountDecimal?: number | null;
  /**
   * Same as unit_amount, but contains a decimal value with at most 12 decimal places.
   *
   * @format decimal
   */
  unitAmountDecimal?: number | null;
  /**
   * Up to and including to this quantity will be contained in the tier.
   *
   * @format int64
   */
  upTo?: number | null;
}

export interface SubscriptionPendingUpdate {
  /**
   * The point after which the changes reflected by this update will be discarded and no
   * longer applied.
   *
   * @format date-time
   */
  expiresAt?: string;
  /**
   * List of subscription items, each with an attached plan, that will be set if the update
   * is applied.
   *
   */
  subscriptionItems?: SubscriptionItem[];
}

export interface ResumeSubscriptionRequest {
  /** @minLength 1 */
  idempotencyKey: string;
}

/**
 * Request model for updating a subscription.
 *
 */
export type UpdateSubscriptionRequest = IdempotentRequest & {
  /** @format int32 */
  quantity: number;
  /**
   * Subscription tiers.
   *
   */
  subscriptionTier: SubscriptionTier;
  /**
   * @format date-time
   * @minLength 1
   */
  prorationDate: string;
  billingInterval?: BillingInterval | null;
  downgradeReason?: string | null;
};

/**
 * Frequency at which an item is billed.
 *
 */
export enum BillingInterval {
  Month = "Month",
  Year = "Year",
}

/**
 * Base class for requests that implement idempotent behavior.
 *
 */
export interface IdempotentRequest {
  /**
   * Idempotency key used by Stripe.
   *
   * @minLength 1
   */
  idempotencyKey: string;
}

/**
 * Request model for updating a subscription item.
 *
 */
export type UpdateSubscriptionItemRequest = IdempotentRequest & {
  /** @format int32 */
  quantity: number;
  /**
   * @format date-time
   * @minLength 1
   */
  prorationDate: string;
};

/**
 * Request model for previewing an update to a subscription.
 *
 */
export type PreviewUpdateSubscriptionRequest = UpdateSubscriptionRequest & {
  /**
   * Whether or not to generate an upcoming invoice representing a proration for the current billing period or an invoice
   * for a new billing period.
   */
  isProration?: boolean;
};

/**
 * Request model for previewing an update to a subscription.
 *
 */
export type PreviewUpdateSubscriptionItemRequest =
  UpdateSubscriptionItemRequest & {
    /**
     * Whether or not to generate an upcoming invoice representing a proration for the current billing period or an invoice
     * for a new billing period.
     */
    isProration?: boolean;
  };

/**
 * Request model for creating a trial subscription.
 *
 */
export type CreateTrialRequest = IdempotentRequest & {
  trialItems?: TrialSubscriptionItem[];
};

/**
 * Represents an item being trialed.
 *
 */
export interface TrialSubscriptionItem {
  /**
   * The ID of the Price object for the product being trialed. Do not confuse this for the
   * LookupKey.
   *
   */
  priceId?: string;
}

export type TokenPagedResultOfProduct = PagedResultOfProduct & {
  hasPrevious?: boolean;
  hasNext?: boolean;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  total?: number;
  token?: string | null;
};

export type PagedResultOfProduct = PagedResultMetadata & {
  data?: Product[] | null;
};

/**
 * Products describe the specific goods or services you offer to your customers. For
 * example, you might offer a Standard and Premium version of your goods or service; each
 * version would be a separate Product. They can be used in conjunction with
 *
 *     Prices
 *
 * to configure pricing in Payment
 * Links, Checkout, and Subscriptions.
 * Related guides:
 *
 *     Set up a
 *     subscription
 *
 * ,
 *
 *     share a Payment
 *     Link
 *
 * ,
 *
 *     accept
 *     payments with Checkout
 *
 * , and more about
 *
 *     Products and Prices
 *
 * .
 *
 */
export interface Product {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * Default Price for this product.
   *
   */
  defaultPrice?: Price;
  /**
   * The product's description, meant to be displayable to the customer. Use this field to
   * optionally store a long form explanation of the product being sold for your own
   * rendering purposes.
   *
   */
  description?: string;
  /**
   * A list of up to 15 features for this product. These are displayed in
   *
   *     pricing tables
   *
   * .
   *
   */
  features?: ProductMarketingFeature[];
  /**
   * A list of up to 8 URLs of images for this product, meant to be displayable to the
   * customer.
   *
   */
  images?: string[];
  /**
   * The product's name, meant to be displayable to the customer.
   *
   */
  name?: string;
  /**
   * Extra information about a product which will appear on your customer's credit card
   * statement. In the case that multiple products are billed at once, the first statement
   * descriptor will be used.
   *
   */
  statementDescriptor?: string;
  /**
   * The type of the product. The product is either of type good, which is eligible
   * for use with Orders and SKUs, or service, which is eligible for use with
   * Subscriptions and Plans.
   * One of: good, or service.
   *
   */
  type?: string;
  /**
   * A label that represents units of this product. When set, this will be included in
   * customers' receipts, invoices, Checkout, and the customer portal.
   *
   */
  unitLabel?: string;
  /**
   * A tax code.
   *
   */
  taxCode?: TaxCode;
  /**
   * If this product represents a subscription plan, this is that plan's tier.
   *
   */
  subscriptionTier?: SubscriptionTier | null;
  /**
   * true if this product can be purchased by a user on their own, and false if this product can only
   * be purchased by contacting us.
   *
   */
  isSelfServe?: boolean;
  /**
   * Determines how products should be ordered by clients. Products with a lower value should be positioned
   * before/earlier than products with a higher value.
   *
   * @format int32
   */
  order?: number;
  /** true if this product should have a 'Most Popular' banner/ribbon, otherwise false */
  isMostPopular?: boolean;
}

export interface ProductMarketingFeature {
  name?: string;
}

/**
 * Tax codes classify goods and
 * services for tax purposes.
 *
 */
export interface TaxCode {
  /**
   * Unique identifier for the object.
   *
   */
  id?: string;
  /**
   * A detailed description of which types of products the tax code represents.
   *
   */
  description?: string;
  /**
   * A short name for the tax code.
   *
   */
  name?: string;
}

export type TokenPagedResultOfPrice = PagedResultOfPrice & {
  hasPrevious?: boolean;
  hasNext?: boolean;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  total?: number;
  token?: string | null;
};

export type PagedResultOfPrice = PagedResultMetadata & {
  data?: Price[] | null;
};

/**
 * Client details for Stripe's CheckoutSession flow
 *
 */
export interface ClientCheckoutSessionDetails {
  /**
   * URL where the client should be sent to complete payment.
   *
   */
  url?: string;
}

/**
 * Request model for creating a Stripe checkout session.
 *
 */
export type CreateStripeCheckoutSessionRequest = IdempotentRequest & {
  companyId?: string;
  checkoutItems?: CheckoutItem[];
  /**
   * The user will be redirected here after a successful payment. For information on how to customize this page
   * see https://stripe.com/docs/payments/checkout/custom-success-page
   *
   */
  successUrl?: string;
  /**
   * Optional. If specified a back button will be shown by Stripe which can be used to cancel payment and redirect
   * users to this URL.
   *
   */
  cancelUrl?: string | null;
};

/**
 * Represents an item being purchased through a checkout session.
 *
 */
export interface CheckoutItem {
  /**
   * The ID of the Price object for the product being purchased. Do not confuse this for the
   * LookupKey.
   *
   */
  priceId?: string;
}

/**
 * Client details for Stripe's SetupIntent flow
 *
 */
export interface ClientSetupIntentDetails {
  /**
   * Client secret used by Stripe client-side SDK.
   *
   */
  clientSecret?: string;
}

/**
 * Request model for creating a Stripe setup intent.
 *
 */
export type CreateSetupIntentRequest = IdempotentRequest & {
  companyId?: string;
};

/**
 * Client details for Stripe's CustomerPortalSessions flow
 *
 */
export interface ClientCustomerPortalSessionDetails {
  /**
   * URL where the client should be sent to complete payment.
   *
   */
  url?: string;
}

/**
 * Request model for creating a Stripe customer portla session.
 *
 */
export type CreateStripeCustomerPortalSessionRequest = IdempotentRequest & {
  companyId?: string;
  returnUrl?: string;
};
