import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
})
export class AccountsComponent {
  @Input()
  accounts = [];

  @Input()
  editMode: boolean;

  @Output()
  selected = new EventEmitter<string>();

  @Output()
  delete = new EventEmitter<string>();
}
