import { MatDialogConfig } from "@angular/material/dialog";

export interface ConfigOptions<T>
  extends Omit<
    MatDialogConfig<T>,
    "role" | "restoreFocus" | "data" | "panelClasses"
  > {
  disableMatPanelReset?: boolean;
  panelClasses?: string[];
  disableClose?: boolean;
}

export const createDialogConfig = <T>(
  data?: T,
  options?: ConfigOptions<T> | null
): MatDialogConfig<T> => {
  const config: MatDialogConfig<T> = {
    ...options,
    role: "dialog",
    restoreFocus: true,
    data,
  };

  if (!options?.disableMatPanelReset) {
    config.panelClass = ["mat-panel-reset", ...(options?.panelClasses ?? [])];
  } else if (options?.panelClasses) {
    config.panelClass = options.panelClasses;
  }

  return config;
};
