import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@env";
import { AlertComponent } from "@modules/core/components";
import { retry } from "rxjs/operators";

@Component({
  selector: "app-consent-picker",
  templateUrl: "./consent-picker.component.html",
  styleUrls: ["./consent-picker.component.scss"],
})
export class ConsentPickerComponent implements OnInit {
  consents: Array<any> = [];
  showSpinner = true;
  @Input() patientID = null;
  minDays = "(?)";

  constructor(
    private http: HttpClient,
    private ref: MatDialogRef<ConsentPickerComponent>,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.setMinDays();
    this.doSearch();
  }

  setMinDays() {
    const path = `${environment.celoApiEndpoint}/api/Settings`;
    this.http
      .get<any>(path)
      .pipe(retry(3))
      .subscribe((data) => {
        this.minDays = data.consentFormValidDays.toString();
      });
  }

  doSearch() {
    this.showSpinner = true;

    const path = `${environment.celoApiEndpoint}/api/Consents`;
    const params = new HttpParams()
      .set("Page", "0")
      .set("PageSize", "20")
      .set("Uid", this.patientID);
    //could also use CreatedBy param

    this.http
      .get<any>(path, { params })
      .pipe(retry(3))
      .subscribe({
        next: (data) => {
          this.showSpinner = false;
          this.consents = data.data.filter((consent: any) => {
            const now = new Date();
            const vb = new Date(consent.validBy);
            return vb >= now;
          });
        },
        error: (err) => {
          this.showSpinner = false;
          AlertComponent.openErrorDialog(
            this.matDialog,
            "Error loading consent forms"
          );
        },
      });
  }

  private select(consent: any) {
    this.ref.close(consent);
  }
}
