import { Injectable, OnDestroy } from "@angular/core";
import { CompanyModel, SubscriptionTier } from "@types";
import { isNumber, SubscriptionContainer } from "@utils";
import { BehaviorSubject } from "rxjs";
import { CompaniesService } from "./companies.service";

const dismissBannerDurationInDays = 30;
const upgradeBannerStorageKey = "upgrade-banner-dismissed-to";

const millisecondsInADay = 86400000;

@Injectable({
  providedIn: "root",
})
export class UpgradeBannerService implements OnDestroy {
  private isBannerDismissedSubject = new BehaviorSubject<boolean>(true);
  public isBannerDismissed$ = this.isBannerDismissedSubject.asObservable();

  // This is to support elements that are displayed regardless of whether the upgrade banner has been dismissed
  private isAllowedToDisplaySubject = new BehaviorSubject<boolean>(false);
  public isAllowedToDisplay$ = this.isAllowedToDisplaySubject.asObservable();

  private subscriptions = new SubscriptionContainer();

  public constructor(private companiesService: CompaniesService) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setIsAllowedToDisplay(isAllowedToDisplay: boolean) {
    this.isAllowedToDisplaySubject.next(isAllowedToDisplay);
  }

  public updateIsBannerDismissed() {
    const companies: CompanyModel[] = [];

    const isDismissed = this.getIsBannerDismissed();
    if (!isDismissed) {
      const companiesSubscription = this.companiesService
        .getCompaniesV1({
          fetchAll: true,
        })
        .subscribe({
          next: (page) => {
            companies.push(...page.data);
          },
          complete: () => {
            const isDismissedWhileFetching = this.getIsBannerDismissed();
            const isPaidUser = companies.some(
              (c) => c.plan !== SubscriptionTier.Free
            );
            this.isBannerDismissedSubject.next(
              isDismissedWhileFetching || isPaidUser
            );
          },
        });
      this.subscriptions.add(companiesSubscription);
    }
  }

  private getIsBannerDismissed(): boolean {
    const dismissedUntil = localStorage.getItem(upgradeBannerStorageKey);
    if (!dismissedUntil) return false;

    const dismissedUntilTime = Number(dismissedUntil);
    if (!isNumber(dismissedUntilTime)) return false;

    return Date.now() < dismissedUntilTime;
  }

  public dismiss() {
    const offset = dismissBannerDurationInDays * millisecondsInADay;
    const time = Date.now() + offset;
    localStorage.setItem(upgradeBannerStorageKey, `${time}`);
    this.isBannerDismissedSubject.next(true);
  }
}
