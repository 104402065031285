import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertComponent } from "@modules/core/components";
import { UpgradePromptDialogComponent } from "@modules/shared/upgrade-prompt-dialog/upgrade-prompt-dialog.component";
import {
  UserPickerService,
  UserPickerSubmitCallback,
} from "@modules/user-picker/user-picker.service";
import { Observable, throwError } from "rxjs";
import { map, tap, toArray } from "rxjs/operators";
import {
  ContactModel,
  FeatureState,
  Team,
  TeamMember,
  TeamMemberRole,
  UserWorkspaceModel,
} from "types";
import { SubscriptionContainer } from "utils";
import { ConversationService } from "../../core";
import {
  AccountService,
  ConversationsService,
  TeamsService,
} from "../../core/services";
import { EditRoleDetailsDialogComponent } from "../edit-role-details-dialog/edit-role-details-dialog.component";
import { toMap } from "./../../../../utils/array-utils";
import { CompaniesService } from "./../../core/services/companies.service";
import { UserService } from "./../../core/services/user.service";
import { UserSelectionList } from "./../../shared/basic-user-selection-list/basic-user-selection-list.component";
import {
  RoleMemberPopupMenuAction,
  RoleMemberPopupMenuActionType,
} from "./../role-member-popup-menu/role-member-popup-menu.component";
import { TeamMemberWithDepartment } from "./../roles-members-list/roles-members-list.component";
import { RoleDetailsService } from "./role-details.service";

@Component({
  selector: "app-role-details",
  templateUrl: "./role-details.component.html",
  styleUrls: ["./role-details.component.scss"],
  providers: [RoleDetailsService],
})
export class RoleDetailsComponent implements OnDestroy, OnInit {
  @Input() public set team(team: Team | null) {
    this._team = team;

    if (team?.workspace) {
      this.updateSortedTeamMembers();
      this.updateWorkspaceId();
    } else {
      this.isLoading = false;
    }

    if (team) {
      this.isCurrentUserAdmin = this.teamsService.isCurrentUserAdmin(team);
      this.isOnCall = this.teamsService.isCurrentUserOnCall(team);
      this.isMember = this.teamsService.isCurrentUserMember(team);
    } else {
      this.sortedTeamMembers = [];
      this.isCurrentUserAdmin = false;
      this.isOnCall = false;
      this.isMember = false;
    }
  }

  public get team(): Team | null {
    return this._team;
  }

  private _workspaceId: string = "";

  @Input() public set workspaceId(workspaceId: string) {
    if (workspaceId === this._workspaceId) return;

    this._workspaceId = workspaceId;

    const getUserWorkspaceSubscription = this.userService
      .getUserWorkspace(workspaceId)
      .subscribe({
        next: (workspace) => (this.userWorkspace = workspace),
      });
    this.workspaceContactsMap = null;
    const getCompanyContactsSubscription = this.companiesService
      .getContacts({
        companyId: workspaceId,
        includeSelf: true,
        fetchAll: true,
      })
      .pipe(
        toArray(),
        map((pages) => pages.flatMap((page) => page.data ?? []))
      )
      .subscribe({
        next: (contacts) => {
          this.workspaceContactsMap = toMap(
            contacts,
            (contact) => contact.userId,
            (contact) => contact
          );
          this.updateSortedTeamMembers();
        },
      });

    this.subscriptionContainer.add(getUserWorkspaceSubscription);
    this.subscriptionContainer.add(getCompanyContactsSubscription);
  }

  public get workspaceId(): string {
    return this._workspaceId;
  }

  @Input() public marginTop: string | null = null;
  @Input() public hidePrimaryAction: boolean | null = null;
  @Input() public membersListMaxHeight: string | null = null;

  @Output() public showProfile: EventEmitter<TeamMember> = new EventEmitter();
  @Output() public updateTeam: EventEmitter<Team> = new EventEmitter();
  @Output() public leaveTeam: EventEmitter<Team> = new EventEmitter();

  public workspaceContactsMap: Map<string, ContactModel> | null = null;
  public userWorkspace: UserWorkspaceModel | null = null;
  public isCurrentUserAdmin: boolean = false;
  public isOnCall: boolean = false;
  public isMember: boolean = false;
  public sortedTeamMembers: TeamMemberWithDepartment[] = [];
  public isLoading: boolean = true;

  private _team: Team | null = null;
  private subscriptionContainer: SubscriptionContainer =
    new SubscriptionContainer();

  public constructor(
    private teamsService: TeamsService,
    private roleDetailsService: RoleDetailsService,
    private conversationService: ConversationService,
    private conversationsService: ConversationsService,
    private companiesService: CompaniesService,
    private userService: UserService,
    private router: Router,
    private userPickerService: UserPickerService,
    private matDialog: MatDialog,
    private accountService: AccountService
  ) {}

  public ngOnInit(): void {
    this.roleDetailsService.popupMenuAction.subscribe({
      next: (action) => this.handlePopupMenuAction(action),
    });
  }

  public ngOnDestroy(): void {
    this.subscriptionContainer.unsubscribe();
  }

  private showUpgradePromptDialogIfTeamsIsUpgradable() {
    this.accountService.userLimits$.subscribe({
      next: (userLimits) => {
        if (userLimits?.teams !== FeatureState.Upgradeable) return;
        UpgradePromptDialogComponent.openDialog(this.matDialog);
      },
    });
  }

  public updateWorkspaceId() {
    if (!this.workspaceId && this.team?.workspace?.id) {
      this.workspaceId = this.team.workspace.id;
    }
  }

  public handleClick() {
    if (!this.team?.id) return;
    if (this.isMember) {
      this.router.navigate(["roles", this.team.id]);
    } else {
      this.conversationsService.createTeamChat(this.team.id).subscribe({
        next: (conversation) => {
          if (!conversation?.id) throw new Error("Invalid conversation id");
          this.router.navigate(["conversations", conversation.id, "messages"]);
        },
      });
    }
  }

  public handleAddMembers() {
    const memberUserIds = this.sortedTeamMembers
      .map((member) => member.user?.userId ?? "")
      .filter((userId) => userId.length > 0);

    this.userPickerService
      .openWorkspaceUserPicker({
        workspaceId: this.workspaceId,
        initiallySelectedUserIds: memberUserIds,
        disabledUserIds: memberUserIds,
        // Typescript bug? - unable to assign a method with non-void return to a function with a void return type
        submitCallback: this.handleSaveMembers.bind(
          this
        ) as unknown as UserPickerSubmitCallback,
        header: "Add New Members",
        subheader: this.userWorkspace?.workspace?.name,
        searchPlaceholder: "Search workspace",
        selectedHeader: "Members",
        submitButtonText: "Add to Role",
        selectedQuantityLabels: {
          zero: "members",
          one: "member",
          plural: "members",
        },
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result?.isSubmitted) return;
          this.showUpgradePromptDialogIfTeamsIsUpgradable();
        },
      });
  }

  private handleSaveMembers(users: UserSelectionList): Observable<Team> {
    if (!this.team?.id) return throwError(new Error("No `teamId` specified"));
    const userIds = users
      .filter((user) => !user.isDisabled)
      .map((user) => user.id);
    return this.teamsService
      .addMembers({
        teamId: this.team.id,
        userIds,
      })
      .pipe(
        tap({
          next: (team) => this.updateTeam.emit(team),
        })
      );
  }

  public handleSave(team: Team) {
    this.updateTeam.emit(team);
  }

  public handleLeaveClick() {
    if (!this.team) return;

    const activeMembers = this.teamsService.filterOutLeftTeamMembers(
      this.team?.members ?? []
    );

    if (activeMembers.length === 1) {
      AlertComponent.openDialog(this.matDialog, {
        title: "Leave this role",
        message:
          "As you are the last member of this role, your colleagues can no longer message the role.",
        acceptOnly: false,
        acceptButtonText: "Leave",
        closeButtonText: "Cancel",
        reverseButtonOrder: true,
      })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (!isConfirmed) return;
            this.leaveRole();
          },
        });
    } else if (this.teamsService.isCurrentUserOnlyAdmin(this.team)) {
      AlertComponent.openDialog(this.matDialog, {
        title: "Assign a new role admin",
        message: "Please assign a new admin before leaving a role.",
        acceptButtonText: "OK",
      });
      return;
    } else {
      AlertComponent.openDialog(this.matDialog, {
        title: "Leave this role",
        message:
          "You will no longer be able to clock in for this role. Are you sure you want to continue?",
        acceptOnly: false,
        acceptButtonText: "Leave",
        closeButtonText: "Cancel",
        reverseButtonOrder: true,
      })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (!isConfirmed) return;
            this.leaveRole();
          },
        });
    }
  }

  private leaveRole() {
    if (!this.team?.id) return;
    this.teamsService.leaveTeam(this.team.id).subscribe({
      next: (team) => this.leaveTeam.emit(team),
      error: () => AlertComponent.openErrorDialog(this.matDialog),
    });
  }

  private handlePopupMenuAction(action: RoleMemberPopupMenuAction) {
    switch (action.type) {
      case RoleMemberPopupMenuActionType.SecureMessage:
        this.handleSecureMessageAction(action.member);
        break;
      case RoleMemberPopupMenuActionType.ViewProfile:
        this.handleViewProfileAction(action.member);
        break;
      case RoleMemberPopupMenuActionType.MakeRoleAdmin:
        this.handleMakeRoleAdminAction(action.member);
        break;
      case RoleMemberPopupMenuActionType.RemoveRoleAdmin:
        this.handleRemoveRoleAdminAction(action.member);
        break;
      case RoleMemberPopupMenuActionType.RemoveFromRole:
        this.handleRemoveFromRoleAction(action.member);
        break;
    }
  }

  private handleSecureMessageAction(member: TeamMember): void {
    if (!member.user?.userId) return;
    this.conversationService
      .createAndNavigateToChat(member.user.userId)
      .subscribe({
        error: () => AlertComponent.openErrorDialog(this.matDialog),
      });
  }

  private handleViewProfileAction(member: TeamMember): void {
    this.showProfile.emit(member);
  }

  private handleMakeRoleAdminAction(member: TeamMember): void {
    if (!member.teamId || !member.user?.userId) return;
    this.teamsService
      .updateMemberRoles({
        teamId: member.teamId,
        updates: [
          {
            userId: member.user.userId,
            role: TeamMemberRole.Administrator,
          },
        ],
      })
      .subscribe({
        next: (team) => {
          this.updateTeam.emit(team);
          this.showUpgradePromptDialogIfTeamsIsUpgradable();
        },
        error: () => AlertComponent.openErrorDialog(this.matDialog),
      });
  }

  private handleRemoveRoleAdminAction(member: TeamMember): void {
    if (!member.teamId || !member.user?.userId) return;
    this.teamsService
      .updateMemberRoles({
        teamId: member.teamId,
        updates: [
          {
            userId: member.user.userId,
            role: TeamMemberRole.Contributor,
          },
        ],
      })
      .subscribe({
        next: (team) => {
          this.updateTeam.emit(team);
          this.showUpgradePromptDialogIfTeamsIsUpgradable();
        },
        error: () => AlertComponent.openErrorDialog(this.matDialog),
      });
  }

  private handleRemoveFromRoleAction(member: TeamMember): void {
    if (!member.teamId || !member.user?.userId) return;
    this.teamsService
      .removeMembers({
        teamId: member.teamId,
        userIds: [member.user.userId],
      })
      .subscribe({
        next: (team) => {
          this.updateTeam.emit(team);
          this.showUpgradePromptDialogIfTeamsIsUpgradable();
        },
        error: () => AlertComponent.openErrorDialog(this.matDialog),
      });
  }

  private updateSortedTeamMembers() {
    if (!this.workspaceContactsMap || !this.team) return;
    this.isLoading = true;

    const workspaceContactsMap = this.workspaceContactsMap;
    const activeTeamMembers = this.teamsService.filterOutLeftTeamMembers(
      this.team.members ?? []
    );

    this.sortedTeamMembers = this.teamsService
      .sortTeamMembers(activeTeamMembers)
      .map((member) => ({
        ...member,
        departmentName: member.user?.userId
          ? workspaceContactsMap.get(member.user?.userId)?.department
          : null,
      }));

    this.isLoading = false;
  }

  public editRoleDetails() {
    if (!this.team) return;
    EditRoleDetailsDialogComponent.openDialog(this.matDialog, {
      team: this.team,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          this.team = result.team;
          this.showUpgradePromptDialogIfTeamsIsUpgradable();
        },
      });
  }
}
