import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";

export type RolesCtaDialogData = {
  hasAccess: boolean;
};

export type RolesCtaDialogResult = unknown;

@Component({
  selector: "app-roles-cta-dialog",
  templateUrl: "./roles-cta-dialog.component.html",
  styleUrls: ["./roles-cta-dialog.component.scss"],
})
export class RolesCtaDialogComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: RolesCtaDialogData,
    private matDialogRef: MatDialogRef<RolesCtaDialogData, RolesCtaDialogResult>
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: RolesCtaDialogData
  ): MatDialogRef<RolesCtaDialogComponent, RolesCtaDialogResult> {
    const config = createDialogConfig<RolesCtaDialogData>(data, {
      panelClasses: ["roles-cta-dialog"],
      maxWidth: "500px",
    });
    config.autoFocus = "dialog";
    return matDialog.open<
      RolesCtaDialogComponent,
      RolesCtaDialogData,
      RolesCtaDialogResult
    >(RolesCtaDialogComponent, config);
  }

  public onDismiss() {
    this.matDialogRef.close();
  }
}
