/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SubscriptionTier } from "./payments-api";

export enum IdentityVerificationStatus {
  Unverified = "Unverified",
  Pending = "Pending",
  Verified = "Verified",
  Rejectecd = "Rejectecd",
}

export interface UserFeatureModel {
  patientLookup?: boolean;
  photoExport?: boolean;
  onCall?: boolean;
  voip?: boolean;
}

export interface UserProfileInvitationModel {
  uri?: string | null;
}

export enum VerificationStatus {
  Unverified = "Unverified",
  Pending = "Pending",
  Verified = "Verified",
}

export type ClaimValue = "true" | "false";

// Note, this may not have all possible claims typed, but it has all the
// possible claims I was able to find
export interface WorkspaceClaims {
  exportCase?: ClaimValue;
  workspaceInvitationReset?: ClaimValue;
  workspaceTeamManage?: ClaimValue;
  workspaceInvitationInvite?: ClaimValue;
  manageDepartment?: ClaimValue;
  workspaceAllowExternalConversations?: ClaimValue;
  workspaceBillingManage?: ClaimValue;
  startVideoCall?: ClaimValue;
  workspaceUserManage?: ClaimValue;
}

export interface BasicWorkplaceWithClaimsModel {
  claims?: WorkspaceClaims;
  email?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  departmentName?: string | null;
  departmentId?: string | null;

  /** @format date-time */
  joinedOnUtc?: string;

  /** @format date-time */
  leftOnUtc?: string | null;
  position?: string | null;
  isActive?: boolean;
  verificationStatus?: VerificationStatus;
}

export enum ProfessionVerificationStatus {
  Unverified = "Unverified",
  Pending = "Pending",
  Verified = "Verified",
  MoreInfoRequired = "MoreInfoRequired",
}

export interface UserProfessionModel {
  id?: string | null;
  verificationStatus?: ProfessionVerificationStatus;
  category?: string | null;
  profession?: string | null;
  registrationName?: string | null;
  registrationNumber?: string | null;
  registrationLink?: string | null;
}

export interface FullUserProfileModel {
  userId?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  isPhoneNumberConfirmed?: boolean;
  picture?: string | null;
  beenLoggedIn?: string | null;
  isOnCall?: string | null;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  countryCode?: string | null;
  features?: UserFeatureModel;
  invitation?: UserProfileInvitationModel;
  isWorkspaceNotificationEnabled?: boolean;
  isMentionNotificationEnabled?: boolean;
  workplaces?: BasicWorkplaceWithClaimsModel[] | null;
  professions?: UserProfessionModel[] | null;
}

export interface Operation {
  value?: any;
  path?: string | null;
  op?: string | null;
  from?: string | null;
}

export interface CreateWorkplaceModel {
  /** @format email */
  email: string;
  verificationId: string;
  companyId: string;
  departmentId?: string | null;
  position?: string | null;
}

export interface UserPrivacyModel {
  name?: string | null;
  settings?: Record<string, string>;
}

export interface CreateIdentityVerificationModel {
  applicationId?: string | null;
}

export interface IdentityVerificationModel {
  token?: string | null;
  reports?: string[] | null;
}

export interface UserProfessionUpdateModel {
  modifiedBy?: string | null;
  verify?: boolean;
  category?: string | null;
  profession?: string | null;
  registrationName?: string | null;
  registrationNumber?: string | null;
  registrationLink?: string | null;
}

export interface UserListModel {
  userId?: string | null;
  profilePic?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  fullName?: string | null;
  profession?: string | null;
  identityVerified?: boolean;
  professionVerified?: boolean;
  workspaceVerified?: boolean;
}

export interface UserActivityMetadata {
  type?: string | null;
  id?: string | null;
  marker?: string | null;
  name?: string | null;
  content?: string | null;
  read?: boolean | null;
  conversationType?: ConversationType | null;
  conversationId?: string | null;
  profilePic?: string | null;
  user?: UserListModel;
  /** @format date-time */
  deletedOnUtc?: string | null;
  deletedBy?: string | null;
}

export type ActivityFeedType = "Mention" | "WorkspaceJoined" | "TeamOnOffCall";

export interface UserActivityModel {
  type?: ActivityFeedType | null;
  id?: string | null;
  userId?: string | null;

  /** @format date-time */
  timeStampUtc?: string;
  metadata?: UserActivityMetadata;
}

export interface UserActivityModelApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: UserActivityModel[] | null;
}

export interface CompanyBasicModel {
  id?: string | null;
  name: string;
  isActive?: boolean;
  countryCode?: string | null;
}

export interface CompanyBasicModelApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: CompanyBasicModel[] | null;
}

export interface ConnectionModel {
  id?: string | null;
  createdBy?: string | null;
  userId: string;

  /** @format date-time */
  lastModifiedOnUtc?: string | null;
  creatorFullName?: string | null;
  responsorFullName?: string | null;
  creatorProfilePic?: string | null;
  responsorProfilePic?: string | null;
  state?: ConnectionState | null;
}

// TODO: Add other connection states
export type ConnectionState = "Accepted";

export interface UserBlockModel {
  id: string;
  isBlocked?: boolean;

  /** @format date-time */
  lastModifiedOnUtc?: string;
}

export interface Creator {
  fullName?: string | null;
  userId?: string | null;
  isAdmin?: boolean;
}

export interface DepartmentModel {
  companyId?: string | null;
  id?: string | null;
  name: string;
}

export interface WorkspaceAddressModel {
  id?: string | null;
  companyId?: string | null;
  address1?: string | null;
  address2?: string | null;
  suburb?: string | null;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
  countryCode?: string | null;
}

export interface OrganizationModel {
  id?: string | null;
  name?: string | null;
}

export interface ViewMyIntegrationModel {
  id?: string | null;
  name: string;
  description?: string | null;
  type?: string | null;
}

export interface CompanyModel {
  /** @format email */
  email?: string | null;
  country?: string | null;
  region?: string | null;
  creator?: Creator;

  /** @format date-time */
  registeredDate?: string;
  isConsentUploaded?: boolean;
  isTermsUploaded?: boolean;
  isEnterprise?: boolean;
  invitationUri?: string | null;
  profilePictureUri?: string | null;
  verificationStatus?: VerificationStatus;
  profilePictureCreatedTime?: string | null;
  departments?: DepartmentModel[] | null;
  addresses?: WorkspaceAddressModel[] | null;
  organization?: OrganizationModel;
  integrations?: ViewMyIntegrationModel[] | null;
  id?: string | null;
  name: string;
  isActive?: boolean;
  countryCode?: string | null;
  plan?: SubscriptionTier | null;
  hasBillingMethod?: boolean;
  isTrialEligible?: boolean;

  /** @format date-time */
  trialEndDate?: string;

  hasShownTrialEndedNotification?: boolean;
}

export interface WorkplaceModel {
  company?: CompanyModel;
  department?: DepartmentModel;
  email?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  departmentName?: string | null;
  departmentId?: string | null;

  /** @format date-time */
  joinedOnUtc?: string;

  /** @format date-time */
  leftOnUtc?: string | null;
  position?: string | null;
  isActive?: boolean;
  verificationStatus?: VerificationStatus;
}

export interface TeamPublicProfileModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface UserProfileModel {
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModel;
  blockedMe?: UserBlockModel;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
  teams?: TeamPublicProfileModel[] | null;
}

export interface UserProfileModelApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: UserProfileModel[] | null;
}

export interface MatchConnectionRequest {
  countryCode?: string | null;
  phoneNumbers?: string[] | null;
}

export interface ConnectionUserModel {
  connection?: ConnectionModel;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  userUri?: string | null;
  workplaces?: WorkplaceModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  professions?: UserProfessionModel[] | null;
}

export interface ConnectionUserModelApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: ConnectionUserModel[] | null;
}

export enum ConversationType {
  Chat = "Chat",
  Group = "Group",
  Case = "Case",
  SelfChat = "SelfChat",
  TeamChat = "TeamChat",
  External = "External",
}

export enum MediaType {
  Photo = "Photo",
  Video = "Video",
}

export enum MessageStatuses {
  None = "None",
  Delivered = "Delivered",
  Read = "Read",
  Sending = "Sending",
  Sent = "Sent",
}

export interface MessageStatusModel {
  createdBy?: string | null;

  /** @format date-time */
  createdOnUtc?: string;
  status?: MessageStatuses;
}

export enum MentionType {
  User = "User",
  Workspace = "Workspace",
}

export interface MentionModel {
  id?: string | null;
  type?: MentionType;
  name?: string | null;

  /** @format int32 */
  index?: number;
}

export interface MessageMetadata {
  forwardFromMessageId?: string | null;
  forwardFromConversationId?: string | null;
  coalseceId?: string | null;
  mediaType?: MediaType | null;
  photoId?: string | null;
  resourceId?: string | null;
  resourceType?: "VideoCall" | null;
  resourceStatus?: "InProgress" | "Ended" | null;
  resourceCallDurationInSeconds?: string | null;
  [x: string]: unknown;
}

export enum MessageType {
  ParticipantAdded = "ParticipantAdded",
  ParticipantRemoved = "ParticipantRemoved",
  ParticipantLeft = "ParticipantLeft",
  ParticipantRoleChanged = "ParticipantRoleChanged",
  PatientDataChanged = "PatientDataChanged",
  ConversationStarted = "ConversationStarted",
  ConversationNameChanged = "ConversationNameChanged",
  Photo = "Photo",
  PatientFile = "PatientFile",
  UidMismatch = "UidMismatch",
  UserConnected = "UserConnected",
  TeamDeactivated = "TeamDeactivated",
  VideoCall = "VideoCall",
}

export interface MessageModel {
  /**
   * @deprecated Use `marker` instead.
   * @format int64
   * */
  id?: number;
  conversationId?: string | null;
  sentBy?: string | null;

  /** @format date-time */
  sentOnUtc?: string;

  /** @format date-time */
  createdOnUtc?: string | null;
  content?: string | null;
  marker?: string | null;

  /** @format int32 */
  unreadCount?: number | null;

  /** Note: `null` indicates the message is just text, typically sent by a user. */
  type?: MessageType | null;
  mediaType?: MediaType;
  metadata?: MessageMetadata;
  statuses?: MessageStatusModel[] | null;
  mentions?: MentionModel[] | null;
  replyTo?: string | null;
  replyToMessage?: MessageModel;
  allowDelivery?: boolean;

  /** @format date-time */
  deletedOnUtc?: string;
  deletedBy?: string;
}

export enum Gender {
  Male = "Male",
  Female = "Female",
  NotSpecified = "Not Specified",
}

export interface PatientDataModel {
  uid?: string | null;
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  dateOfBirth?: string | null;
  gender?: Gender;
  phoneNumber?: string | null;
}

export interface ConversationInvitationModel {
  uri?: string | null;
  allowAll?: boolean;
}

export enum ParticipantRole {
  Contributor = "Contributor",
  Administrator = "Administrator",
}

export interface ConversationParticipantModelV2 {
  teamId?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModel;
  blockedMe?: UserBlockModel;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  isExternal?: boolean | null;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
  teams?: TeamPublicProfileModel[] | null;

  /** @format date-time */
  leftOnUtc?: string | null;

  /** @format date-time */
  joinedOnUtc?: string | null;
  isActive?: boolean;

  /** @format date-time */
  mutedToUtc?: string | null;

  /** @format int32 */
  muteInterval?: number | null;
  isHidden?: boolean;

  /** @format date-time */
  asReadToUtc?: string | null;
  role?: ParticipantRole;

  /** @deprecated */
  profilePicture?: string | null;

  /** @deprecated */
  position?: string | null;

  /** @format date-time */
  pinnedOnUtc?: string | null;
}

export interface ConversationModelV2 {
  id?: string | null;
  teamIds?: string[] | null;
  subject?: string | null;
  name?: string | null;
  createdBy?: string | null;
  type?: ConversationType;

  /** @format date-time */
  createdOnUtc?: string;

  /** @format date-time */
  lastModifiedOnUtc?: string;
  lastMessage?: MessageModel;
  unreadMessageIds?: number[] | null;
  patientData?: PatientDataModel;
  isReal?: boolean;
  invitation?: ConversationInvitationModel;
  participants?: ConversationParticipantModelV2[] | null;
  photoId?: string;
}

export interface ConversationModelV2ApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: ConversationModelV2[] | null;
}

export interface CreateParticipantModel {
  userId?: string;
  teamId?: string;
}

export interface CreateConversationModel {
  id?: string | null;
  subject?: string | null;
  name?: string | null;

  /** @format date-time */
  createOnUtc?: string | null;
  type?: ConversationType;
  patientData?: PatientDataModel;
  participants?: CreateParticipantModel[] | null;
}

export interface ConversationParticipantModel {
  teamId?: string | null;
  company?: string | null;
  companyName?: string | null;
  companyId?: string | null;
  department?: string | null;
  departmentId?: string | null;
  position?: string | null;
  isTrimmed?: boolean;
  profilePicture?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModel;
  blockedMe?: UserBlockModel;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
  teams?: TeamPublicProfileModel[] | null;

  /** @format date-time */
  leftOnUtc?: string | null;

  /** @format date-time */
  joinedOnUtc?: string | null;
  isActive?: boolean;

  /** @format date-time */
  mutedToUtc?: string | null;

  /** @format int32 */
  muteInterval?: number | null;
  isHidden?: boolean;

  /** @format date-time */
  asReadToUtc?: string | null;
  role?: ParticipantRole;

  /** @format date-time */
  pinnedOnUtc?: string | null;
}

export interface ConversationModel {
  id?: string | null;
  teamIds?: string[] | null;
  subject?: string | null;
  name?: string | null;
  createdBy?: string | null;
  type?: ConversationType;

  /** @format date-time */
  createdOnUtc?: string;

  /** @format date-time */
  lastModifiedOnUtc?: string;
  lastMessage?: MessageModel;
  unreadMessageIds?: number[] | null;
  patientData?: PatientDataModel;
  isReal?: boolean;
  invitation?: ConversationInvitationModel;
  participants?: ConversationParticipantModel[] | null;
  photoId?: string;
}

export interface CreateMessageMediaModel {
  mediaId?: string | null;
  photoId?: string | null;
  content?: string | null;
  marker?: string | null;

  /** @format date-time */
  createdOnUtc?: string | null;
  replyTo?: string | null;
  allowDelivery?: boolean;
  mentions?: MentionModel[] | null;
}

export interface CreateMessageModel {
  content?: string | null;
  marker?: string | null;

  /** @format date-time */
  createdOnUtc?: string | null;
  replyTo?: string | null;
  allowDelivery?: boolean;
  forwardFromMessageId?: string | null;
  forwardFromConversationId?: string | null;
  mentions?: MentionModel[] | null;
}

export interface UpdateConversationInvitationModel {
  allowAll?: boolean;
}

export interface AddColleagueModel {
  phoneNumbers?: string[] | null;
  userEmails?: string[] | null;
}

export interface AddInvitationsModel {
  userEmails?: string[] | null;
  contacts?: AddColleagueModel[] | null;
}

export enum InvitationType {
  Test = "Test",
  WorkspaceJoin = "WorkspaceJoin",
  Group = "Group",
  Case = "Case",
  WorkspaceCreate = "WorkspaceCreate",
  ConnectionRequests = "ConnectionRequests",
  User = "User",
  UserSpecificWorkspaceJoin = "UserSpecificWorkspaceJoin",
}

export enum InvitationConfirmationType {
  Enterprise = "Enterprise",
}

export interface InvitationMetadata {
  id?: string | null;
  name?: string | null;
}

export interface InvitationModel {
  id?: string | null;
  type?: InvitationType;
  isActive?: boolean;

  /** @format date-time */
  createdOnUtc?: string;
  metadata?: InvitationMetadata;
  isActioned?: boolean;
  isConfirmationRequired?: boolean;
  confirmationType?: InvitationConfirmationType | null;
}

export interface ResetInvitationResponseModel {
  invitationUri?: string | null;
}

export interface ActionInvitationResponseModel {
  type?: InvitationType | null;
  resourceUri?: string | null;
}

export interface CreatorModel {
  userId?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface PatientMediaModel {
  mediaType?: MediaType;
  thumbnailUrl?: string | null;
  id?: string | null;
  patientData?: PatientDataModel;
  consentId?: string | null;
  url?: string | null;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
  hasOverlay?: boolean;
  isImported?: boolean;
  isExported?: boolean | null;
  isDeleted?: boolean;

  /** @format date-time */
  deletedOnUtc?: string | null;

  /** @format date-time */
  sharedOnUtc?: string | null;
  fileName?: string | null;
  description?: string | null;
}

export enum CreatedByEnum {
  Me = "Me",
  Other = "Other",
  All = "All",
}

export interface PatientMediaModelPagedResult {
  data?: PatientMediaModel[] | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format date-time */
  pageProcessedOn?: string;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
}

export enum MediaSize {
  Full = "Full",
  Small = "Small",
  Medium = "Medium",
}

export interface UpdatePatientPhotoModel {
  patientData?: PatientDataModel;
  consentId?: string | null;
  description?: string | null;
}

export interface PatientConsentModel {
  id?: string | null;
  patientData?: PatientDataModel;
  url?: string | null;

  /** @format date-time */
  validBy?: string;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
  company?: CompanyBasicModel;
}

export enum PhotoEditHistoryType {
  Edit = "Edit",
  Export = "Export",
}

export interface CompanyTypeModel {
  id?: string | null;
  description?: string | null;
  code?: string | null;
}

export interface DepartmentIntegrationModel {
  id?: string | null;
  name?: string | null;
  code?: string | null;
}

export interface IntegrationModel {
  settings?: Record<string, string>;
  id?: string | null;
  name: string;
  description?: string | null;
  type?: string | null;
}

export interface CompanyIntegrationModel {
  id?: string | null;
  name?: string | null;
  timezone?: string | null;
  code?: string | null;
  alternativeId?: string | null;
  type?: CompanyTypeModel;
  department?: DepartmentIntegrationModel;
  integration?: IntegrationModel;
}

export interface PhotoEditorsHistoryMetadataModel {
  externalId?: string | null;
  uri?: string | null;
  integration?: CompanyIntegrationModel;
}

export interface PhotoEditorsHistoryModel {
  uid?: string | null;
  type?: PhotoEditHistoryType;
  metadata?: PhotoEditorsHistoryMetadataModel;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  timestamp?: string;
}

export interface PatientMediaDetailsModel {
  mediaType?: MediaType;
  thumbnailUrl?: string | null;
  mimeType?: string | null;
  id?: string | null;
  patientData?: PatientDataModel;
  consent?: PatientConsentModel;
  hasOverlay?: boolean;
  isImported?: boolean;
  url?: string | null;
  shareUrl?: string | null;
  description?: string | null;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
  modifiedBy?: PhotoEditorsHistoryModel[] | null;
}

export interface ExportPhotoRequest {
  companyId?: string | null;
  integrationId?: string | null;
  serviceCode?: string | null;
}

export interface UploadFileModel {
  fileName?: string | null;

  /** @format byte */
  data?: string | null;
  mimeType?: string | null;

  /** @format int64 */
  size?: number;
}

export interface CreatePatientMediaRequestModel {
  patientData?: PatientDataModel;
  consentId?: string | null;
  description?: string | null;
  isImported?: boolean;

  /** @format date-time */
  createdOnUtc?: string | null;
  media?: UploadFileModel;
  mediaType?: MediaType;
  thumbnail?: UploadFileModel;
}

export enum TeamMemberRole {
  Administrator = "Administrator",
  Contributor = "Contributor",
}

export interface TeamMember {
  teamId?: string | null;
  user?: UserListModel;

  /** @format date-time */
  joinedOnUtc?: string;

  /** @format date-time */
  leftOnUtc?: string | null;
  role?: TeamMemberRole | null;

  /** @format date-time */
  onCallStartOnUtc?: string | null;

  /** @format date-time */
  onCallEndOnUtc?: string | null;
}

export interface Team {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  isActive?: boolean;
  unreadConversationIds?: string[] | null;
  members?: TeamMember[] | null;
  workspace?: WorkspaceModel | null;
}

export interface TeamApiTokenPagedResult {
  nextPageUri?: string | null;
  hasNext?: boolean;
  token?: string | null;

  /** @format int32 */
  pageSize?: number;
  data?: Team[] | null;
}

export interface TeamApiPagedResult {
  nextPageUri?: string | null;
  hasNext?: boolean;
  page?: number | null;

  /** @format int32 */
  pageSize?: number;
  data?: Team[] | null;
}

export interface AddTeamMemberModel {
  userId?: string | null;
}

export interface RemoveTeamMemberModel {
  userIds?: string[] | null;
}

export interface WorkspaceModel {
  id?: string | null;
  name?: string | null;
  invitationUri?: string | null;
  verificationStatus?: VerificationStatus;
  profilePictureUri?: string | null;
  partnerWorkspaceIds?: string[] | null;
}

export interface DepartmentBasicModel {
  id?: string | null;
  name: string;
}

export interface UserWorkspaceModel {
  email?: string | null;

  /** @format date-time */
  joinedOnUtc?: string;

  /** @format date-time */
  leftOnUtc?: string | null;
  position?: string | null;
  isActive?: boolean;
  isPartner?: boolean;
  workspace?: WorkspaceModel;
  department?: DepartmentBasicModel;
  claims?: WorkspaceClaims;
}

export interface UserWorkspaceModelApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: UserWorkspaceModel[] | null;
}

export interface NotificationSettings {
  workspaceNotificationEnabled?: boolean | null;
  mentionNotificationEnabled?: boolean | null;
}

export interface UserBadgesModel {
  activityFeedSeen?: boolean | null;
  connectionRequestSeen?: boolean | null;
}

export interface TeamTokenPagedResult {
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  total?: number;
  token?: string | null;
  data?: Team[] | null;

  /** @format int32 */
  pageSize?: number;

  /** @format date-time */
  pageProcessedOn?: string;

  /** @format int32 */
  totalPages?: number;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
}

export interface UserProfileWithAllWorkspacesModel {
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModel;
  blockedMe?: UserBlockModel;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
  teams?: TeamPublicProfileModel[] | null;
}

export interface BasicWorkplaceModel {
  email?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  departmentName?: string | null;
  departmentId?: string | null;

  /** @format date-time */
  joinedOnUtc?: string;

  /** @format date-time */
  leftOnUtc?: string | null;
  position?: string | null;
  isActive?: boolean;
  verificationStatus?: VerificationStatus;
}

export interface FileModel {
  uri?: string | null;
  midThumbUri?: string | null;
  smallThumbUri?: string | null;
  fileName?: string | null;

  /** @format byte */
  data?: string | null;
  mimeType?: string | null;

  /** @format int64 */
  size?: number;
}

export interface UserPublicProfileModel {
  id?: string | null;
  legalFirstName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  profilePic?: string | null;
  isIdentityConfirmed?: boolean;
  dataRegionId?: string | null;
  workspace?: BasicWorkplaceModel[] | null;
  professions?: UserProfessionModel[] | null;
  teams?: TeamPublicProfileModel[] | null;
  profilePicFile?: FileModel;
}

export interface BasicContactModel {
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  userUri?: string | null;
  workplaces?: WorkplaceModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  professions?: UserProfessionModel[] | null;
}

export interface BasicContactModelPagedResult {
  data?: BasicContactModel[] | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format date-time */
  pageProcessedOn?: string;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
}

export enum SuggestedType {
  Chat = "Chat",
  Connection = "Connection",
  Department = "Department",
  Colleagues = "Colleagues",
  Random = "Random",
}

export interface SuggestedUserModel {
  type?: SuggestedType;
  userId?: string | null;
  profilePic?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  fullName?: string | null;
  profession?: string | null;
  identityVerified?: boolean;
  professionVerified?: boolean;
  workspaceVerified?: boolean;
}

export interface WorkspaceDetailsModel {
  /** @format date-time */
  registeredDate?: string;
  creator?: CreatorModel;
  countryCode?: string | null;
  type?: "Workspace" | "Company" | null;
  isActive?: boolean;
  id?: string | null;
  name?: string | null;
  invitationUri?: string | null;
  verificationStatus?: VerificationStatus;
  profilePictureUri?: string | null;
}

export interface WorkspaceDetailsModelApiPagedResult {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: WorkspaceDetailsModel[] | null;
}

export interface CreateWorkspaceModel {
  name?: string | null;
}

export enum ProfilePictureSize {
  Full = "Full",
  Small = "Small",
  Medium = "Medium",
}

export interface UnreadConversationsModel {
  unreadConversationIds?: string[] | null;
}

export interface UnreadMessagesModel {
  unreadMessageIds?: string[] | null;
}

export interface UserFeatureFlagModel {
  workspace_roles: boolean;
  workspace_create_workspace: boolean;
  max_pinned_conversations: number;
  start_video_call: boolean;
  start_video_call_ui: boolean;
}

export enum PinnedMode {
  Default = "Default",
  PinnedTop = "PinnedTop",
  PinnedOnly = "PinnedOnly",
}

export type CallStatus = "InProgress" | "Ended";
