import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig, generateUpgradeUrl } from "@utils";

export type TrialEndedCtaDialogData = unknown;

export interface TrialEndedCtaDialogResult {}

@Component({
  selector: "app-trial-ended-cta-dialog",
  templateUrl: "./trial-ended-cta-dialog.component.html",
  styleUrls: ["./trial-ended-cta-dialog.component.scss"],
})
export class TrialEndedCtaDialogComponent {
  public upgradeUrl = generateUpgradeUrl({
    redirectPath: "/network",
  });
  public constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: TrialEndedCtaDialogData,
    private matDialogRef: MatDialogRef<
      TrialEndedCtaDialogData,
      TrialEndedCtaDialogResult
    >
  ) {}

  public static openDialog(
    matDialog: MatDialog
  ): MatDialogRef<TrialEndedCtaDialogComponent, TrialEndedCtaDialogResult> {
    const config = createDialogConfig<TrialEndedCtaDialogData>(null, {
      disableClose: true,
      panelClasses: ["end-trial-cta-dialog"],
      maxWidth: "500px",
    });
    config.autoFocus = "dialog";
    return matDialog.open<
      TrialEndedCtaDialogComponent,
      TrialEndedCtaDialogData,
      TrialEndedCtaDialogResult
    >(TrialEndedCtaDialogComponent, config);
  }

  public onDowngrade() {
    this.matDialogRef.close();
  }
}
