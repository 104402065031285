<div class="content">
  <div class="banner-message">
    You are currently using the free version. Start a free trial of a paid plan
    - no payment or credit card required!
  </div>
  <a mat-ripple class="upgrade" [href]="upgradeUrl" [target]="target">
    Upgrade
  </a>
</div>
<button type="button" class="close" mat-icon-button (click)="close.emit()">
  <mat-icon>close</mat-icon>
</button>
