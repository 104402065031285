import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@env";
import {
  AccountService,
  AlertService,
  ConversationService,
  ConversationsService,
  SharedService,
  SnackbarService,
  UserLimits,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components/alert/alert.component";
import { UpgradePromptDialogComponent } from "@modules/shared/upgrade-prompt-dialog/upgrade-prompt-dialog.component";
import { NetworkUserPickerMode } from "@modules/user-picker/network-user-picker.service";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import {
  ConversationModel,
  ConversationParticipantModelV2,
  ConversationType,
  FeatureState,
} from "@types";
import { isNotNullOrUndefined, UserLimitsUtils } from "@utils";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "app-message-detail",
  templateUrl: "./message-detail.component.html",
  styleUrls: ["./message-detail.component.scss", "../../../celo-input.scss"],
})
export class MessageDetailComponent implements OnInit, OnDestroy, OnChanges {
  hasPatient = false;
  patient: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  userId = "";
  cName = "";
  cSubject = "";
  @Input() participants: any[];
  @Input() activeAdminList: string[] = [];
  @Input() conversation: ConversationModel;
  @Input() userAccount: any;
  @Input() leftChat: boolean;
  @Input() type = "";
  @Input() chatParticipantId = "";
  @Input() chatParticipant: any;
  @Input() refreshCount = 0;
  @Output() leftChatEvent = new EventEmitter<boolean>();
  @Output() openWindow = new EventEmitter<string>();
  @Output() closeClick = new EventEmitter();
  @Output() isAdmin = new EventEmitter<boolean>();
  @Output() conversationChange = new EventEmitter<any>();
  @Output() userDataChanged = new EventEmitter<any>();
  @ViewChild("form1") form1: any;
  saving: boolean;
  formChangesSubscription: any;
  hideSecureMessageButton = false;

  // Used in template
  private isConversationUpdating = false;
  isInConversation = true;

  @Input() showParticipantProfile = false;
  @Input() profileInFocus: string = "";

  public isExportCaseToPdfVisible$: Observable<boolean>;
  public userLimits$: Observable<UserLimits | null>;

  constructor(
    private alertService: AlertService,
    private sharedService: SharedService,
    private conversationService: ConversationService,
    private userPickerService: UserPickerService,
    private conversationsService: ConversationsService,
    private snackbarService: SnackbarService,
    private matDialog: MatDialog,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.isExportCaseToPdfVisible$ = this.accountService.userLimits$.pipe(
      map(
        (userLimits) =>
          userLimits && UserLimitsUtils.isExportCaseToPdfVisible(userLimits)
      )
    );
    this.userLimits$ = this.accountService.userLimits$;

    if (this.formChangesSubscription)
      this.formChangesSubscription = this.form1.form.valueChanges.subscribe(
        (x) => {
          this.saving = false;
        }
      );
  }

  getConversationInfo() {
    if (!this.conversation || !this.conversation.id) {
      return;
    }
    const instance = this;
    this.conversationService
      .getConversationByIdApi(this.conversation.id)
      .subscribe((conversation) => {
        if (!conversation) {
          return;
        }
        instance.conversation = conversation;
        instance.setViewData(this.conversation);
      });
  }

  ngOnChanges() {
    this.setViewData(this.conversation);
    this.hideSecureMessageButton = false;
    if (
      this.type == ConversationType.Chat ||
      this.type == ConversationType.SelfChat ||
      this.type == ConversationType.TeamChat
    ) {
      this.hideSecureMessageButton = true;
    }

    this.isInConversation = !this.conversation.participants.find(
      (cp) => cp.userId === this.userAccount.userId
    ).leftOnUtc;
  }

  ngOnDestroy() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  updateConvo(data) {
    this.saving = true;
    const patchData = {
      subject: this.cSubject,
      name: this.cName ? this.cName.trim() : this.cName,
    };
    if (this.hasPatient) {
      patchData["patientData"] = this.patient;
    }

    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/{conversationId}".replace(
        "{" + "conversationId" + "}",
        String(this.conversation.id)
      );
    this.sharedService.patchObjectById(path, patchData).subscribe(
      (convo) => {
        this.saving = false;
        this.conversation = convo;
        this.setViewData(this.conversation);
        this.form1.form.markAsPristine();
        this.conversationChange.emit(convo);
        this.alertService.showSnackBar(
          this.type + " has been updated successfully",
          4
        );
      },
      (err) => {
        this.getConversationInfo();
        this.saving = false;
        let errText = "Error updating conversation. ";
        if (err.status == 400) {
          const errBody = err.error;
          if (errBody.uid) {
            errText += " " + errBody.uid[0];
          }
          if (errBody.dateOfBirth) {
            errText += " " + errBody.dateOfBirth[0];
          }
        }
        this.alertService.alert("", errText, true);
      }
    );
  }

  private setViewData(convo: any) {
    this.cSubject = this.conversation.subject;
    this.cName = this.conversation.name;
    if (this.conversation.patientData) {
      this.patient = { ...this.conversation.patientData };
      this.hasPatient = true;
    } else {
      this.patient = {
        uid: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        gender: null,
      };
      this.hasPatient = false;
    }
  }

  cancelConvo() {
    this.setViewData(this.conversation);
  }

  remove(participantId: string, participantName?) {
    this.alertService
      .confirm(
        "Remove participant",
        "Are you sure you want to remove " +
          (participantName ? participantName : "this participant") +
          "?",
        "Remove"
      )
      .subscribe((isConfirm) => {
        if (isConfirm) {
          const x: Array<any> = [{ userId: participantId }];
          const path =
            environment.celoApiEndpoint +
            "/api/Conversations/{conversationId}/Participants".replace(
              "{" + "conversationId" + "}",
              String(this.conversation.id)
            );

          this.sharedService.deleteObjectById(path, x).subscribe((convo) => {
            this.participants = this.participants.filter(
              (p: any) => p.userId !== participantId
            );
            this.conversationChange.emit(convo);
          });
        }
      });
  }

  isLastActiveAdmin() {
    if (!this.userAccount) {
      return false;
    }
    if (
      this.activeAdminList &&
      this.activeAdminList.length == 1 &&
      this.activeAdminList[0] == this.userAccount.userId
    ) {
      return true;
    }

    return false;
  }

  isLastActiveParticipant() {
    for (const participant of this.participants) {
      if (
        participant.isActive &&
        participant.userId != this.userAccount.userId
      ) {
        return false;
      }
    }
    return true;
  }

  leaveConvo() {
    if (this.isLastActiveAdmin() && !this.isLastActiveParticipant()) {
      this.alertService.confirm(
        "Assign a new " + this.type.toLocaleLowerCase() + " admin",
        "Please assign a new admin before leaving this " +
          this.type.toLocaleLowerCase() +
          ". ",
        "OK",
        "",
        true
      );
      return;
    }
    const i = this;
    this.alertService
      .confirm(
        "Leave " + this.type.toLocaleLowerCase(),
        "If you choose to leave, you will no longer be a part of this " +
          this.type.toLowerCase() +
          " and will not receive further messages from this " +
          this.type.toLowerCase() +
          ".",
        "Leave",
        "Cancel",
        false
      )
      .subscribe((confirm: boolean) => {
        if (confirm) {
          const path =
            environment.celoApiEndpoint +
            "/api/v2/conversations/{conversationId}/leave".replace(
              "{" + "conversationId" + "}",
              String(this.conversation.id)
            );
          this.sharedService.postObjectById(path).subscribe((resp) => {
            i.leftChat = true;
            i.leftChatEvent.emit(true);
          }),
            (err) => {
              this.alertService.confirm(
                "",
                this.sharedService.STANDARD_ERROR_MESSAGE,
                "Ok",
                "",
                true
              );
            };
        }
      });
  }

  showProfile(participant: ConversationParticipantModelV2): void {
    this.showParticipantProfile = true;
    // this.chatParticipantId = participant.userId;
    this.profileInFocus = participant.userId;
  }

  onAddParticipants(): void {
    const currentlySelectedUsersIds = this.participants
      .map((p) => p.userId as string)
      .filter(isNotNullOrUndefined);

    this.userPickerService.openNetworkUserPicker({
      header: "Add Participants",
      selectedHeader: "Participants",
      searchPlaceholder: "Search network",
      selectedQuantityLabels: {
        one: "participant",
        plural: "participants",
        zero: "participants",
      },
      initiallySelectedUserIds: currentlySelectedUsersIds,
      disabledUserIds: currentlySelectedUsersIds,
      excludeTeams: true,
      mode: NetworkUserPickerMode.MULTIPLE_SELECT,
      submitCallback: (users) => {
        const participantUserIds = users
          .filter((u) => !u.isDisabled)
          .map((u) => u.id);
        return this.addParticipants(participantUserIds);
      },
    });
  }

  private addParticipants(participantUserIds: string[]): Observable<void> {
    this.isConversationUpdating = true;
    return this.conversationService
      .addParticipants(this.conversation.id, participantUserIds)
      .pipe(
        tap({
          next: (conversation) => {
            this.conversationChange.emit(conversation);
          },
          error: (e) => {
            this.isConversationUpdating = false;
          },
          complete: () => {
            this.isConversationUpdating = false;
          },
        })
      ) as Observable<void>;
  }

  onExportToPdf(userLimits: UserLimits): void {
    if (userLimits.export_cases === FeatureState.Upgradeable) {
      UpgradePromptDialogComponent.openDialog(this.matDialog);
      return;
    }

    const ref = AlertComponent.openDialog(this.matDialog, {
      title: "Warning",
      message: "Sharing content outside of Celo is your responsibility",
      acceptButtonText: "Confirm Export",
      closeButtonText: "Cancel",
      acceptOnly: false,
    });

    ref.afterClosed().subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.conversationsService
        .exportToPdf(this.conversation.id)
        .subscribe(({ operationId }) => {
          this.snackbarService.show(
            "Exporting PDF... Your download will start automatically",
            10
          );
          console.log("Operation ID:", operationId);
        });
    });
  }
}
