import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CeloMaterialModule } from "app/celo-material/celo-material.module";
import { FileUploadModule } from "ng2-file-upload";
import { AvatarModule } from "ngx-avatars";
import { AccountsComponent } from "./accounts/accounts.component";
import { AddAWorkspaceComponent } from "./add-a-workspace/add-a-workspace.component";
import { AddCompanyDetailsComponent } from "./add-company-details/add-company-details.component";
import { SecurePipe } from "./auth.img.pipe";
import { UrlHelperService } from "./auth.img.service";
import { AutofocusDirective } from "./autofocus/autofocus.directive";
import { AvatarWithNameComponent } from "./avatar/avatar-with-name/avatar-with-name.component";
import { AvatarComponent } from "./avatar/avatar/avatar.component";
import { CeloAvatarComponent } from "./avatar/celo-avatar/celo-avatar.component";
import { BasicAvatarComponent } from "./basic-avatar/basic-avatar.component";
import { BasicCeloAvatarComponent } from "./basic-celo-avatar/basic-celo-avatar.component";
import { BasicCeloCheckboxComponent } from "./basic-celo-checkbox/basic-celo-checkbox.component";
import { BasicLabelComponent } from "./basic-label/basic-label.component";
import { BasicSpinnerComponent } from "./basic-spinner/basic-spinner.component";
import { BasicTextfieldComponent } from "./basic-textfield/basic-textfield.component";
import { BasicUserAvatarCardComponent } from "./basic-user-avatar-card/basic-user-avatar-card.component";
import { BasicUserCardComponent } from "./basic-user-card/basic-user-card.component";
import { BasicUserSelectionListGroupComponent } from "./basic-user-selection-list-group/basic-user-selection-list-group.component";
import { BasicUserSelectionListComponent } from "./basic-user-selection-list/basic-user-selection-list.component";
import { SelectedDirective } from "./basic-user-selection-list/selected.directive";
import { BasicUserStatusesComponent } from "./basic-user-statuses/basic-user-statuses.component";
import { CeloButtonPairComponent } from "./celo-button-pair/celo-button-pair.component";
import { CeloCancelSaveButtonsComponent } from "./celo-cancel-save-buttons/celo-cancel-save-buttons.component";
import { CeloContactCardComponent } from "./celo-contact-card/celo-contact-card.component";
import { CeloCountriesComponent } from "./celo-countries/celo-countries.component";
import { CeloNoResultsFoundMessageComponent } from "./celo-no-results-found-message/celo-no-results-found-message.component";
import { CeloParticipantCardComponent } from "./celo-participant-card/celo-participant-card.component";
import { CeloPickParticipantsComponent } from "./celo-pick-participants/celo-pick-participants.component";
import { CeloSideMenuSubListComponent } from "./celo-side-menu-sub-list/celo-side-menu-sub-list.component";
import { CeloTopbarContactChipsComponent } from "./celo-topbar-contact-chips/celo-topbar-contact-chips.component";
import { CeloTopbarSearchInputComponent } from "./celo-topbar-search-input/celo-topbar-search-input.component";
import { CeloUserCardComponent } from "./celo-user-card/celo-user-card.component";
import { CeloUserStatusComponent } from "./celo-user-status/celo-user-status.component";
import { ClinkPipe } from "./clink.pipe";
import { ConsentDownloadComponent } from "./consent-download/consent-download.component";
import { ConversationParticipantPopupMenuComponent } from "./conversation-participant-popup-menu/conversation-participant-popup-menu.component";
import { ConversationParticipantsListComponent } from "./conversation-participants-list/conversation-participants-list.component";
import { CopyBoxComponent } from "./copy-box/copy-box.component";
import { CustomDialogComponent } from "./custom-dialog/custom-dialog.component";
import { DepartmentPickerComponent } from "./department-picker/department-picker.component";
import { DropZoneComponent } from "./drop-zone/drop-zone.component";
import { EditFieldsComponent } from "./edit-fields/edit-fields.component";
import { EditPatientDetailsDialogComponent } from "./edit-patient-details-dialog/edit-patient-details-dialog.component";
import { EditPatientComponent } from "./edit-patient/edit-patient.component";
import { EmailerComponent } from "./emailer/emailer.component";
import { EmptyStateComponent } from "./empty-state/empty-state.component";
import { ExpansionPanelHeaderComponent } from "./expansion-panel-header/expansion-panel-header.component";
import { ExpansionPanelContentDirective } from "./expansion-panel/expansion-panel-content.directive";
import { ExpansionPanelComponent } from "./expansion-panel/expansion-panel.component";
import { ExportConfirmationDialogComponent } from "./export-confirmation-dialog/export-confirmation-dialog.component";
import { ExternalChatDetailsComponent } from "./external-chat-details/external-chat-details.component";
import { FeedbackPageComponent } from "./feedback-page/feedback-page.component";
import { FileListViewComponent } from "./file-list-view/file-list-view.component";
import { FileUploadPreviewComponent } from "./file-upload-preview/file-upload-preview.component";
import { ImgOverlayService } from "./img-overlay.service";
import { IntegrationsPickerComponent } from "./integrations-picker/integrations-picker.component";
import { IsValidStringPipe } from "./is-valid-string-pipe/is-valid-string.pipe";
import { LinkShareComponent } from "./link-share/link-share.component";
import { LoadingLogoComponent } from "./loading-logo/loading-logo.component";
import { ParticipantListComponent } from "./participant-list/participant-list.component";
import { PatientDetailsFormComponent } from "./patient-details-form/patient-details-form.component";
import { PatientDetailsComponent } from "./patient-details/patient-details.component";
import { PatientViewComponent } from "./patient-view/patient-view.component";
import { PatientIdLookupComponent } from "./patientid-lookup/patientid-lookup.component";
import { CapitalizeFirstLetterPipe } from "./pipes/capitalizeFirstLetter/capitalize-first-letter.pipe";
import { CountryFromCodePipe } from "./pipes/country-from-code.pipe";
import { DateFormatPipe } from "./pipes/dateFormat/date-format.pipe";
import { LinkifyPipe } from "./pipes/linkify/linkify.pipe";
import { PhoneFormatPipe } from "./pipes/phoneFormat/phone-format.pipe";
import { TrimPipe } from "./pipes/trim/trim.pipe";
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { PossessivePipe } from "./possessive.pipe";
import { ProfileShareComponent } from "./profile-share/profile-share.component";
import { QuotedMessageComponent } from "./quoted-message/quoted-message.component";
import { RolesIconComponent } from "./roles-icon/roles-icon.component";
import { ScrollToBottomDirectiveDirective } from "./scroll-to-bottom-directive/scroll-to-bottom-directive.directive";
import { SoundPlayService } from "./sound-play.service";
import { StringDateToDisplayFormatPipe } from "./string-date-to-display-format.pipe";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { BadgeInfoComponent } from "./verifications/badge-info/badge-info.component";
import { BadgeComponent } from "./verifications/badge/badge.component";
import { VerifyIdentityInfoComponent } from "./verifications/verify-identity-info/verify-identity-info.component";
import { ZipFilesService } from "./zip-files.service";
import { UpgradeBannerComponent } from './upgrade-banner/upgrade-banner.component';

@NgModule({
  declarations: [
    AvatarComponent,
    AvatarWithNameComponent,
    CeloAvatarComponent,
    BadgeComponent,
    BadgeInfoComponent,
    VerifyIdentityInfoComponent,
    CountryFromCodePipe,
    DateFormatPipe,
    EmptyStateComponent,
    CeloCancelSaveButtonsComponent,
    BasicAvatarComponent,
    BasicCeloAvatarComponent,
    BasicUserCardComponent,
    BasicUserStatusesComponent,
    BasicUserAvatarCardComponent,
    BasicUserSelectionListComponent,
    SelectedDirective,
    BasicCeloCheckboxComponent,
    BasicUserSelectionListGroupComponent,
    ExpansionPanelComponent,
    ExpansionPanelContentDirective,
    BasicLabelComponent,
    BasicTextfieldComponent,
    ExpansionPanelHeaderComponent,
    BasicSpinnerComponent,
    ScrollToBottomDirectiveDirective,
    LoadingLogoComponent,
    SecurePipe,
    ClinkPipe,
    CeloUserCardComponent,
    IsValidStringPipe,
    RolesIconComponent,
    PossessivePipe,
    LinkifyPipe,
    TrimPipe,
    CeloSideMenuSubListComponent,
    PatientViewComponent,
    DepartmentPickerComponent,
    CeloContactCardComponent,
    CeloNoResultsFoundMessageComponent,
    CeloTopbarSearchInputComponent,
    CeloTopbarContactChipsComponent,
    CeloParticipantCardComponent,
    FileListViewComponent,
    QuotedMessageComponent,
    DropZoneComponent,
    FileUploadPreviewComponent,
    EditFieldsComponent,
    StringDateToDisplayFormatPipe,
    ConsentDownloadComponent,
    CustomDialogComponent,
    CopyBoxComponent,
    CeloButtonPairComponent,
    FeedbackPageComponent,
    IntegrationsPickerComponent,
    CeloPickParticipantsComponent,
    CeloUserStatusComponent,
    AccountsComponent,
    CeloCountriesComponent,
    UnauthorizedComponent,
    EmailerComponent,
    LinkShareComponent,
    AutofocusDirective,
    AddAWorkspaceComponent,
    AccountsComponent,
    CeloCountriesComponent,
    UnauthorizedComponent,
    EmailerComponent,
    LinkShareComponent,
    AutofocusDirective,
    ParticipantListComponent,
    EditPatientComponent,
    PatientIdLookupComponent,
    ProfileShareComponent,
    PatientDetailsComponent,
    PatientDetailsFormComponent,
    EditPatientDetailsDialogComponent,
    PhoneFormatPipe,
    ExportConfirmationDialogComponent,
    CapitalizeFirstLetterPipe,
    ExternalChatDetailsComponent,
    ConversationParticipantsListComponent,
    ConversationParticipantPopupMenuComponent,
    AddCompanyDetailsComponent,
    PopupMenuComponent,
    UpgradeBannerComponent,
  ],
  exports: [
    AvatarComponent,
    AvatarWithNameComponent,
    CeloAvatarComponent,
    BadgeComponent,
    BadgeInfoComponent,
    VerifyIdentityInfoComponent,
    CountryFromCodePipe,
    DateFormatPipe,
    CeloMaterialModule,
    EmptyStateComponent,
    CeloCancelSaveButtonsComponent,
    BasicAvatarComponent,
    BasicCeloAvatarComponent,
    BasicUserCardComponent,
    BasicUserStatusesComponent,
    BasicUserAvatarCardComponent,
    BasicUserSelectionListComponent,
    SelectedDirective,
    BasicCeloCheckboxComponent,
    BasicUserSelectionListGroupComponent,
    ExpansionPanelComponent,
    ExpansionPanelContentDirective,
    BasicLabelComponent,
    BasicTextfieldComponent,
    BasicSpinnerComponent,
    ScrollToBottomDirectiveDirective,
    LoadingLogoComponent,
    SecurePipe,
    ClinkPipe,
    CeloUserCardComponent,
    IsValidStringPipe,
    RolesIconComponent,
    PossessivePipe,
    LinkifyPipe,
    TrimPipe,
    CeloSideMenuSubListComponent,
    PatientViewComponent,
    DepartmentPickerComponent,
    CeloContactCardComponent,
    CeloNoResultsFoundMessageComponent,
    CeloTopbarSearchInputComponent,
    CeloTopbarContactChipsComponent,
    CeloParticipantCardComponent,
    FileListViewComponent,
    QuotedMessageComponent,
    DropZoneComponent,
    FileUploadPreviewComponent,
    EditFieldsComponent,
    StringDateToDisplayFormatPipe,
    ConsentDownloadComponent,
    CustomDialogComponent,
    CopyBoxComponent,
    CeloButtonPairComponent,
    FeedbackPageComponent,
    IntegrationsPickerComponent,
    CeloPickParticipantsComponent,
    CeloUserStatusComponent,
    AccountsComponent,
    CeloCountriesComponent,
    UnauthorizedComponent,
    EmailerComponent,
    LinkShareComponent,
    AutofocusDirective,
    AddAWorkspaceComponent,
    AccountsComponent,
    CeloCountriesComponent,
    UnauthorizedComponent,
    EmailerComponent,
    LinkShareComponent,
    AutofocusDirective,
    ParticipantListComponent,
    EditPatientComponent,
    PatientIdLookupComponent,
    ProfileShareComponent,
    PatientDetailsComponent,
    PatientDetailsFormComponent,
    EditPatientDetailsDialogComponent,
    PhoneFormatPipe,
    ExternalChatDetailsComponent,
    AddCompanyDetailsComponent,
    PopupMenuComponent,
    UpgradeBannerComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    CeloMaterialModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    UrlHelperService,
    ImgOverlayService,
    SoundPlayService,
    ZipFilesService,
    DateFormatPipe
  ],
})
export class SharedModule { }
