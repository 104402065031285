<div class="celo-input-outline">
  <div class="flex">
    <img src="../../../assets/verify-your-profession.svg" alt="" />
  </div>
  <div class="title">Verify your profession</div>

  <mat-form-field class="fw" appearance="outline">
    <mat-label>Profession</mat-label>
    <input
      readonly
      matInput
      [ngModel]="myProfession ? myProfession : myCategory"
      autocomplete="off"
      name="profession"
      #profession="ngModel"
      maxlength="45"
    />
  </mat-form-field>
  <div
    *ngIf="
      countryCode.toLowerCase() === 'nz' || countryCode.toLowerCase() === 'gb'
    "
  >
    <div class="subtitle">
      We need either of the following options to verify your profession.
    </div>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Name of register</mat-label>
      <input
        matInput
        [(ngModel)]="registrationName"
        (ngModelChange)="onChange()"
        autocomplete="off"
        name="profession"
        #profession="ngModel"
        maxlength="45"
      />
    </mat-form-field>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Registration number</mat-label>
      <input
        matInput
        [(ngModel)]="registrationNumber"
        (ngModelChange)="onChange()"
        autocomplete="off"
        name="profession"
        #profession="ngModel"
        maxlength="45"
      />
    </mat-form-field>
    <div class="subtitle or">- OR -</div>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Registration link</mat-label>
      <input
        matInput
        [(ngModel)]="registrationLink"
        (ngModelChange)="onChange()"
        autocomplete="off"
        name="profession"
        #profession="ngModel"
      />
    </mat-form-field>
  </div>
  <div *ngIf="countryCode.toLowerCase() === 'au'">
    <div class="subtitle">Verify your profession using your AHPRA number.</div>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>AHPRA Number</mat-label>
      <input
        matInput
        [(ngModel)]="registrationNumber"
        placeholder="Enter your AHPRA number"
        autocomplete="off"
        name="profession"
        #profession="ngModel"
        maxlength="45"
        (ngModelChange)="onChange()"
      />
    </mat-form-field>
  </div>

  <div class="action">
    <button
      (click)="onVerifyLater()"
      mat-button
      class="celo-tertiary-button celo-hoverable"
    >
      Verify Later
    </button>
  </div>
</div>
