export interface UserLimitsResponseModelV3 {
  workspace_create_workspace?: boolean;
  /** @format int32 */
  max_pinned_conversations?: number;
  teams?: FeatureState;
  external_conversations?: FeatureState;
  video_calls?: FeatureState;
  export_cases?: FeatureState;
}

export enum FeatureState {
  Upgradeable = "Upgradeable",
  Disabled = "Disabled",
  Unauthorized = "Unauthorized",
  Enabled = "Enabled",
  Invisible = "Invisible",
}
