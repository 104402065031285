<!-- <mat-button-toggle-group class="w-100" #group="matButtonToggleGroup" [value]="selectedDepartment" [vertical]="true" (change)="onSelectDepartment(group.value)">
  <mat-button-toggle *ngFor="let dep of deps" [value]="dep.name">
    <div class="text-left">{{dep.name}}</div>
  </mat-button-toggle>
</mat-button-toggle-group> -->
<div class="nav-group">
  <button
    mat-button
    class="text-left"
    *ngFor="let dep of deps"
    [ngClass]="{ selected: selectedDepartment === dep.name }"
    (click)="onSelectDepartment(dep.name)"
  >
    {{ dep.name }}
  </button>
</div>
