<!-- <form class="nounderline">
    <div class="form-field">
      <select [(ngModel)]="selectedDepartment" name="departments">
        <option *ngFor="let department of filteredDepartments | async" [value]="department" (onSelectionChange)="departmentSelect($event, department)">
          {{department.name}}
        </option>
      </select>
    </div>
</form> -->
<form class="nounderline">
  <mat-form-field class="form-field">
    <mat-select
      [disabled]="!filteredDepartments"
      [(ngModel)]="selectedDepartment"
      name="departments"
    >
      <mat-option
        *ngFor="let department of filteredDepartments | async"
        [value]="department"
        (onSelectionChange)="departmentSelect($event, department)"
      >
        {{ department.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<!-- <form [ngClass]="{selected: selected}">
  <mat-form-field class="celo-less-bottom-margin">
    <input type="text"
           placeholder="Department"
           matInput
           (focus)="selected=!selected"
           (blur)="selected=!selected"
           [(ngModel)]="selectedDepartment"
           [formControl]="departmentCtrl"
           [matAutocomplete]="auto">
    <button matSuffix mat-icon-button
            *ngIf="!!(departmentCtrl.valueChanges|async) && !(departmentCtrl.valueChanges|async)?.name"
            (click)="departmentCtrl.setValue('')"><mat-icon>close</mat-icon></button>
    <button matSuffix mat-icon-button
            *ngIf="!(departmentCtrl.valueChanges|async) || (departmentCtrl.valueChanges|async)?.name"
            (click)="departmentCtrl.setValue('')"><mat-icon>keyboard_arrow_down</mat-icon></button>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        (onSelectionChange)="departmentSelect($event, department)"
        *ngFor="let department of filteredDepartments | async" [value]="department">
        {{department.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form> -->
