import { Component, Input } from "@angular/core";
import { FileItem } from "ng2-file-upload";

@Component({
  selector: "app-file-list-view",
  templateUrl: "./file-list-view.component.html",
  styleUrls: ["./file-list-view.component.scss"],
})
export class FileListViewComponent {
  @Input() matIcon;
  @Input() allowDescription = false;
  @Input() uploaderQueueItems: { fileItem: FileItem; description: string }[] =
    [];
}
