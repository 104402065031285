import {
  ConnectionModel,
  ConversationInvitationModel,
  ConversationType,
  IdentityVerificationStatus,
  MessageModel,
  ParticipantRole,
  PatientDataModel,
  UserBlockModel,
  UserProfessionModel,
  WorkplaceModel,
} from "./api-v2";

export interface ApiPagedResult<T> {
  /** @format date-time */
  pageProcessedOn?: string;
  previousPageUri?: string | null;
  nextPageUri?: string | null;

  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: T[] | null;
}

export interface MessageSkeletonModelV3 {
  marker: string;
  /** @format date-time */
  sentOnUtc: string;
}

export interface ConversationModelV3 {
  id: string;
  type?: ConversationType;
  subject?: string | null;
  name: string;
  createdBy: string;
  creatorName: string;
  /** @format date-time */
  createdOnUtc?: string;
  /** @format date-time */
  lastModifiedOnUtc?: string;
  lastMessage?: MessageModel;
  patientData?: PatientDataModel;
  isReal?: boolean;
  invitation?: ConversationInvitationModel;
  unreadMessageLimit?: number | null;
  teamIds: string[];
  photoId?: string;
  participants?: ConversationParticipantModelV3[] | null;
  unreadMessages?: MessageSkeletonModelV3[] | null;
}

export interface ConversationTeamDetailsModel {
  id: string;
  isNonTeamParticipant: boolean;
  teams: TeamSkeletonModelV3[];
}

export interface ConversationSkeletonModelV3 {
  id: string;
  type: ConversationType;
  teamDetails?: ConversationTeamDetailsModel | null;
}

export interface TeamSkeletonModelV3 {
  id: string;
  isMember: boolean;
}

export interface ConversationParticipantModelV3 {
  userId: string;
  profilePic?: string | null;
  title?: string | null;
  firstName: string;
  lastName: string;
  legalFirstName: string;
  /** The team this user is on call for */
  isOnCall?: string | null;
  professions?: UserProfessionModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus | null;
  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel | null;
  blockedByMe?: UserBlockModel | null;
  blockedMe?: UserBlockModel | null;
  /** @format date-time */
  leftOnUtc?: string | null;
  /** @format date-time */
  joinedOnUtc: string;
  isActive: boolean;
  isExternal: boolean;
  teamId?: string | null;
  /** @format date-time */
  latestReadMessageUtc?: string | null;
  /** @format date-time */
  mutedToUtc?: string | null;
  muteInterval?: number | null;
  isHidden: boolean;
  /** @format date-time */
  asReadToUtc?: string | null;
  role: ParticipantRole;
  /** @format date-time */
  pinnedOnUtc?: string | null;
}

export interface ClearUnreadConversationModelV3 {
  /** @format date-time */
  asReadToUtc: string;
}

export interface ConversationReadToUtcUpdateResponseModel {
  /** @format date-time */
  asReadToUtc: string;
}
