export type VisibilityChangeListener = (isVisible: boolean) => void;

// See: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
const getVisibilityChangeProperties = () => {
  let hidden: string | null = null;
  let visibilityChange: string | null = null;

  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document["msHidden"] !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document["webkitHidden"] !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  return { hidden, visibilityChange };
};

export const addVisibilityChangeEventListener = (
  listener: VisibilityChangeListener
): EventListenerOrEventListenerObject => {
  const { hidden, visibilityChange } = getVisibilityChangeProperties();

  if (hidden === null || visibilityChange === null) {
    // eslint-disable-next-line no-console
    console.error("This browser does not support the Page Visibility API.");
  } else {
    const internalListener = () => listener(!document[hidden]);
    document.addEventListener(visibilityChange, internalListener, false);
    return internalListener;
  }
};

export const removeVisibilityChangeEventListener = (
  listener: EventListenerOrEventListenerObject
) => {
  const { hidden, visibilityChange } = getVisibilityChangeProperties();

  if (hidden === null || visibilityChange === null) {
    // eslint-disable-next-line no-console
    console.error("This browser does not support the Page Visibility API.");
  } else {
    document.removeEventListener(visibilityChange, listener);
  }
};
