import { MonoTypeOperatorFunction, pipe, timer } from "rxjs";
import { map, retry, toArray } from "rxjs/operators";

export const flatMap = <T, O>(project: (value: T) => O[] | null | undefined) =>
  pipe(
    toArray<T>(),
    map((array) => array.flatMap((elem) => project(elem) ?? []))
  );

export function exponentialRetry<T>(
  count: number = 5,
  maxDelay: number = 2000
): MonoTypeOperatorFunction<T> {
  return retry<T>({
    count,
    delay: (error, retryCount) => {
      const delayTime = Math.min(2 ** retryCount * 200, maxDelay);
      return timer(delayTime);
    },
  });
}
