<div >
  <div >
    <textarea
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMaxRows="20"
      cdkAutosizeMinRows="3"
      #copyarea
      class="content"
      name=""
      id=""
      [(ngModel)]="content"
      readonly
    ></textarea>
  </div>

  <div class="button-bar mt-2">
    <button class="celo-primary-button" mat-button (click)="copy(content)">
      Click to copy
    </button>
  </div>
  <div cdkFocusInitial></div>
</div>
