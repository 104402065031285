import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@env";
import { AlertService, AuthService, SharedService } from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { getDisplayAppVersion } from "@utils";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { PinscreenService } from "./pinscreen.service";

@Component({
  selector: "app-pinscreen",
  templateUrl: "./pinscreen.component.html",
  styleUrls: ["./pinscreen.component.scss", "../../celo-input.scss"],
})
export class PinscreenComponent implements AfterViewInit, OnInit, OnDestroy {
  passcode = "";
  passcode0 = "";
  hasPassCode: boolean = null;
  showSpinner = true;
  attempts = 0;
  sub: any;
  public expired: boolean = false;
  version: string = "";
  production: boolean;

  public isSubmitting: boolean = false;

  public lockoutPeriodMinutes$: Observable<number | null> | null = of(null);

  @ViewChildren("myPasscode") private inputs: QueryList<
    ElementRef<HTMLInputElement>
  >;

  private input: ElementRef<HTMLInputElement> | null = null;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    public pinService: PinscreenService,
    public sharedService: SharedService,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.inputs.changes.subscribe({
      next: () => {
        if (this.hasPassCode) {
          this.input = this.inputs.first;
          this.input?.nativeElement.focus();
        }
      },
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  setState() {
    this.showSpinner = false;
    if (this.pinService.hasPasscode()) {
      this.hasPassCode = true;
    } else {
      this.hasPassCode = false;
    }
    this.setTitle();
  }

  setTitle() {
    if (this.hasPassCode) {
      this.sharedService.setTitle("Pin Lock | Celo");
    } else this.sharedService.setTitle("Set Pin | Celo");
  }

  ngOnInit() {
    this.expired = this.route.snapshot.queryParams["expired"];

    this.lockoutPeriodMinutes$ = this.pinService.lockoutPeriodSeconds$.pipe(
      map((value) => value / 60)
    );

    this.hasPassCode = true;
    this.version = getDisplayAppVersion();
    this.production = environment.production;
    this.setState();
    this.sub = this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (foucsed) {
        this.setState();
      }
    });
  }

  forgotPin() {
    this.showSpinner = true;
    this.logout("Forgot pin");
  }

  logout(reason: string) {
    this.sharedService.isLoading = true;
    this.authService.logout(reason);
  }

  onSubmit() {
    this.submit();
  }

  submit() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    if (this.isSubmitting) return;
    if (!this.passcode?.length) return;

    this.isSubmitting = true;
    this.input?.nativeElement?.blur();

    this.showSpinner = false;
    if (!this.pinService.hasPasscode()) {
      this.pinService.setOrUpdatePinAndUnlock(this.passcode).subscribe({
        next: () => {
          this.passcode = "";
          this.isSubmitting = false;
          this.hasPassCode = true;
        },
        error: (err) => {
          AlertComponent.openErrorDialog(
            this.matDialog,
            err instanceof Error ? err.message : null
          )
            .afterClosed()
            .subscribe({
              complete: () => {
                this.passcode = "";
                this.isSubmitting = false;
                this.input?.nativeElement?.focus();
              },
            });
        },
      });
      return;
    }

    this.pinService.checkPinAndUnlock(this.passcode).subscribe({
      next: (isMatch) => {
        if (isMatch) return;
        this.passcode = "";
        AlertComponent.openDialog(this.matDialog, {
          message: "Incorrect PIN. Please try again.",
          acceptOnly: true,
          acceptButtonText: "Close",
        })
          .afterClosed()
          .subscribe({
            complete: () => {
              this.passcode = "";
              this.isSubmitting = false;
              this.input?.nativeElement?.focus();
            },
          });
        this.attempts++;
        if (this.attempts > 2) {
          this.logout(`User entered incorrect pin ${this.attempts} time(s)`);
        }
      },
      error: (err) => {
        console.error(err);
        this.passcode = "";
        this.isSubmitting = false;
        this.alertService.error();
      },
    });
  }
}
