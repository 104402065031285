<div class="logo-container">
  <a [routerLink]="['/conversations']" class="logo">
    <img src="/assets/logo-v2-invert.svg" />
  </a>
</div>
<div class="left-contents">
  <ng-container
    *ngIf="{
      isAuthenticated: isAuthenticated$ | async,
      teams: teams$ | async,
      userLimits: userLimits$ | async
    } as tabData"
  >
    <div *ngFor="let tab of tabs" class="tabitem">
      <a
        *ngIf="
          tab.isActive &&
            (tab.id !== ROLES_TAB_ID ||
              (tabData.isAuthenticated &&
                (tabData.teams?.length === 0 || tabData.teams?.length === 1)));
          else rolesMenuTab
        "
        matRipple
        [routerLink]="
          tab.id === ROLES_TAB_ID
            ? tabData.teams?.length === 0
              ? null
              : [tab.link, tabData.teams[0].id]
            : tab.link
        "
        routerLinkActive="active"
        #rla="routerLinkActive"
        class="tab-link celo-hoverable-light celo-cursor-pointer"
        [id]="tab.id"
        (click)="tabClicked(tab, tabData)"
      >
        <div class="tab-link-content">
          <span>{{ tab.name }}</span>
          <span *ngIf="tab.notifications">
            <span class="notification">{{ tab.notifications }}</span>
          </span>
        </div>
      </a>

      <ng-template #rolesMenuTab>
        <ng-container
          *ngIf="
            tab.isActive &&
            tab.id === ROLES_TAB_ID &&
            tabData.isAuthenticated &&
            tabData.teams?.length > 1
          "
        >
          <a
            matRipple
            class="tab-link celo-hoverable-light celo-cursor-pointer"
            [id]="tab.id"
            (click)="tabClicked(tab, tabData); rolesMenu.toggle()"
          >
            <div class="tab-link-content">
              <span>{{ tab.name }}</span>
              <span *ngIf="tab.notifications">
                <span class="notification">{{ tab.notifications }}</span>
              </span>
              <mat-icon>expand_more</mat-icon>
            </div>
          </a>

          <app-roles-menu
            #rolesMenu
            class="roles-menu"
            (team)="handleTeamClicked($event)"
          ></app-roles-menu>
        </ng-container>
      </ng-template>
    </div>

    <div *ngFor="let tab of role_tabs" class="tabitem">
      <a
        matRipple
        [routerLink]="tab.link"
        routerLinkActive="active"
        #rla="routerLinkActive"
        class="tab-link celo-hoverable-light"
        [id]="tab.id"
        (click)="tabClicked(tab)"
      >
        <div class="tab-link-content">
          <span>{{ tab.name }}</span>
        </div>
      </a>
    </div>
  </ng-container>
</div>

<div class="right-menu-grid">
  <a
    *ngIf="
      (plan$ | async) === SubscriptionTier.Free && isAllowedToDisplayUpgrade$
        | async
    "
    mat-ripple
    [href]="upgradeUrl"
    class="upgrade-button uppercase"
    >Upgrade</a
  >

  <ng-container
    *ngIf="
      (plan$ | async) !== SubscriptionTier.Free &&
      (isAllowedToDisplayUpgrade$ | async) &&
      (isTrial$ | async)
    "
  >
    <div class="trial-countdown">
      <app-trial-countdown
        [trialEndDate]="trialEndDate$ | async"
      ></app-trial-countdown>
    </div>

    <button
      *ngIf="!(hasBillingMethod$ | async) && isBillingOwner"
      mat-ripple
      class="add-billing-info-button uppercase"
      (click)="addBillingInfo()"
      [disabled]="isCustomerPortalSessionLoading"
    >
      <ng-container
        *ngIf="isCustomerPortalSessionLoading; else addBillingInfoText"
      >
        <app-basic-spinner [diameter]="20"></app-basic-spinner>
      </ng-container>

      <ng-template #addBillingInfoText>Add Billing Info</ng-template>
    </button>
  </ng-container>

  <button
    id="notificationMenu"
    [matMenuTriggerFor]="notificationMenu"
    mat-icon-button
    class="notification-icon"
    (click)="showNotifications()"
  >
    <mat-icon [svgIcon]="'icon-notifications'"></mat-icon>
    <svg
      *ngIf="
        notificationsService.unseenActivityFeed ||
        notificationsService.unseenConnections
      "
      class="badge"
      viewBox="0 0 10 10"
    >
      <circle cx="5" cy="5" r="5" stroke="white" stroke-width="2" fill="red" />
    </svg>
  </button>
  <mat-menu
    class="notifications mat-notifications"
    #notificationMenu="matMenu"
    xPosition="before"
    backdropClass="notifications"
  >
    <div class="list">
      <app-notifications-dropdown
        *ngIf="userAccount"
        [pendingConnections]="profileService.pendingConnections"
        [userAccount]="userAccount"
      ></app-notifications-dropdown>
    </div>
  </mat-menu>
  <div
    id="nav-menu"
    matRipple
    class="profile_button celo-hoverable-light"
    [matMenuTriggerFor]="menu"
    (click)="menuCliked()"
  >
    <div *ngIf="userAccount" class="icon">
      <app-avatar
        [name]="userAccount.firstName + ' ' + userAccount.lastName"
        [userId]="userAccount.userId"
        [width]="36"
        [height]="36"
        [hasImage]="!!userAccount.picture"
        [userId]="userAccount.userId"
        [nocache]="true"
        [refreshCount]="avatarRefreshCount"
        [showBadge]="true"
        [badgeSize]="18"
        [workspaces]="userAccount.workplaces"
        [identityVerificationStatus]="userAccount.identityVerificationStatus"
        [showTooltip]="false"
        [marginRight]="-5"
        [user]="userAccount"
      ></app-avatar>
    </div>
    <div class="name">
      <div class="texts">
        <div *ngIf="userAccount" class="text">
          {{ userAccount.firstName }} {{ userAccount.lastName }}
        </div>
        <div *ngIf="!userAccount" class="text">Profile Info</div>
        <div
          [style.width.px]="userAccount && userAccount.doNotDisturb ? 170 : 0"
          [style.height.px]="userAccount && userAccount.doNotDisturb ? 13 : 0"
          class="subtext celo-open-horizontal"
        >
          You are set to ‘Do not disturb’
        </div>
      </div>
      <span class="arrowicon">
        <mat-icon [svgIcon]="'icon-arrow-down'"></mat-icon>
      </span>
    </div>
  </div>
  <mat-menu #menu="matMenu" class="profile-menu">
    <div
      *ngIf="userAccount"
      mat-menu-item
      class="profile"
      routerLink="/profile"
    >
      <div class="celo-menu-item-wrapper">
        <div class="avatar">
          <app-avatar
            *ngIf="userAccount.userId"
            [name]="userAccount.firstName + ' ' + userAccount.lastName"
            [userId]="userAccount.userId"
            [width]="40"
            [height]="40"
            [hasImage]="!!userAccount.picture"
            [nocache]="true"
            [refreshCount]="avatarRefreshCount"
          ></app-avatar>
        </div>
        <div class="text">
          <div class="name">
            {{ userAccount.firstName }} {{ userAccount.lastName }}
          </div>
          <div class="subname">View your profile</div>
        </div>
      </div>
    </div>

    <!-- Plan info -->
    <ng-container
      *ngIf="(plan$ | async) !== SubscriptionTier.Free; else upgradeYourPlan"
    >
      <div
        mat-menu-item
        [disableRipple]="true"
        class="flex flex-col g-2 heading plan"
      >
        <div class="title">Plan</div>
        <div class="description">
          Your workspace is currently on Celo's
          {{ planDisplayName$ | async }} subscription.<a
            *ngIf="paidCompany$ | async"
            class="celo-link"
            [routerLink]="['network', 'workspace', (paidCompany$ | async).id]"
            >View workspace</a
          >.
        </div>
      </div>

      <a
        *ngIf="isAdminPortalLinkEnabled"
        [href]="workspaceAdminUrl"
        target="_blank"
      >
        <button mat-menu-item>
          <div class="menu-item">
            <img
              class="svgIcon"
              src="../../assets/icons/celo-celo-blue.svg"
              alt="Admin Portal"
            />
            <span class="text">Admin Portal</span>
          </div>
        </button>
      </a>
    </ng-container>

    <ng-template #upgradeYourPlan>
      <a
        *ngIf="isAllowedToDisplayUpgrade$ | async"
        [href]="upgradeUrl"
        target="_blank"
      >
        <button mat-menu-item>
          <div class="menu-item flex flex-row items-center">
            <img
              class="svgIcon"
              src="../../assets/icons/celo-celo-blue.svg"
              alt="Admin Portal"
            />
            <div class="flex flex-col menu-item-label g-2">
              <div class="menu-item-title">Upgrade your plan</div>
              <div class="menu-item-description">Current Plan: Celo Free</div>
            </div>
          </div>
        </button>
      </a>
    </ng-template>

    <div mat-menu-item disabled class="heading">
      <div class="title">Status</div>
    </div>
    <button mat-menu-item (click)="doNotDisturbClick()">
      <div class="menu-item">
        <div class="dnd">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-dnd-v2.svg"
            alt=""
          />
          <div class="content">
            <div class="contenttitle">Do not disturb</div>
            <div
              *ngIf="
                userAccount &&
                userAccount.doNotDisturb &&
                userAccount.doNotDisturbToUtc &&
                !userAccount.doNotDisturbIndefinite
              "
              class="contentsubtitle"
            >
              Until
              {{
                userAccount.doNotDisturbToUtc | dateFormat: "relativeDateTime"
              }}
            </div>
          </div>
          <span class="slider">
            <mat-slide-toggle
              hideIcon
              #slider
              [checked]="userAccount ? userAccount.doNotDisturb : false"
            ></mat-slide-toggle>
          </span>
        </div>
      </div>
    </button>
    <div mat-menu-item disabled class="heading">
      <div class="title">Settings</div>
    </div>
    <a routerLink="/edit/general">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-general-v2.svg"
            alt=""
          />
          <span class="text">General</span>
        </div>
      </button>
    </a>

    <a routerLink="/edit/privacy">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-privacy-v3.svg"
            alt=""
          />
          <span class="text">Privacy</span>
        </div>
      </button>
    </a>

    <a routerLink="/edit/security">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-security-v2.svg"
            alt=""
          />
          <span class="text">Security</span>
        </div>
      </button>
    </a>

    <a routerLink="/edit/notifications">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/more-notifications.svg"
            alt=""
          />
          <span class="text">Notifications</span>
        </div>
      </button>
    </a>

    <div mat-menu-item disabled class="heading">
      <div class="title">Help & Support</div>
    </div>
    <a href="https://support.celohealth.com/hc/en-us" target="_blank">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-helpcenter-v2.svg"
            alt=""
          />
          <span class="text">Help Centre</span>
        </div>
      </button>
    </a>

    <a href="https://www.celohealth.com/contact-us" target="_blank">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-contactus-v2.svg"
            alt=""
          />
          <span class="text">Contact Us</span>
        </div>
      </button>
    </a>

    <a href="https://www.celohealth.com/legal" target="_blank">
      <button mat-menu-item>
        <div class="menu-item">
          <img
            class="svgIcon"
            src="../../assets/icons/terms-of-use-v2.svg"
            alt=""
          />
          <span class="text">Privacy & Terms</span>
        </div>
      </button>
    </a>
    <button mat-menu-item (click)="addColleagues()">
      <div class="menu-item">
        <img
          class="svgIcon"
          src="../../assets/icons/icon-invitecolleagues-v2.svg"
          alt=""
        />
        <span class="text">Add Colleagues</span>
      </div>
    </button>
    <div class="title">&nbsp;</div>
    <button mat-menu-item (click)="lock()" id="celo-lock-button">
      <div class="menu-item celo-red-text celo-menu-item-wrapper">
        <mat-icon class="celo-red-text material-icons">lock</mat-icon>
        <span class="celo-red-text">Lock</span>
      </div>
    </button>
    <button mat-menu-item (click)="logout()" id="celo-logout-button">
      <div class="menu-item celo-red-text celo-menu-item-wrapper">
        <mat-icon class="material-icons celo-red-text">exit_to_app</mat-icon>
        <span class="celo-red-text">Log out</span>
      </div>
    </button>
  </mat-menu>
</div>
