<button
  *ngIf="hasCloseButton"
  mat-icon-button
  aria-label="Close"
  class="close"
  (click)="closeClick.emit()"
>
  <mat-icon>close</mat-icon>
</button>

<ng-content select="[image]"></ng-content>

<h1
  *ngIf="title"
  class="title pb-16"
  [style.fontSize]="titleFontSize"
  [innerText]="title"
></h1>

<p class="pb-20 content">
  <ng-content select="[content]"></ng-content>
</p>

<div class="buttons flex flex-col w-full g-10px">
  <ng-container *ngIf="primaryButtonHref; else primaryButton">
    <a
      mat-button
      class="celo-primary-button uppercase"
      [href]="primaryButtonHref"
      [disabled]="isPrimaryButtonLoading"
    >
      <app-basic-spinner
        *ngIf="isPrimaryButtonLoading"
        [diameter]="20"
      ></app-basic-spinner>
      <ng-container *ngIf="!isPrimaryButtonLoading">{{
        primaryButtonText
      }}</ng-container>
    </a>
  </ng-container>

  <ng-template #primaryButton>
    <button
      mat-button
      class="celo-primary-button uppercase"
      (click)="primaryClick.emit()"
      [disabled]="isPrimaryButtonLoading"
    >
      <app-basic-spinner
        *ngIf="isPrimaryButtonLoading"
        [diameter]="20"
      ></app-basic-spinner>
      <ng-container *ngIf="!isPrimaryButtonLoading">{{
        primaryButtonText
      }}</ng-container>
    </button>
  </ng-template>

  <button
    *ngIf="secondaryButtonText"
    mat-button
    [ngClass]="{
      'celo-tertiary-button': !isPrimaryButtonLoading,
      'celo-disabled': isPrimaryButtonLoading
    }"
    (click)="secondaryClick.emit()"
    [innerText]="secondaryButtonText"
    [disabled]="isPrimaryButtonLoading"
  ></button>
</div>
