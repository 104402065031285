import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, pipe } from "rxjs";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import {
  ApiRequestOptions,
  DoNotDisturbUpdate,
  FullUserProfileModel,
  GetUserWorkspacesOptions,
  Role,
  UserWorkspaceModel,
  UserWorkspaceModelApiPagedResult,
} from "types";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

export const USER_SERVICE = new InjectionToken<UserServiceProvider>(
  "USER_SERVICE"
);

/**
 * Provides access to information on the currently authenticated user (if any)
 */
export interface UserServiceProvider {
  currentUser$: Observable<FullUserProfileModel | null>;
  userId$: Observable<string | null>;
  isWorkspaceAdmin$: Observable<boolean>;

  updateIsWorkspaceAdminStatus(): void;
  getUserObservable(): Observable<FullUserProfileModel>;
  loadUser(): void;
  getUser(): FullUserProfileModel | null;
  getUserId(required?: boolean): string | null;
  getUserWorkspaces(
    options?: GetUserWorkspacesOptions & ApiRequestOptions
  ): Observable<UserWorkspaceModelApiPagedResult>;
  getUserWorkspace(workspaceId: string): Observable<UserWorkspaceModel>;
  getCountryCode(): string | null;
  isDoNotDisturbActive(): boolean;
}

/**
 * Provides access to information on the currently authenticated user (if any)
 */
@Injectable({
  providedIn: "root",
})
export class UserService implements OnDestroy, UserServiceProvider {
  private currentUserSubject = new BehaviorSubject<FullUserProfileModel | null>(
    null
  );

  public currentUser$ = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public userId$ = this.currentUserSubject.asObservable().pipe(
    map((value) => value?.userId ?? null),
    distinctUntilChanged()
  );

  private isAuthenticatedSubscription: Subscription;
  private getUserSubscription: Subscription | null = null;

  private isWorkspaceAdminSubject = new BehaviorSubject<boolean>(false);
  public isWorkspaceAdmin$ = this.isWorkspaceAdminSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {
    // Update data when authentication state changes
    this.isAuthenticatedSubscription =
      this.authService.isAuthenticated$.subscribe({
        next: this.loadData.bind(this),
      });

    this.loadData();
  }

  private loadData() {
    this.updateIsWorkspaceAdminStatus();
    this.loadUser();
  }

  public handleDoNotDisturbUpdate(update: DoNotDisturbUpdate) {
    const user = this.getUser();
    if (!user) return;
    const clone = structuredClone(user);
    user.doNotDisturbToUtc = update.doNotDisturbToUtc;
    this.currentUserSubject.next(clone);
  }

  public ngOnDestroy(): void {
    this.isAuthenticatedSubscription.unsubscribe();
  }

  public updateIsWorkspaceAdminStatus = () => {
    if (!this.authService.isAuthenticated()) {
      this.isWorkspaceAdminSubject.next(false);
      return;
    }
    const isWorkspaceAdmin =
      this.authService.hasRole(Role.WorkspaceAdmin) ||
      this.authService.hasRole(Role.SuperAdmin);
    this.isWorkspaceAdminSubject.next(isWorkspaceAdmin);
  };

  public getUserObservable() {
    return this.apiService
      .get<FullUserProfileModel>({ path: "/api/v2/User" })
      .pipe(this.userTap());
  }

  private userTap() {
    return pipe(
      tap<FullUserProfileModel>({
        next: (user) => {
          this.currentUserSubject.next(user);
        },
      }),
      switchMap(() => this.currentUser$)
    );
  }

  public loadUser() {
    if (!this.authService.isAuthenticated()) {
      this.currentUserSubject.next(null);
      return;
    }
    this.getUserSubscription?.unsubscribe();
    this.getUserSubscription = this.getUserObservable().subscribe();
  }

  public getUser(): FullUserProfileModel | null {
    return this.currentUserSubject.value;
  }

  public getUserId(required?: false): string | null;
  public getUserId(required: true): string;
  public getUserId(required?: boolean): string | null {
    const userId =
      this.currentUserSubject.value?.userId ??
      this.authService.getUserId() ??
      null;
    if (required && !userId) throw new Error("Failed to get userId");
    return userId;
  }

  public getUserWorkspaces({
    fetchAll,
    ...options
  }: GetUserWorkspacesOptions &
    ApiRequestOptions = {}): Observable<UserWorkspaceModelApiPagedResult> {
    const path = `/api/v2/User/workspaces`;
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...options },
      });
    }
    return this.apiService.get({ path, queryParams: { ...options } });
  }

  public getUserWorkspace(workspaceId: string): Observable<UserWorkspaceModel> {
    const path = `/api/v2/User/workspaces/${workspaceId}`;
    return this.apiService.get({ path });
  }

  public getCountryCode(): string | null {
    const user = this.currentUserSubject.value;
    return user?.countryCode ?? null;
  }

  public isDoNotDisturbActive(): boolean {
    const user = this.getUser();
    if (!user || !user.doNotDisturbToUtc) return false;
    return new Date(user.doNotDisturbToUtc) >= new Date();
  }
}
