<div class="container">
  <button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="mat-h2">New External Chat</h2>

  <h4 class="mat-h4">Recipient Information</h4>

  <app-patient-details-form
    [disabled]="isSubmitting"
    (patientDetails)="onSubmit($event)"
    (isValid)="isPatientDetailsValid = $event"
  >
    <div class="mat-caption">
      Celo will use this information to begin a conversation with the external
      recipient.
    </div>

    <button
      mat-button
      class="celo-primary-button"
      type="submit"
      [disabled]="!isPatientDetailsValid || isSubmitting"
    >
      <ng-container *ngIf="!isSubmitting; else loading">
        Create external chat
      </ng-container>
      <ng-template #loading>
        <div class="spinner-container">
          <app-basic-spinner diameter="20" strokeWidth="2"></app-basic-spinner>
        </div>
      </ng-template>
    </button>
  </app-patient-details-form>
</div>
