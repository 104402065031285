import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";

@Component({
  selector: "app-copy-box",
  templateUrl: "./copy-box.component.html",
  styleUrls: ["./copy-box.component.scss"],
})
//TODO: REMOVE THIS
export class CopyBoxComponent {
  @Input() content: string;
  @Output() copied = new EventEmitter();
  @ViewChild("autosize", { static: true }) autosize: CdkTextareaAutosize;

  copy(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.copied.emit();
  }
}
