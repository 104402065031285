<button mat-button mat-dialog-close class="celo-close">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title class="celo-title">Choose Consent</div>
<div mat-dialog-content style="min-width: 500px">
  <div class="alert alert-success">
    Consents can be created on the Celo mobile app
  </div>

  <app-empty-state
    [title]="'No Consent Forms'"
    [message]="
      'This patient doesn\'t have any consents from the last ' +
      minDays +
      ' days'
    "
    [isEmpty]="consents.length === 0"
    [isLoading]="showSpinner"
  >
  </app-empty-state>

  <mat-list *ngIf="consents && consents.length && !showSpinner">
    <h3 mat-subheader>Consents</h3>
    <mat-list-item
      class="consent-link"
      *ngFor="let consent of consents"
      (click)="select(consent)"
    >
      <mat-icon mat-list-icon class="pdf-icon">picture_as_pdf</mat-icon>
      <h3 mat-line class="uid">{{ consent.patientData.uid }}</h3>
      <h4 mat-line class="creator">
        {{ consent.creator?.title }} {{ consent.creator?.firstName }}
        {{ consent.creator?.lastName }}
      </h4>
      <p mat-line class="date">
        {{ consent.createdOn | dateFormat: "medium" }}
      </p>
    </mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>
</div>
