import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-trial-ended-cta",
  templateUrl: "./trial-ended-cta.component.html",
  styleUrls: ["./trial-ended-cta.component.scss"],
})
export class TrialEndedCtaComponent {
  @Input() public upgradeUrl: string;

  @Output() public downgradeClick = new EventEmitter<void>();
}
