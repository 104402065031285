import { Pipe, PipeTransform } from "@angular/core";
import { SubscriptionTier } from "@types";
import { getPlanDisplayName } from "@utils";

@Pipe({
  name: "planDisplayName",
})
export class PlanDisplayNamePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) return value;
    try {
      return getPlanDisplayName(value as SubscriptionTier) ?? value;
    } catch {
      return value;
    }
  }
}
