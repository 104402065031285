<div class="celo-input-outline">
  <div class="flex">
    <img src="../../../assets/profession-success.svg" alt="" />
  </div>
  <div class="text">
    <div class="title">Your profession is being verified</div>
    <div class="subtitle">
      We will let you know when the verification process is complete.
    </div>
    <div>&nbsp;</div>
    <div class="subtext">
      Have a question? Please email us at
      <a class="celo-link" href="mailto:support@celohealth.com"
        >support@celohealth.com</a
      >.
    </div>
  </div>
</div>
