<div class="fw celo-input-outline">
  <mat-form-field class="fw" appearance="outline">
    <mat-label>Profession</mat-label>
    <mat-select
      id="profession-select"
      [(ngModel)]="profession"
      (ngModelChange)="
        onProfessionSelected($event); filter.value = ''; onKey('')
      "
      (openedChange)="filter.value = ''; onKey(''); filter.focus()"
      required
      [disabled]="professionReadonly"
      name="profession_field"
      #profession_field
    >
      <mat-form-field class="filter">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          #filter
          (keyup)="onKey($event.target.value)"
          (keydown)="$event.stopPropagation()"
          placeholder="Search"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-option
        *ngFor="let profession_ob of selectedProfessions"
        [value]="profession_ob"
        >{{ profession_ob.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field
    *ngIf="profession && profession.allowFreeformProfession"
    class="fw"
    appearance="outline"
  >
    <mat-label>Specify Profession</mat-label>
    <input
      id="filter"
      matInput
      [(ngModel)]="profession_specify"
      (ngModelChange)="inputChange()"
      autocomplete="off"
      name="profession_specify_field"
      id="profession_specify_field"
      #profession_specify_field="ngModel"
      [pattern]="regex"
      maxlength="45"
      [disabled]="professionReadonly"
    />
  </mat-form-field>
  <div *ngIf="myCategory">
    <div
      *ngIf="
        (status === 'Verified' || status === 'Pending') &&
        (!edited || !hasChanged())
      "
    >
      <mat-form-field class="fw" appearance="outline">
        <mat-label>Status</mat-label>
        <input
          matInput
          class="disabled-input"
          [(ngModel)]="status"
          disabled
          autocomplete="off"
          name="profession_status"
          #profession_status="ngModel"
        />
        <div matSuffix *ngIf="status === 'Verified'">
          <app-badge
            class="inline-block"
            [identityVerificationStatus]="'Verified'"
            [profession]="{ verificationStatus: 'Verified' }"
            [size]="20"
            [clickable]="false"
          ></app-badge>
        </div>
      </mat-form-field>
    </div>
    <div class="action-buttons">
      <div
        *ngIf="
          ((status !== 'Verified' && status !== 'Pending' && !edited) ||
            (valid && edited)) &&
          profession &&
          profession.canVerify
        "
      >
        <button
          mat-button
          class="celo-tertiary-button celo-hoverable"
          (click)="verifyProfessionClick(profession)"
        >
          Verify profession
        </button>
      </div>
      <div *ngIf="editMode">
        <button
          mat-button
          class="celo-danger-button celo-hoverable"
          (click)="removeProfessionClick(profession)"
        >
          Remove profession
        </button>
      </div>
    </div>
    <div class="subtext">
      Have a question? Please email us at
      <a class="celo-link" href="mailto:support@celohealth.com"
        >support@celohealth.com</a
      >.
    </div>
  </div>
</div>
