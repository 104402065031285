<div>
  <div class="title pb-3 appear_anim_0">Edit Workspace Info</div>
  <div class="content pt-3 pb-3 appear_anim_2">
    <app-companies-list
      [companies]="companies"
      [responsive]="false"
      [editable]="true"
      (edit)="edit($event)"
    ></app-companies-list>
  </div>
  <div class="appear_anim_4 pt-3 pb-3">
    <button mat-button (click)="joinWorkspace()" class="celo-primary-button">
      <span>Add Workspace</span>
    </button>
  </div>
</div>
