<div #editorContainer id="editorContainer" class="editor_container">
  <div
    id="textarea"
    class="content_editable_element"
    data-placeholder="Type a message"
    contenteditable="true"
    #txtArea
    [innerHtml]="contentHtml"
    [style.max-width.px]="editorContainer.offsetWidth"
    (input)="textChangeEvent.emit($event.target.innerText); change()"
    (keydown.enter)="$event.preventDefault()"
    (keyup.enter)="returnPressed()"
    (paste)="onPaste($event)"
    (keydown)="onChange($event)"
    (keyup.arrowup)="change()"
    (keyup.arrowleft)="change()"
    (keyup.arrowdown)="change()"
    (keyup.arrowright)="change()"
    (click)="change()"
  ></div>
</div>
<div
  *ngIf="
    showSuggestion && participantsList && participantsList.length && userAccount
  "
  class="mentionables"
  #mentionables
  id="mentionables"
  [style.bottom.px]="txtArea.clientHeight ? txtArea.clientHeight : 45"
>
  <app-participant-list
    [participantsList]="participantsList"
    [userId]="userAccount.userId"
    [selectable]="true"
    [searchQuery]="lastWordBlock"
    (newMentionClicked)="onNewMentionClicked($event)"
  ></app-participant-list>
</div>
