import { Directive, ElementRef, HostListener } from "@angular/core";

const allowedKeys = new Set([
  "Backspace",
  "Delete",
  "Tab",
  "ArrowLeft",
  "ArrowRight",
]);

const allowedControlKeys = new Set(["a", "v"]);

@Directive({
  selector: "[appNumericPassword]",
})
export class NumericPasswordDirective {
  public constructor(private el: ElementRef) {}

  // Prevent non-numeric input
  @HostListener("keydown", ["$event"])
  public onKeyDown(event: KeyboardEvent) {
    if (
      (event.ctrlKey || event.metaKey) &&
      allowedControlKeys.has(event.key.toLowerCase())
    ) {
      return;
    }

    if (/[0-9]/.test(event.key) || allowedKeys.has(event.key)) {
      return;
    }

    event.preventDefault();
  }

  @HostListener("input", ["$event"])
  public onChange(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    if (!target) return;

    const value = target.value;
    if (!value) return;

    if (/^[0-9]+$/.test(value)) return;
    target.value = "";
  }

  // Prevent pasting non-numeric characters
  @HostListener("paste", ["$event"])
  public onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData("text") || "";

    if (/^[0-9]+$/.test(pastedText)) return;
    event.preventDefault();
  }

  // Prevent copying content
  @HostListener("copy", ["$event"])
  public onCopy(event: ClipboardEvent) {
    event.preventDefault();
  }
}
