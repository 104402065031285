import { Component } from "@angular/core";

@Component({
  selector: "app-network-no-results",
  templateUrl: "../../../assets/network-no-results.svg",
  styleUrls: ["./network-no-results.component.scss"],
})
export class NetworkNoResultsComponent {
  constructor() {}
}
