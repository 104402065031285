// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { defaultEnvironment, Environment } from "./environment.default";

export const environment: Environment = {
  ...defaultEnvironment,
  production: false,
  celoAuth: "59ac8c4bb45f5b0001e38d913d00e8f4a95b45a46ab694d4b8fbdef3",

  celoApiEndpoint: "https://celo-api-dev.celohealth.com",
  celoBroadcastApiEndpoint: "https://celo-broadcast-dev.celohealth.com",
  celoAuthApiEndpoint: "https://login-dev.celohealth.com",
  celoSocketLocation: "https://celo-messaging-dev.celohealth.com/messagingHub",
  origin: "https://devapp.celohealth.com",
  invitationOrigin: "link-dev.celohealth.com",

  clientId: "celo.desktop.6CC9BEAE2341",
  emailThrottleInSeconds: 30,
  env: "dev",

  workspaceAdminUrl: "https://workspace-admin-dev.celohealth.com/",
  applicationInsightsConnectionString:
    "InstrumentationKey=7c233ad3-872b-4946-94b5-73431ef4f70a;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/",

  firebaseConfig: {
    apiKey: "AIzaSyDw5uexXjgdmQ3IVdzzWhqmVnyI3tDYx2M",
    authDomain: "celo-1470698531705.firebaseapp.com",
    databaseURL: "https://celo-1470698531705.firebaseio.com",
    projectId: "celo-1470698531705",
    storageBucket: "celo-1470698531705.appspot.com",
    messagingSenderId: "564504357537",
    appId: "1:564504357537:web:f1b11015adb64027c31ee0",
    measurementId: "G-MS32MM395T",
  },
};
