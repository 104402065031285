import { SubscriptionTier } from "@types";

const SubscriptionTierToDisplayNameMap: {
  [K in SubscriptionTier]: string;
} = {
  [SubscriptionTier.Free]: "Free",
  [SubscriptionTier.Business]: "Business (Legacy)",
  [SubscriptionTier.Enterprise]: "Enterprise",
  [SubscriptionTier.Growth]: "Growth",
  [SubscriptionTier.BusinessV2]: "Business",
  [SubscriptionTier.BusinessPro]: "Business Pro",
};

export const getPlanDisplayName = (plan: SubscriptionTier) =>
  SubscriptionTierToDisplayNameMap[plan];
