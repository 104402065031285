import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@modules/core";

@Component({
  selector: "app-register-login-oidc",
  templateUrl: "./register-login-oidc.component.html",
  styleUrls: ["./register-login-oidc.component.scss"],
})
export class RegisterLoginOidcComponent {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authService.setIsOnboarding(true);
    this.authService.loginWithoutPrompt();
  }
}
