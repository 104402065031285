<app-basic-cta
  [title]="title"
  [primaryButtonText]="primaryButtonText"
  [secondaryButtonText]="secondaryButtonText"
  [isPrimaryButtonLoading]="isStartTrialLoading"
  [primaryButtonHref]="upgradeUrl"
  (primaryClick)="startTrial.emit()"
  (secondaryClick)="ignoreTrial.emit()"
  [padding]="'30px 65px 45px 65px'"
>
  <img
    image
    ngSrc="../../../../assets/ctas/cta-upgrade@2x.png"
    width="250"
    height="250"
    priority
    placeholder
  />

  <ng-container content>
    Explore the full potential of Celo with enhanced tools and premium features,
    available for a limited time. No payment or credit card required.
  </ng-container>
</app-basic-cta>
