<div class="content">
  <div class="banner-message">
    You are currently using the free version of Celo. Upgrade to Celo Business
    to unlock more features!
  </div>
  <a mat-ripple class="upgrade" [href]="upgradeUrl" [target]="target">
    Upgrade
  </a>
</div>
<button type="button" class="close" mat-icon-button (click)="close.emit()">
  <mat-icon>close</mat-icon>
</button>
