import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SharedService } from "../../modules/core";

@Component({
  selector: "app-verify-profession",
  templateUrl: "./verify-profession.component.html",
  styleUrls: ["./verify-profession.component.scss", "../../../celo-input.scss"],
})
export class VerifyProfessionComponent implements OnInit {
  @Input() countryCode = "nz";
  @Input() myCategory = "";
  @Input() myProfession = "";
  registrationName = "";
  registrationNumber = "";
  registrationLink = "";

  @Output() dataChange = new EventEmitter<any>();
  @Output() validity = new EventEmitter<any>();
  @Output() verifyLater = new EventEmitter<any>();

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.validity.emit(false);
  }

  getObject() {
    return {
      category: this.myCategory,
      profession: this.myProfession,
      registrationName: this.registrationName,
      registrationNumber: this.registrationNumber,
      registrationLink: this.registrationLink,
    };
  }

  onVerifyLater() {
    const obj = this.getObject();
    this.verifyLater.emit(obj);
  }

  onChange() {
    setTimeout(() => {
      this.sanitiseFields();
      this.emit();
    }, 100);
  }

  emit() {
    const obj = this.getObject();
    obj["verify"] = true;
    this.dataChange.emit(obj);
    let validity = false;
    if (
      this.countryCode.toLowerCase() == "nz" ||
      this.countryCode.toLowerCase() == "gb"
    ) {
      if (
        (this.registrationName && this.registrationNumber) ||
        this.registrationLink
      ) {
        validity = true;
      }
    } else if (this.countryCode == "au") {
      if (this.registrationNumber) {
        validity = true;
      }
    }
    this.validity.emit(validity);
  }

  sanitiseFields() {
    this.registrationName = this.sharedService.removeInvalidChars(
      this.registrationName
    );
    this.registrationNumber = this.sharedService.removeInvalidChars(
      this.registrationNumber
    );
    this.registrationLink = this.sharedService.removeInvalidChars(
      this.registrationLink
    );
  }
}
