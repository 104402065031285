import { environment } from "environments/environment";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  EditPatientDetailsData,
  EditPatientDetailsDialogComponent,
  EditPatientDetailsResult,
  EditPatientDetailsUpdateFn,
} from "./../edit-patient-details-dialog/edit-patient-details-dialog.component";
import { PatientDetails } from "../patient-details-form/patient-details-form.component";

@Component({
  selector: "app-patient-details",
  templateUrl: "./patient-details.component.html",
  styleUrls: ["./patient-details.component.scss"],
})
export class PatientDetailsComponent implements OnInit {
  @Input() public lastName: string = "";
  @Input() public firstName: string = "";
  @Input() public dateOfBirth: string = "";
  @Input() public phoneNumber: string = "";
  @Input() public updateFn: EditPatientDetailsUpdateFn | null = null;

  @Output() public patientDetails: EventEmitter<PatientDetails> =
    new EventEmitter();

  public get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public constructor(private matDialogService: MatDialog) {}

  public ngOnInit(): void {}

  public editDetails() {
    if (this.updateFn === null && !environment.production)
      throw new Error("updateFn must not be null");

    this.openEditPatientDetailsDialog({
      patientDetails: {
        lastName: this.lastName,
        firstName: this.firstName,
        dateOfBirth: this.dateOfBirth,
        phoneNumber: this.phoneNumber,
      },
      updateFn: this.updateFn,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          this.patientDetails.emit(result.patientDetails);
        },
      });
  }

  private openEditPatientDetailsDialog(
    data: EditPatientDetailsData
  ): MatDialogRef<EditPatientDetailsDialogComponent, EditPatientDetailsResult> {
    const config: MatDialogConfig<EditPatientDetailsData> = {
      role: "dialog",
      restoreFocus: true,
      panelClass: ["mat-panel-reset", "w-500px"],
      data,
    };

    return this.matDialogService.open<
      EditPatientDetailsDialogComponent,
      EditPatientDetailsData,
      EditPatientDetailsResult
    >(EditPatientDetailsDialogComponent, config);
  }
}
