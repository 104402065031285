import {
  MediaType,
  MentionModel,
  MessageMetadata,
  MessageModel,
  MessageStatusModel,
  MessageType,
} from "./api-v2";

export interface Message {
  /**
   * @deprecated Use `marker` instead.
   * @format int64
   * */
  id?: number;
  conversationId?: string | null;
  sentBy?: string | null;

  /** @format date-time */
  sentOnUtc?: string;

  /** @format date-time */
  createdOnUtc?: string | null;
  content?: string | null;
  marker?: string | null;

  /** @format int32 */
  unreadCount?: number | null;

  /** Note: `null` indicates the message is just text, typically sent by a user. */
  type?: MessageType | null;
  mediaType?: MediaType;
  metadata?: MessageMetadata;
  statuses?: MessageStatusModel[] | null;
  mentions?: MentionModel[] | null;
  replyTo?: string | null;
  replyToMessage?: MessageModel;
  allowDelivery?: boolean;

  /** @format date-time */
  deletedOnUtc?: string;
  deletedBy?: string;

  /** `true` if this message has been successfully sent to the backend. */
  isSent?: boolean;

  /** `true` if this message is comprised of a single emoji. */
  isEmoji?: boolean;

  /** `true` if this message is the first of the day it's batch. */
  isFirstOfTheDay: boolean;
}
