import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { isCeloErrorResponse, isHttpErrorResponse } from "@utils";
import { CompanyErrorComponent } from "app/company-error/company-error.component";
import { getErrorDescriptor } from "utils/error-utils";
import {
  AlertService,
  AnalyticsService,
  AuthService,
  SharedService,
  UserAccountService,
} from "../../modules/core";

@Component({
  selector: "app-workspace-add",
  templateUrl: "./workspace-add.component.html",
  styleUrls: [
    "./workspace-add.component.scss",
    "../onboard.scss",
    "../../../celo-input.scss",
  ],
})
export class WorkspaceAddComponent implements OnInit {
  workspaces = [];
  workspace_details: any = {}; // typed as any to support legacy code
  verificationId: string;
  email: string;
  workspace_details_valid = false;
  loading = true;
  // userAccount:any;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private userAccountService: UserAccountService,
    private alertService: AlertService,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.sharedService.setTitle("Join | Celo");

    const onboardInfo = localStorage.getItem("onboard_info");
    const data = JSON.parse(onboardInfo);

    this.verificationId = data.verificationId;
    this.email = data.email;

    if (!data.email) {
      this.sharedService.navigateByUrl("/conversations");
    }
  }

  removeOnboardingInfo() {
    localStorage.removeItem("onboard_info");
  }

  joinWorkspace() {
    this.removeOnboardingInfo();
    if (!this.workspace_details_valid) {
      this.skipEvent();
      this.sharedService.navigateByUrl("/conversations");
      this.authService.setHasCompletedOnboarding();
      return;
    }
    this.loading = true;
    this.sharedService
      .joinWorkspace(
        this.email,
        this.verificationId,
        this.workspace_details["companyId"],
        this.workspace_details["departmentId"],
        this.workspace_details["position"],
        true
      )
      .subscribe(
        (userAccount) => {
          this.loading = false;
          this.sharedService.navigateTo("/conversations");
          this.userAccountService.userAccount = userAccount;

          this.analyticsService.raiseEvent("add_workspace_action", {
            flow: "signing_up",
            department_added: this.workspace_details["departmentId"]
              ? true
              : false,
            position_added: this.workspace_details["position"] ? true : false,
            success: "true",
          });
        },
        (err) => {
          this.analyticsService.raiseEvent("add_workspace_action", {
            flow: "signing_up",
            department_added: this.workspace_details["departmentId"]
              ? true
              : false,
            position_added: this.workspace_details["position"] ? true : false,
            success: "false",
          });
          this.loading = false;

          if (isHttpErrorResponse(err) && isCeloErrorResponse(err.error)) {
            const descriptor = getErrorDescriptor(err.error);
            CompanyErrorComponent.openDialog(this.matDialog, {
              title: this.workspace_details.company.companyName,
              content: descriptor.message,
            });
            return;
          }

          const reason =
            "We can't seem to add your workspace at the moment. You can try again from your profile page in the app.";
          this.alertService
            .confirm(
              this.sharedService.STANDARD_ERROR_MESSAGE,
              reason,
              "Ok",
              "",
              false
            )
            .subscribe((result) => {
              this.sharedService.navigateTo("/conversations");
            });
        }
      );
  }

  onCompaniesLoaded(companies) {
    this.loading = false;
    if (!companies || !companies.length) {
      this.sharedService.navigateTo("/conversations");
    }
  }

  skip() {
    this.removeOnboardingInfo();
    this.skipEvent();
  }

  skipEvent() {
    this.analyticsService.raiseEvent("skip_workspace", {
      flow: "signing_up",
    });
  }
}
