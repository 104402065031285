import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SubscriptionTier } from "@types";
import { getPlanDisplayName } from "@utils";

@Component({
  selector: "app-start-trial-cta",
  templateUrl: "./start-trial-cta.component.html",
  styleUrls: ["./start-trial-cta.component.scss"],
})
export class StartTrialCtaComponent implements OnInit {
  @Input() public plan: SubscriptionTier | null = null;
  @Input() public isStartTrialLoading = false;

  @Input() public upgradeUrl: string | null = null;

  @Output() public startTrial = new EventEmitter();
  @Output() public ignoreTrial = new EventEmitter();

  public title = "It's not too late! Start your 14-day free trial of a paid plan today!";
  public primaryButtonText = "Let's Get Started!";
  public secondaryButtonText = "Continue using Celo for Free";
  public isPaidPlan = false;

  public ngOnInit(): void {
    if (this.plan === null || this.plan === SubscriptionTier.Free) return;

    this.title = `Welcome to Celo! Unlock your 14-day free trial of the ${getPlanDisplayName(this.plan)} plan!`;
    this.secondaryButtonText = null;
    this.isPaidPlan = true;
  }
}
