import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  StartTrialCtaDialogComponent,
  StartTrialCtaDialogData,
} from "@modules/shared/start-trial-cta-dialog/start-trial-cta-dialog.component";
import { TrialEndedCtaDialogComponent } from "@modules/shared/trial-ended-cta-dialog/trial-ended-cta-dialog.component";
import {
  BillingInterval,
  ClientCustomerPortalSessionDetails,
  CompanyModel,
  CreateStripeCustomerPortalSessionRequest,
  CreateTrialRequest,
  IdempotentRequest,
  Price,
  Subscription,
  SubscriptionTier,
  TokenPagedRequestOptions,
  TokenPagedResultOfPrice,
  TokenPagedResultOfProduct,
  UserWorkspaceModel,
} from "@types";
import { flatMap, isFutureDate, SubscriptionContainer } from "@utils";
import { PinscreenService } from "app/pinscreen/pinscreen.service";
import {
  combineLatest,
  EMPTY,
  filter,
  first,
  map,
  merge,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
  timer,
} from "rxjs";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { CompaniesService } from "./companies.service";
import { UserService } from "./user.service";

/**
 * This additional time is added to the trial ended delay to give the
 * server time to actually process the downgrade.
 */
const additionalTrialEndedDelayMilliseconds = 60000;
const millisecondsPerMinute = 60000;

@Injectable({
  providedIn: "root",
})
export class PaymentsService implements OnDestroy {
  private trialStartedSubject = new Subject<void>();
  public trialStarted$ = this.trialStartedSubject.asObservable();

  private companies$: Observable<{
    companies: CompanyModel[];
    workspaces: UserWorkspaceModel[];
  }>;

  private checkIsTrialEnded = new Subject<void>();

  private trialEndedSubject = new Subject<void>();
  public trialEnded$ = this.trialEndedSubject.asObservable();

  private subscriptions = new SubscriptionContainer();

  public constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private companiesService: CompaniesService,
    private userService: UserService,
    private pinScreenService: PinscreenService
  ) {
    this.companies$ = combineLatest([
      this.authService.isAuthenticated$,
      this.pinScreenService.isLocked$,
    ]).pipe(
      filter(([isAuthenticated, isLocked]) => isAuthenticated && !isLocked),
      take(1),
      switchMap(() => {
        return merge(this.checkIsTrialEnded, this.trialStarted$).pipe(
          startWith("init")
        );
      }),
      switchMap(() => {
        return this.companiesService
          .getCompaniesV1({
            fetchAll: true,
            pageSize: 100,
          })
          .pipe(flatMap((page) => page.data));
      }),
      switchMap((companies) => {
        return this.userService
          .getUserWorkspaces({ fetchAll: true, pageSize: 100 })
          .pipe(
            flatMap((page) => page.data),
            map((workspaces) => ({ companies, workspaces }))
          );
      }),
      shareReplay(1)
    );

    // Trigger trial ended dialog if a company's trial ends or has already ended and the dialog hasn't been shown
    this.companies$.subscribe({
      next: ({ companies, workspaces }) => {
        const endedTrialCompany = companies.find((c) => {
          return (
            c.trialEndDate &&
            !isFutureDate(c.trialEndDate) &&
            !c.hasShownTrialEndedNotification &&
            !c.hasBillingMethod &&
            workspaces.some(
              (w) =>
                w.workspace.id === c.id &&
                w.claims?.workspaceBillingManage === "true"
            )
          );
        });

        if (!endedTrialCompany) return;

        this.trialEndedSubject.next();

        this.matDialog.closeAll();

        this.pinScreenService.isLocked$
          .pipe(
            first((isLocked) => !isLocked),
            switchMap(() => {
              return TrialEndedCtaDialogComponent.openDialog(
                this.matDialog
              ).afterOpened();
            }),
            switchMap(() => {
              return this.updateHasShownTrialEndedNotification(
                endedTrialCompany.id
              );
            })
          )
          .subscribe();
      },
    });

    // Emit when any company's trial ends
    this.companies$
      .pipe(
        switchMap(({ companies }) => {
          const activeTrialCompany = companies.find(
            (c) => c.trialEndDate && isFutureDate(c.trialEndDate)
          );
          if (!activeTrialCompany) return EMPTY;

          const trialEndDate = new Date(activeTrialCompany.trialEndDate);

          // Max delay is because high timeouts are unreliable (e.g browser restarts, system sleeping, etc.)
          // This is effectively a way for us to poll.
          const delay =
            Math.min(
              millisecondsPerMinute * 15,
              trialEndDate.getTime() - Date.now()
            ) + additionalTrialEndedDelayMilliseconds;
          return timer(delay);
        })
      )
      .subscribe({
        next: () => {
          this.checkIsTrialEnded.next();
        },
      });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getProducts(
    options?: TokenPagedRequestOptions
  ): Observable<TokenPagedResultOfProduct> {
    const path = `/api/payments/v1/products`;
    return this.apiService.get<TokenPagedResultOfProduct>({
      path,
      queryParams: { ...options },
    });
  }

  public getPrices(
    productId: string,
    options?: TokenPagedRequestOptions
  ): Observable<TokenPagedResultOfPrice> {
    const path = `/api/payments/v1/products/${productId}/prices`;
    return this.apiService.get<TokenPagedResultOfPrice>({
      path,
      queryParams: { ...options },
    });
  }

  public getPrice(
    subscriptionTier: SubscriptionTier,
    billingInterval: BillingInterval
  ) {
    const path = `/api/payments/v1/prices`;
    return this.apiService.get<Price>({
      path,
      queryParams: {
        subscriptionTier,
        billingInterval,
      },
    });
  }

  public startTrial(companyId: string, options: CreateTrialRequest) {
    const path = `/api/payments/v1/companies/${companyId}/subscriptions/trial`;
    return this.apiService
      .post<Subscription>({
        path,
        body: options,
      })
      .pipe(
        tap({
          next: () => this.trialStartedSubject.next(),
        })
      );
  }

  public openStartTrialCtaDialog() {
    const productTrialType = this.authService.getProductTrialType();
    const billingInterval = this.authService.getBillingInterval();

    const dialogData: StartTrialCtaDialogData = {};

    if (productTrialType && billingInterval) {
      dialogData.trialData = {
        subscriptionTier: productTrialType,
        billingInterval: billingInterval,
      };
    }

    return StartTrialCtaDialogComponent.openDialog(this.matDialog, dialogData);
  }

  public createStripeCustomerPortalSession(
    options: CreateStripeCustomerPortalSessionRequest
  ) {
    const path = `/api/payments/v1/stripe/customer-portal-session`;
    return this.apiService.post<ClientCustomerPortalSessionDetails>({
      path,
      body: options,
    });
  }

  public refreshBillingMethod(companyId: string, options: IdempotentRequest) {
    const path = `/api/payments/v1/companies/${companyId}/refresh-billing-method`;
    return this.apiService.post<void>({
      path,
      body: options,
    });
  }

  public updateHasShownTrialEndedNotification(companyId: string) {
    const path = `/api/v2/Companies/${companyId}/updateHasShownTrialEndedNotification`;
    return this.apiService.post<void>({
      path,
    });
  }
}
