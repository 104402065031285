import { Component, Input, OnChanges } from "@angular/core";
import { AlertService } from "app/modules/core/old/alert.service";
import { WorkspaceService } from "app/workspace/workspace.service";
import { environment } from "../../../../environments/environment";
import { SharedService, UserAccountService } from "../../core";

@Component({
  selector: "app-edit-company",
  templateUrl: "./edit-company.component.html",
  styleUrls: ["./edit-company.component.scss"],
})
export class EditCompanyComponent implements OnChanges {
  @Input()
  profile: any;

  companies: any;
  dialogRef: any;
  protected basePath = environment.celoApiEndpoint;

  constructor(
    private sharedService: SharedService,
    private workspaceService: WorkspaceService,
    private alertService: AlertService,
    private userAccountService: UserAccountService
  ) {}

  ngOnChanges() {
    this.getCompaniesFromProfile();
  }

  getCompaniesFromProfile() {
    if (this.profile) {
      this.companies = this.sharedService.getCompaniesInfo(this.profile);
    }
  }

  edit(workspace) {
    const instance = this;
    this.userAccountService.editCompany(
      workspace,
      this.companies,
      function (data) {
        if (data) {
          instance.profile = data;
          instance.companies = instance.sharedService.getCompaniesFromProfile(
            instance.profile
          );
          instance.alertService.showSnackBar("Saved", 2);
        } else {
          return;
        }
      }
    );
  }

  joinWorkspace() {
    const instance = this;
    this.workspaceService.joinWorkspaceFlow(function (profile) {
      if (!profile) {
        return;
      }
      instance.profile = profile;
      instance.companies = instance.sharedService.getCompaniesFromProfile(
        instance.profile
      );
      instance.userAccountService.getUserAccount(true, function (user) {
        instance.profile = user;
      });
    });
  }
}
