<div *ngIf="!showSpinner" class="pin-page" [ngClass]="{ fh: hasPassCode }">
  <div class="content" [ngClass]="{ width_50p: hasPassCode }">
    <div class="form">
      <img class="logo appear_anim_1" src="../../assets/logo-v2.svg" alt="" />
      <img
        *ngIf="!hasPassCode"
        class="pic appear_anim_1"
        src="../../assets/pin-set.svg"
        alt=""
      />
      <div class="appear_anim_2">
        <div class="celo-text-32" *ngIf="hasPassCode">Welcome back!</div>
        <div class="celo-text-14 celo-grey" *ngIf="hasPassCode">
          <span *ngIf="expired === 'true'">
            <ng-container
              *ngIf="
                lockoutPeriodMinutes$ | async as lockoutPeriodMinutes;
                else inactiveForTooLong
              "
            >
              You were inactive for
              {{ lockoutPeriodMinutes | number: "1.0-0" }} minutes.
            </ng-container>
            <ng-template #inactiveForTooLong>
              You were inactive for too long.
            </ng-template>
          </span>
          Please enter your PIN to continue.
        </div>
        <div *ngIf="!hasPassCode">
          <div class="celo-bold celo-text-18">Set up your PIN number</div>
          <div class="celo-grey celo-text-14">
            Enter a 4 digit PIN. You will use this to easily access the Celo app
            on this device.
          </div>
        </div>
      </div>
      <div class="celo-input-outline-standard-height fields appear_anim_3">
        <mat-form-field *ngIf="!hasPassCode" appearance="outline" class="fw">
          <input
            matInput
            id="pin_code"
            class="fw"
            type="password"
            placeholder="Pin number"
            required
            minlength="4"
            autocomplete="off"
            maxlength="4"
            autocomplete="off"
            autofocus
            [(ngModel)]="passcode0"
            name="passcode"
            #passcode="ngModel"
            #setPasscode
            appNumericPassword
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="fw">
          <input
            matInput
            id="pin_code_confirm"
            class="fw"
            type="password"
            [placeholder]="hasPassCode ? 'Enter Pin' : 'Re-enter your pin'"
            autocomplete="off"
            required
            minlength="4"
            maxlength="4"
            autocomplete="off"
            [(ngModel)]="passcode"
            (keyup.enter)="(hasPassCode || passcode0 === passcode) && submit()"
            (ngModelChange)="
              hasPassCode && passcode.length === 4 ? onSubmit() : ''
            "
            name="passcodeConfirm"
            #passcodeConfirm="ngModel"
            #myPasscode
            appNumericPassword
          />
        </mat-form-field>
        <div
          *ngIf="
            !hasPassCode &&
            passcode0 !== passcode &&
            passcode &&
            passcode.length === 4
          "
          class="celo-grey celo-text-14 appear_2 error-text"
        >
          PINs do not match. Please re-enter.
        </div>
      </div>
      <div *ngIf="!hasPassCode" class="buttons appear_anim_4">
        <button
          [disabled]="
            !passcode0 ||
            !passcode ||
            passcode0 !== passcode ||
            passcode0?.length !== 4 ||
            passcode?.length !== 4
          "
          class="celo-primary-button fw"
          mat-button
          [color]="'primary'"
          (click)="submit()"
        >
          NEXT
        </button>
      </div>
      <div class="appear_anim_4 logout">
        <div class="appear_anim_3 celo-link" *ngIf="!hasPassCode">
          <a (click)="logout('Pin screen')">Logout</a>
        </div>
        <div class="appear_anim_3 celo-link" *ngIf="hasPassCode">
          <a (click)="forgotPin()">Forgot PIN</a>
        </div>
        <div class="appear_anim_4 celo-link" *ngIf="hasPassCode">
          <a (click)="forgotPin()">Sign in with a different account</a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasPassCode" class="cover">
    <img class="set-pin-pic" src="../../assets/splash-screen.png" alt="" />
  </div>
</div>
<div *ngIf="showSpinner" class="loading-screen">
  <app-empty-state
    [no_message]="true"
    [isLoading]="true"
    [spinner]="false"
    [animation]="true"
    [diameter]="70"
  ></app-empty-state>
</div>
<div *ngIf="!production" class="version">{{ version }}</div>
