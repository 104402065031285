<app-basic-cta
  [title]="'Discover More with a Plan Upgrade!'"
  [primaryButtonText]="'View Plans'"
  [secondaryButtonText]="'Continue using current plan'"
  [primaryButtonHref]="upgradeUrl"
  (secondaryClick)="dismiss.emit()"
  [padding]="'30px 65px 45px 65px'"
>
  <img
    image
    ngSrc="../../../../assets/ctas/cta-upgrade@2x.png"
    width="250"
    height="250"
    priority
    placeholder
  />

  <ng-container content>
    Expand your capabilities by upgrading to a higher plan. Get access to
    premium tools and services that will elevate your Celo experience.
  </ng-container>
</app-basic-cta>
